var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-snackbar',{attrs:{"color":"#16C96D","top":""},model:{value:(_vm.showchangeMessage),callback:function ($$v) {_vm.showchangeMessage=$$v},expression:"showchangeMessage"}},[_c('v-icon',{attrs:{"dark":"","size":"25"}},[_vm._v("mdi-check")]),_c('span',{staticClass:"font-weight-bold pl-2"},[_vm._v(_vm._s(_vm.changeMessage))])],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.info.color},model:{value:(_vm.showInfo),callback:function ($$v) {_vm.showInfo=$$v},expression:"showInfo"}},[_c('v-icon',[_vm._v(_vm._s(_vm.info.icon))]),_vm._v(" "),_c('span',{staticClass:"font-weight-bold pl-2"},[_vm._v(_vm._s(_vm.info.message))])],1),_c('v-dialog',{attrs:{"max-width":"350px"},model:{value:(_vm.deleteUserQuestion),callback:function ($$v) {_vm.deleteUserQuestion=$$v},expression:"deleteUserQuestion"}},[_c('v-card',[_c('v-card-title',{staticStyle:{"{\n                        text-align":"center"}},[_vm._v(" Delete "+_vm._s(_vm.selectedUser.username)+"?")]),_c('v-card-text',{staticClass:"text-center"},[_c('v-btn',{staticClass:"mr-3",attrs:{"text":"","absolute":"","left":""},on:{"click":function($event){_vm.deleteUserQuestion = false}}},[_vm._v(" Nevermind ")]),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.deleteUser()}}},[_vm._v(" Yes ")])],1)],1)],1),_c('AddUserDialog',{attrs:{"company_domains":_vm.domains,"addUserDialog":_vm.addUserDialog},on:{"close":function($event){_vm.addUserDialog = false},"createInfo":_vm.createInfo,"refreshData":function($event){return _vm.fetchUser()}}}),_c('v-row',[_c('v-col',{staticClass:"d-flex"},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"fabicon mx-5 mt-auto",attrs:{"rounded":"","small":"","color":"primary"},on:{"click":function($event){_vm.addUserDialog = true}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"dark":""}},[_vm._v("mdi-plus")]),_vm._v(" add user ")],1)]}}])},[_c('span',[_vm._v("invite user")])])],1),_c('v-spacer'),_c('v-dialog',{attrs:{"max-width":"400","height":"300px"},model:{value:(_vm.showDefaultGroup),callback:function ($$v) {_vm.showDefaultGroup=$$v},expression:"showDefaultGroup"}},[_c('v-card',[_c('v-card-title',[_vm._v(" Change Default User Rights ")]),_c('v-card-text',[_c('div',{staticClass:"qgreycard mx-5 my-2 ml-auto"},[_c('span',{staticClass:"lbl"},[_vm._v(" Research Suite "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The selected Research Suite default user group will be assigned to any user created manually by the Admin or via the Register page!")])])],1),_c('v-select',{staticClass:"my-auto",attrs:{"dense":"","color":"primary","hide-details":"","rounded":"","filled":"","items":_vm.groups,"item-text":"name","item-value":"id","return-object":""},on:{"change":_vm.changeStandardGroup},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":""}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}}]),model:{value:(_vm.selected_standard_group),callback:function ($$v) {_vm.selected_standard_group=$$v},expression:"selected_standard_group"}})],1),_c('div',{staticClass:"qgreycard mx-5 my-2 ml-auto"},[_c('span',{staticClass:"lbl"},[_vm._v("Content Suite "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The selected Research Suite default user group will be assigned to any user created manually by the Admin or via the Register page!")])])],1),_c('v-select',{staticClass:"my-auto",attrs:{"dense":"","color":"primary","hide-details":"","rounded":"","filled":"","items":_vm.cs_groups,"item-text":"name","item-value":"id","return-object":""},on:{"change":_vm.changeStandardGroup},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":""}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}}]),model:{value:(_vm.selected_standard_group_cs),callback:function ($$v) {_vm.selected_standard_group_cs=$$v},expression:"selected_standard_group_cs"}})],1),_c('div',{staticClass:"qgreycard mx-5 my-2 ml-auto"},[_c('span',{staticClass:"lbl"},[_vm._v("Monitoring Suite "),_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"pl-0 mr-auto tooltipicon",attrs:{"small":"","color":"grey lightn-2"}},on),[_vm._v("mdi-help-circle-outline")])]}}])},[_c('span',[_vm._v("The selected Research Suite default user group will be assigned to any user created manually by the Admin or via the Register page!")])])],1),_c('v-select',{staticClass:"my-auto",attrs:{"dense":"","color":"primary","hide-details":"","rounded":"","filled":"","items":_vm.ms_groups,"item-text":"name","item-value":"id","return-object":""},on:{"change":_vm.changeStandardGroup},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-list-item',_vm._g({attrs:{"ripple":""}},on),[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(item.name)+" ")])],1)],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(item.description))])])]}}]),model:{value:(_vm.selected_standard_group_ms),callback:function ($$v) {_vm.selected_standard_group_ms=$$v},expression:"selected_standard_group_ms"}})],1)]),_c('v-card-actions',[_c('v-btn',{staticClass:"mx-auto",attrs:{"rounded":"","color":"primary","dark":""},on:{"click":function($event){_vm.showDefaultGroup = false}}},[_vm._v(" ok")])],1)],1)],1),_c('v-col',{staticClass:"d-flex"},[_c('v-btn',{staticClass:"ml-auto mr-5",attrs:{"outlined":"","color":"primary","rounded":""},on:{"click":function($event){_vm.showDefaultGroup = true}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"size":"16px"}},[_vm._v("mdi-cog")]),_vm._v(" Default User Group ")],1)],1)],1),_c('v-text-field',{staticClass:"mx-5",attrs:{"max-width":"300px","dense":"","small":"","prepend-inner-icon":"mdi-magnify","rounded":"","filled":"","label":"Search user..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.header,"items":_vm.user,"search":_vm.search,"max-height":"500","item-key":"searchPhrase"},on:{"update:search":function($event){_vm.search=$event}},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('v-progress-linear',{staticClass:"px-0",attrs:{"size":50,"color":"primary","indeterminate":true}})]},proxy:true},{key:"item.username",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"py-3"},[_c('div',[_vm._v(_vm._s(item.username))]),_c('v-chip',{attrs:{"small":""}},[_vm._v(_vm._s(item.user))])],1)]}},{key:"item.group_id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","offset-y":"","right":"","content-class":"submenu","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":"","color":"grey darken-2","outlined":""}},on),[(item.admin)?_c('span',[_vm._v("admin")]):(!item.research_suite)?_c('span',[_vm._v("no access")]):_c('span',[_vm._v(" "+_vm._s(_vm.groups[_vm.groups.findIndex(function (x) { return item.group_id == x.id; })].name)+" ")]),_c('v-icon',{attrs:{"size":"16px"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"max-height":"300px","overflow-y":"auto","font-weight":"bold !important"},attrs:{"rounded":"","dense":""}},[_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.grantAdmin(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" Admin ")])],1),_vm._l((_vm.groups),function(group){return _c('div',{key:group.id},[(group.id > 0)?_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.changeGroupV2('rs', item, group.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(group.name)+" ")])],1):_vm._e()],1)}),_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.changeAccess('rs', item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" No Access ")])],1)],2)],1)]}},{key:"item.content_suite_group_id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","offset-y":"","right":"","content-class":"submenu","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":"","color":"grey darken-2","outlined":""}},on),[(item.admin)?_c('span',[_vm._v("admin")]):(!item.content_suite)?_c('span',[_vm._v("no access")]):_c('span',[_vm._v(" "+_vm._s(_vm.cs_groups[_vm.cs_groups.findIndex(function (x) { return item.content_suite_group_id == x.id; })].name)+" ")]),_c('v-icon',{attrs:{"size":"16px"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"max-height":"300px","overflow-y":"auto","font-weight":"bold !important"},attrs:{"rounded":"","dense":""}},[_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.grantAdmin(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v("Admin ")])],1),_vm._l((_vm.cs_groups),function(group){return _c('div',{key:group.id},[(group.id > 0)?_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.changeGroupV2('cs', item, group.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(group.name)+" ")])],1):_vm._e()],1)}),_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.changeAccess('cs', item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" No Access ")])],1)],2)],1)]}},{key:"item.monitoring_suite_group_id",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"top":"","offset-y":"","right":"","content-class":"submenu","close-on-content-click":true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","text":"","rounded":"","color":"grey darken-2","outlined":""}},on),[(item.admin)?_c('span',[_vm._v("admin")]):(!item.monitoring_suite)?_c('span',[_vm._v("no access")]):_c('span',[_vm._v(" "+_vm._s(_vm.ms_groups[_vm.ms_groups.findIndex(function (x) { return item.monitoring_suite_group_id == x.id; })].name)+" ")]),_c('v-icon',{attrs:{"size":"16px"}},[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{staticStyle:{"max-height":"300px","overflow-y":"auto","font-weight":"bold !important"},attrs:{"rounded":"","dense":""}},[_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.grantAdmin(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v("Admin ")])],1),_vm._l((_vm.ms_groups),function(group){return _c('div',{key:group.id},[(group.id > 0)?_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.changeGroupV2('ms', item, group.id)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" "+_vm._s(group.name)+" ")])],1):_vm._e()],1)}),_c('v-list-item',{staticClass:"pl-2",on:{"click":function($event){return _vm.changeAccess('ms', item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v("mdi-circle-outline")]),_c('v-list-item-title',[_vm._v(" No Access ")])],1)],2)],1)]}},{key:"item.verification_status_translated",fn:function(ref){
var item = ref.item;
return [_c('center',[(item.verification_status == 1)?_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-check-circle-outline")]):_c('v-icon',{attrs:{"color":"errorred"}},[_vm._v("mdi-close-circle-outline")])],1)]}},{key:"item.action",fn:function(ref){
var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","rounded":"lg","color":"primary","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","rounded":"","outlined":"","small":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-account-edit")]),_c('v-icon',[_vm._v("mdi-chevron-down")])],1)]}}],null,true)},[_c('v-list',{attrs:{"rounded":"","dense":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.openDeleteDialog(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-delete-circle")]),_vm._v(" "),_c('v-list-item-title',[_vm._v("Delete User")])],1),(item.verification_status == 0)?_c('v-list-item',{on:{"click":function($event){return _vm.resendVerificationMail(item)}}},[_c('v-icon',{staticClass:"mr-2"},[_vm._v("mdi-send-circle-outline")]),_c('v-list-item-title',[_vm._v("Resend verification mail")])],1):_vm._e()],1)],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }