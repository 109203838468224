<template>
    <div>
        <AddUrlDialogVue
            :show="addURLDialog.show"
            :id="$route.query.id"
            @close="addURLDialog.show = false"
            @started="
                addURLDialog.show = false
                url_loading = true
            "
            :setMainURL="false"
            :addLighthouseData="false"></AddUrlDialogVue>
        <div v-if="loading" class="loaderwrapper">
            <v-row class="loaderimg mb-10">
                <img id="loader" src="../../../assets/quaro-q.svg" />
            </v-row>
            <v-row class="loaderheading">
                <span style="font-weight: 300; font-size: 16px" class="mx-auto">{{ statusNotification.message }}</span>
            </v-row>
            <v-row class="ma-auto">
                <v-col>
                    <div id="loaderdiv">
                        <v-progress-linear
                            :indeterminate="statusNotification.message ? false : true"
                            rounded
                            height="6px"
                            class="ma-auto"
                            color="primary"
                            :value="statusNotification.progress"></v-progress-linear>
                    </div>
                </v-col>
            </v-row>
            <v-col class="d-flex mt-10" v-if="statusNotification.type == 'error'">
                <v-btn color="primary" class="mx-auto" @click="$emit('retryProcess', 2)"> Retry <v-icon>mdi-refresh</v-icon> </v-btn>
            </v-col>
            <v-row class="mt-10">
                <p class="loadersubtext">Note: Based on your selected focus topics, we now identify the most relevant and strongest competitors. This usually only takes a few seconds!</p>
            </v-row>
        </div>
        <div v-else>
            <h2 style="font-weight: 300">Select Competitors</h2>
            <p>
                Through a analysis of the search results for your selected focus keywords, the strongest competitors for your topic have been identified. Their content will be analyzed to assist you
                in creating high-quality content that can boost your search engine rankings. If the search intent is mixed, it may be beneficial to exclude competitors who serve a different intent.
            </p>
            <v-row no-gutters>
                <v-col cols="12" class="ml-auto">
                    <v-row class="ml-auto" no-gutters>
                        <!-- <v-slide-x-transition>
                            <v-text-field
                                v-if="addManually"
                                class="ml-auto mr-3"
                                dense
                                single-line
                                rounded
                                filled
                                v-model="topic_manual"
                                label="enter manually"
                                hide-details
                                @keyup.enter="add"></v-text-field>
                        </v-slide-x-transition> -->
                        <v-btn rounded class="ml-auto mb-2" color="primary" @click="addURLDialog.show = true" :loading="url_loading">enter manually</v-btn>
                        <!-- <span class="mt-2 mx-3">or</span>
                        <v-btn rounded color="primary" @click="addFromClipboard">add from clipboard</v-btn> -->
                    </v-row>
                </v-col>
            </v-row>
            <v-row class="pa-4">
                <span v-if="selected.length > 0">
                    <v-chip class="white--text" color="darkgreen" small> {{ selected.length }} of {{ project.competitors.length }} selected</v-chip>
                </span>
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                    clearable
                    clear-icon="mdi-close"
                    dense
                    rounded
                    filled
                    v-model="search"
                    max-width="300px"
                    prepend-inner-icon="mdi-magnify"
                    label="Search..."
                    single-line
                    hide-details></v-text-field>
            </v-row>
            <v-data-table
                disabled
                id="cs_comp_table"
                show-select
                :search="search"
                fixed-header
                height="600px"
                :headers="headers"
                :items="tableitems"
                class="elevation-0 comp-table"
                @toggle-select-all="selectPage"
                :footer-props="{ 'items-per-page-options': [25, 50, 100] }"
                :items-per-page="25"
                :custom-sort="customSort"
                item-key="url">
                <!-- header slot -->
                <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                    <span :key="index">
                        <span v-bind:style="{ width: header.width }">
                            <!-- Adding tooltips to header -->
                            <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                <template v-slot:activator="{ on }">
                                    <span v-on="on" class="quarotblheader">
                                        {{ header.text }}
                                        <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                    </span>
                                </template>
                                <span>
                                    <v-row no-gutters>
                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                    </v-row>
                                    {{ header.tooltip }}
                                </span>
                            </v-tooltip>
                        </span>
                    </span>
                </template>

                <!-- Table Body -->
                <template v-slot:body="{ items }">
                    <tbody v-if="items.length">
                        <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="mainUrl(item.url) ? 'pinnedurl' : ''">
                            <td class="selecttd">
                                <v-icon v-if="!item.selected" @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-blank-outline</v-icon>
                                <v-icon v-else @click="selectItem(itemIndex, item)" style="margin: 0px; padding: 0px">mdi-checkbox-marked</v-icon>
                            </td>
                            <td v-for="(header, headerIndex) in headers" :key="headerIndex">
                                <!-- ------- -->
                                <!-- competitor -->
                                <!-- ------- -->
                                <div v-if="header.value === 'domain'" class="py-4">
                                    <v-row no-gutters>
                                        <v-col cols="2" style="display: flex">
                                            <div class="fav_wrapper">
                                                <img class="fav" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + getDomainFromURL(item.url) + '&sz=24'" alt="" />
                                            </div>
                                        </v-col>
                                        <v-col cols="10">
                                            <div class="domain_td">{{ item[header.value] ? item[header.value] : getDomainFromURL(item.url) }}</div>
                                            <div class="path_td">{{ item.path ? item.path : getPathFromURL(item.url) }}</div></v-col
                                        >
                                    </v-row>
                                </div>
                                <!-- -------------- -->
                                <!---- relevance ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'comp_relevance'" v-bind="(relevance = getRelevance(item))">
                                    <v-progress-linear style="border-radius: 12px; font-size: 12px" class="white--text" rounded :value="relevance.value" color="primary" height="24">
                                        {{ relevance.value }}%
                                    </v-progress-linear>
                                </div>
                                <!-- -------------- -->
                                <!---- avg position ----->
                                <!-- -------------- -->
                                <!-- <div v-if="header.value === 'positions'" v-bind="(avgpos = getAVGpos(item))">
                                    <v-chip small class="white--text" :color="avgpos.color"> {{ avgpos.value }}</v-chip>
                                </div> -->
                                <!-- -------------- -->
                                <!---- avg position ----->
                                <!-- -------------- -->
                                <div v-else-if="header.value === 'positions'" v-bind="(avgpos = getAVGpos(item))" style="display: flex">
                                    <!-- <v-sparkline
                                        :gradient="['#4DB6AC', '#E57373']"
                                        type="bar"
                                        show-labels
                                        auto-line-width
                                        auto-draw
                                        :value="sparklineData.values"
                                        :labels="sparklineData.labels"></v-sparkline> -->
                                    <div style="display: flex">
                                        <v-chip class="white--text" :color="avgpos.color"> {{ Math.trunc(avgpos.value) }}</v-chip>
                                    </div>

                                    <div style="display: flex" class="ml-auto pl-2">
                                        <div v-for="(topic, index) in selectedTopics" :key="index" class="" style="padding-left: 3px; display: flex" v-bind="(posData = getPos(topic, item))">
                                            <v-tooltip color="primary" top>
                                                <template v-slot:activator="{ on }">
                                                    <div class="posbar mt-auto" v-on="on" :style="getPosBarStyle(topic, item)"></div>
                                                </template>
                                                <div><span style="font-weight: bold"> Keyword: </span>{{ posData.keyword }}</div>
                                                <div><span style="font-weight: bold">Position: </span>{{ posData.position ? posData.position : "-" }}</div>
                                            </v-tooltip>
                                        </div>
                                    </div>
                                    <div style="display: block"></div>
                                </div>
                                <!-- -------------- -->
                                <!---- TITLE ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'title'">
                                    {{ item[header.value] }}
                                </div>
                                <!-- -------------- -->
                                <!---- DESCRIPTION ----->
                                <!-- -------------- -->
                                <div v-if="header.value === 'description'">
                                    {{ getDescription(item[header.value]) }}
                                </div>
                                <!-- ------- -->
                                <!---- PR----->
                                <!-- ------- -->
                                <div v-if="header.value === 'page_rank'">
                                    <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{
                                        item[header.value] ? item[header.value] : 0
                                    }}</v-chip>
                                    <span v-else>-</span>
                                </div>
                                <!-- ------ -->
                                <!---- DR----->
                                <!-- ------ -->
                                <div v-if="header.value === 'domain_rank'">
                                    <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{
                                        item[header.value] ? item[header.value] : 0
                                    }}</v-chip>
                                    <span v-else>-</span>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </template>
            </v-data-table>
        </div>
    </div>
</template>

<script>
    import AddUrlDialogVue from "../AddUrlDialog.vue"

    export default {
        props: {
            project: Object,
            loading: Boolean,
            statusNotification: Object,
            url_loading: Boolean
        },
        components: {
            AddUrlDialogVue
        },
        data() {
            return {
                addURLDialog: {
                    show: false
                },
                rankColors: [
                    "green-accent-4",
                    "green darken-2",
                    "green darken-2",
                    "green darken-1",
                    "light-green darken-2",
                    "lime darken-2",
                    "yellow darken-2",
                    "amber darken-2",
                    "orange darken-2",
                    "deep-orange darken-2"
                ],
                positionColors: [
                    "green darken-2",
                    "green darken-1",
                    "light-green darken-2",
                    "lime darken-2",
                    "yellow darken-2",
                    "amber darken-2",
                    "orange darken-2",
                    "deep-orange darken-2",
                    "red darken-2",
                    "red darken-4"
                ],
                search: "",
                positionColors2: ["#388E3C", "#43A047", "#689F38", "#AFB42B", "#FBC02D", "#FFA000", "#F57C00", "#E64A19", "#D32F2F", "#B71C1C"],
                headers: [
                    {
                        text: "Competitor",
                        value: "domain", //domain and path
                        width: "20",
                        sortable: true,
                        width: 350
                    },
                    {
                        text: "Ø Position",
                        value: "positions",
                        sortable: true,
                        tooltip: "The average position of the competitor website in search engine results pages (SERPs) for your target keywords.",
                        width: "135px"
                    },
                    {
                        text: "Relevance",
                        value: "comp_relevance",
                        sortable: true,
                        width: 150,
                        tooltip: "Shows the relevance of an competitor based on its count and positions in search engine results pages (SERPs) for selected topics in a project."
                    },

                    {
                        text: "Title",
                        value: "title",
                        width: 250,
                        sortable: false,
                        tooltip: "The title tag of the competitor website's landing page, which is displayed in SERPs."
                    },
                    {
                        text: "Description",
                        value: "description",
                        sortable: false,
                        width: 450,
                        tooltip: "The meta description tag of the competitor website's landing page, which is displayed in SERPs."
                    },
                    {
                        text: "Page Rank",
                        value: "page_rank",
                        sortable: true,
                        tooltip:
                            "Page Rank is an algorithm designed to measure the importance of a webpage based on the quality and quantity of backlinks. High Values are perceived as more authoritative by Google and rank better"
                    },
                    {
                        text: "Domain Rank",
                        value: "domain_rank",
                        sortable: true,
                        tooltip:
                            "Domain Rank is an algorithm designed to measure the importance of a domain based on the quality and quantity of backlinks. High Values are perceived as more authoritative by Google and rank better"
                    }
                ],
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
            }
        },
        computed: {
            selectedTopics() {
                return this.project.topics.filter((topic) => topic.selected)
            },
            selected() {
                let selectedComps = this.project.competitors.filter((comp) => comp.selected)
                return selectedComps
            },
            tableitems() {
                if (!this.project.comparissonURL || !this.project.comparissonURL.url) {
                    return this.project.competitors
                }
                let exists = this.checkURLExists(this.project.comparissonURL.url, this.project.competitors)
                if (exists && exists.exists) {
                    console.log(this.project.competitors[exists.index])
                    this.project.comparissonURL.positions = this.project.competitors[exists.index].positions
                    this.project.comparissonURL.count = this.project.comparissonURL.positions.length
                    this.project.competitors[exists.index] = this.project.comparissonURL
                    console.log(this.project.competitors[exists.index])

                    return this.project.competitors
                }
                return [this.project.comparissonURL, ...this.project.competitors]
                // if (!exists) {
                //     return [this.project.comparissonURL, ...this.project.competitors]
                // } else {
                //     return this.project.competitors
                // }
            }
        },
        // watch: {
        //     project: {
        //         handler(newValue, oldValue) {
        //             // Note: `newValue` will be equal to `oldValue` here
        //             // on nested mutations as long as the object itself
        //             // hasn't been replaced.
        //             console.log("WATCHHHHHHHHHHHHHH")
        //             this.selected = []
        //             this.project.competitors.map((comp) => {
        //                 if (comp.selected) {
        //                     this.selected.push(comp)
        //                 }
        //             })
        //         },
        //         deep: true
        //     }
        // },
        methods: {
            getPathFromURL(url) {
                return new URL(url).pathname
            },
            getDomainFromURL(url) {
                return new URL(url).protocol + "//" + new URL(url).hostname
            },
            getPosBarStyle(topic, comp) {
                let position = comp.positions.find((entry) => entry.keyword == topic.keyword)
                let style = ""
                if (position && position.position && position.position > 20) {
                    style = "background-color: " + "#009f89" + " !important; height: " + "7" + "px;"
                } else if (position && position.position) {
                    let colorIndex = Math.ceil(position.position / 3)
                    let color = this.positionColors2[colorIndex] ? this.positionColors2[colorIndex] : this.positionColors2[this.positionColors2.length - 1]
                    let height = (22 - position.position) * 1.5 > 30 ? 30 : (22 - position.position) * 1.5
                    style = "background-color: " + "#009f89" + " !important; height: " + height + "px;"
                } else {
                    style = "background-color: " + "#E57373" + " !important; height: " + "7" + "px;"
                }
                return style
            },
            getPos(topic, comp) {
                let pos = comp.positions.find((entry) => entry.keyword == topic.keyword)
                return pos ? pos : { keyword: topic.keyword, position: null }
            },
            checkURLExists(url, urls) {
                try {
                    let exists = false
                    let existsindex = 0
                    let urlObj = new URL(url)
                    let compareURL = urlObj.origin + urlObj.pathname
                    console.log(compareURL)
                    for (let index = 0; index < urls.length; index++) {
                        let uObj = new URL(urls[index].url)
                        let compareU = uObj.origin + uObj.pathname
                        if (compareURL == compareU) {
                            console.log(compareURL, compareU)
                            existsindex = index
                            exists = true
                        }
                    }
                    return { exists: exists, index: existsindex }
                } catch (error) {
                    console.log(error)
                    return false
                }
            },
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers, this.selectedTopics)
                console.log(sortedItems)
                return sortedItems
            },
            mainUrl(url) {
                if (!this.project.url) return false
                let exists = false
                try {
                    var urlObj = new URL(url)
                    var compareURL = urlObj.origin + urlObj.pathname
                    var uObj = new URL(this.project.url)
                    var compareU = uObj.origin + uObj.pathname
                    if (compareURL == compareU) {
                        exists = true
                    }
                } catch (error) {
                    console.log("SHITTY URL:", compareURL, compareU)
                }
                return exists
            },
            getRelevance(item) {
                if (!item.avgpos) {
                    return { value: 0 }
                }
                let value = Math.ceil((100 - item.avgpos) * 1.12)
                // const count = item.count
                // const totalSERPs = this.project.topics.filter((topic) => topic.selected).length
                // let sum = 0
                // for (let index = 0; index < totalSERPs; index++) {
                //     const positionEntry = item.positions[index]
                //     if (positionEntry) {
                //         sum += positionEntry.position
                //     } else {
                //         sum += 100
                //     }
                // }
                // let relevance = 150 - sum / totalSERPs
                // if (relevance > 100) {
                //     relevance = 100 - Math.random() * (totalSERPs - count) //add variance
                // }
                return { value: value > 100 ? 100 : value }
            },
            getRankColor(rank) {
                // #2DFF3D #6AEB50 #9EDD4E #D1EB57 #E6D05E #EDA653 #EDA653 #EB2727 #B80101 #8D0202
                // let colors = ["#00A30E", "#00B80F", "#9EDD4E", "#DDE236", "#E6D05E", "#F4BC7B", "#EDA653", "#EB2727", "#B80101", "#8D0202"]
                let helper = 9 - Math.ceil(rank / 100)
                return this.rankColors[helper]
            },
            getAVGpos(item) {
                let sum = 0
                if (item.averagePosition) {
                    let colorIndex = Math.ceil(item.averagePosition / 10)
                    let color = this.positionColors[colorIndex] ? this.positionColors[colorIndex] : this.positionColors[this.positionColors.length - 1]
                    return { value: item.averagePosition.toFixed(0), color: color }
                }
                if (item.positions.length) {
                    this.selectedTopics.map((t) => {
                        let e = item.positions.find((e) => e.keyword == t.keyword)
                        sum += e ? e.position : 100
                    })
                    // let sum = item.positions.reduce((pv, cv) => pv + cv.position, 0)
                    let avgpos = sum / this.selectedTopics.length
                    item.avgpos = avgpos

                    let colorIndex = Math.ceil(avgpos / 10)
                    let color = this.positionColors[colorIndex] ? this.positionColors[colorIndex] : this.positionColors[this.positionColors.length - 1]
                    return { value: avgpos.toFixed(0), color: color }
                }
                return { value: "-", color: "" }
            },
            selectItem(index, item) {
                let visibleIndex = this.project.competitors.indexOf(item)
                this.project.competitors[visibleIndex].selected = !this.project.competitors[visibleIndex].selected
                // if (this.project.competitors[visibleIndex].selected) {
                //     this.selected.push(item)
                // } else {
                //     this.selected = this.selected.filter((obj) => obj.url !== item.url)
                // }
                this.updateProject()
            },
            selectPage(event) {
                let selectedPageItems = event.items.filter((x) => x.selected)
                if (event.value && selectedPageItems.length == 0) {
                    //select
                    event.items.map((x) => {
                        let index = this.project.competitors.findIndex((comp) => comp.url == x.url)
                        this.project.competitors[index].selected = true
                    })
                } else {
                    event.items.map((x) => {
                        let index = this.project.competitors.findIndex((comp) => comp.url == x.url)
                        this.project.competitors[index].selected = false
                    })
                }
                this.updateProject()
            },
            async updateProject() {
                const url = process.env.VUE_APP_APIURL + "/cs/update"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: this.project
                }
                await this.$helpers.axiosPost(url, post_data)
            },
            getDescription(description) {
                if (description && description.length > 168) {
                    return description.substring(0, 168) + "..."
                }
                return description
            }
        },
        mounted() {}
    }
</script>

<style scoped>
    .loaderwrapper {
        padding: 50px;
    }
    .loadersubtext {
        text-align: center;
    }
    .loaderimg {
        margin: auto;
    }
    #loader {
        margin: auto;
    }
    .comp-table-area {
        /* background: #f8f8f8 !important; */
        border-radius: 5px;
    }
    .td_position {
        border-radius: 5px;
        padding-left: 5px !important;
        /* 
        background: rgb(210, 237, 234);
        background: linear-gradient(90deg, rgba(210, 237, 234, 1) 6%, rgba(238, 238, 238, 0.8645833333333334) 100%); */
    }
    .comp-table {
        background-color: transparent;
    }
    #cs_comp_table table > tbody > tr.pinnedurl {
        position: sticky !important;
        position: -webkit-sticky !important;
        top: 48px !important;
        z-index: 3 !important;
        background: var(--v-keywordcol-base) !important;
    }
    #cs_comp_table table > tbody > tr.pinnedurl > td {
        border-bottom: 4px solid rgb(202, 202, 202);
    }
    /* #cs_comp_table table > thead > tr > th:nth-child(1) {
        background: var(--v-keywordcol-base) !important;
    }
    #cs_comp_table table > tbody > tr > td:nth-child(1),
    #cs_comp_table table > tbody > tr > th:nth-child(1) {
        background: var(--v-keywordcol-base) !important;
    }
    #cs_comp_table table > tbody > tr > td:nth-child(2),
    #cs_comp_table table > thead > tr > th:nth-child(2) {
        background: var(--v-keywordcol-base) !important;
        border-right: 1.5px solid rgb(218, 218, 218);
    } */
    .posbar {
        border-radius: 3px;
        background-color: var(--v-primary-base);
        width: 6px;
        margin-top: auto;
        /* // height: 20px; */
    }
</style>
