<template>
    <div id="contentcontainter" class="standard-mode">
        <!-- <div class="mt-10" style="font-size: 24px" v-if="projects">Documents</div>
        <div style="font-size: 12px" v-if="projects">
            <v-chip color="primary">{{ projects ? projects.length : "0" }} {{ projects.length == 1 ? "Project" : "Projects" }}</v-chip>
        </div> -->

        <!-- Loader -->
        <!-- Loader -->
        <!-- Loader -->
        <div v-if="!projects">
            <div style="display: flex">
                <span class="circularprogress mx-auto">
                    <img id="loader" :src="getSrc()" />
                </span>
            </div>
        </div>

        <v-slide-x-transition>
            <v-row v-if="unfinishedProjects.length" no-gutters class="my-5">
                <v-col sm="12" md="6" xl="4">
                    <v-card class="quaroshadow mt-4 rounded_card" max-width="500px" v-if="unfinishedProjects.length">
                        <div class="backsheet"></div>
                        <div class="pa-4">
                            <div class="quarolbl mb-2" style="font-size: 12px">Finish Setup</div>
                            <div v-for="project in unfinishedProjects" :key="project._id" class="pa-2">
                                <div style="font-size: 18px; display: flex">
                                    <span> {{ project.title }}</span>
                                    <v-btn
                                        class="px-1 ml-auto my-auto"
                                        x-small
                                        outlined
                                        color="primary"
                                        @click="
                                            $router.push('/content-project/setup?id=' + project._id + '&step=' + project.setup.step)
                                            trackEvent('CS - Projects', 'open setup project', project._id, project.title)
                                        ">
                                        <v-icon class="mr-1" x-small>mdi-open-in-new</v-icon>open setup
                                    </v-btn>
                                    <v-btn
                                        class="px-1 ml-2 my-auto"
                                        x-small
                                        outlined
                                        color="deep-orange darken-2"
                                        @click="
                                            selectedDeleteItem = project
                                            deleteDialog.show = true
                                        "
                                        ><v-icon class="mr-1" x-small>mdi-delete</v-icon>delete
                                    </v-btn>
                                </div>
                                <div v-if="statusNotification" class="up_status">
                                    <div>
                                        Status: {{ statusNotification.message }} | {{ Math.ceil(statusNotification.progress) }} %
                                        <!-- <v-icon style="padding-bottom: 1px" v-if="statusNotification.progress === 100" color="primary" x-small>mdi-check-circle-outline</v-icon> -->
                                    </div>
                                    <div><v-progress-linear rounded height="5px" class="ma-auto" color="primary" :value="statusNotification.progress"></v-progress-linear></div>
                                </div>
                                <div v-else class="up_status">
                                    <span class="setup_progress_bar_text">Step {{ project.setup.step }}/ 6</span>
                                    <v-progress-linear height="5px" rounded :value="(project.setup.step / 6) * 100"></v-progress-linear>
                                </div>
                            </div>
                        </div>
                    </v-card>
                </v-col>
                <!-- <v-col style="display: flex" v-if="projects && projects.length">
                    <div class="mt-auto ml-auto">
                        <v-btn class="pl-2 ml-auto" @click="createNew()" color="primary" filled small>
                            <v-icon class="mr-1" small>mdi-plus-circle-outline</v-icon>
                            new project
                        </v-btn>
                    </div>
                </v-col> -->
            </v-row>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <v-row class="mt-5" v-if="noProjects">
                <v-col>
                    <v-card color="primary" max-width="600px" class="rounded_card quaroshadow">
                        <!-- <v-btn right absolute @click="dialog = false" class="mb-1" icon><v-icon color="primary">mdi-close</v-icon></v-btn> -->
                        <v-card-text class="">
                            <v-row>
                                <v-col md="4" sm="4" style="display: flex">
                                    <img width="120" class="mx-auto" src="../../assets/undraw_folder.svg" />
                                </v-col>
                                <v-col md="8" sm="8" class="white--text">
                                    <h2 class="my-2">
                                        {{ bannertext.title }}
                                        <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip>
                                    </h2>

                                    <p class="mb-3">{{ bannertext.firstp }}</p>

                                    <v-btn :disabled="$store.state.content_suite_group.id !== 1" outlined color="white" class="pl-2" small @click="$router.push('/content-project/setup')">
                                        <v-icon small class="mr-1">mdi-plus-circle-outline</v-icon>
                                        create new project</v-btn
                                    >
                                </v-col>
                            </v-row>

                            <!-- {{welcome_message.secondp}} -->
                        </v-card-text>
                    </v-card>
                </v-col>
            </v-row>
        </v-slide-x-transition>
        <v-slide-x-transition>
            <div v-if="(projects && projects.length) || filters.length" class="flex">
                <v-card class="mx-auto" style="min-width: 100%">
                    <v-toolbar id="projects-tool-bar" flat :color="$vuetify.theme.dark ? '' : 'blue-grey lighten-5'">
                        <v-toolbar-items>
                            <v-tooltip top>
                                <template v-slot:activator="{ on }">
                                    <v-btn icon v-on="on" class="px-0" color="grey darken-2" @click="showfolder = !showfolder">
                                        <v-icon small>{{ showfolder ? "mdi-arrow-collapse-right" : "mdi-arrow-collapse-left" }}</v-icon></v-btn
                                    >
                                </template>
                                <span>{{ showfolder ? "hide" : "show" }} folders</span>
                            </v-tooltip>

                            <v-menu offset-y rounded dense v-if="true">
                                <template v-slot:activator="{ on }">
                                    <v-btn icon class="storage-select-button" v-on="on">
                                        <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
                                    </v-btn>
                                </template>
                                <v-list rounded dense class="storage-select-list">
                                    <v-list-item v-for="(item, index) in storages" :key="index" :disabled="item.code === storageObject.code || true" @click="">
                                        <v-list-item-icon>
                                            <v-icon v-text="item.icon"></v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title>{{ item.name }}</v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                            <v-btn text color="grey darken-2" @click="folderSelect(null)">
                                <!-- :input-value="path === '/'" @click="changePath('/')" -->
                                <!-- <v-icon color="grey darken-1" class="mr-2">{{ storageObject.icon }}</v-icon> -->
                                {{ storageObject.name }}
                            </v-btn>

                            <v-icon v-if="selectedFolderTitle" color="grey darken-2">mdi-chevron-right</v-icon>
                            <v-btn v-if="selectedFolderTitle" color="grey darken-2" text>{{ selectedFolderTitle }}</v-btn>

                            <!-- <template v-for="(segment, index) in pathSegments">
                        <v-btn text :input-value="index === pathSegments.length - 1" :key="index + '-btn'" @click="changePath(segment.path)">{{ segment.name }}</v-btn>
                    </template> -->
                        </v-toolbar-items>
                        <div class="flex-grow-1"></div>

                        <template v-if="$vuetify.breakpoint.smAndUp">
                            <!-- <v-tooltip bottom v-if="pathSegments.length > 0">
                        <template v-slot:activator="{ on }">
                            <v-btn icon @click="goUp" v-on="on">
                                <v-icon>mdi-arrow-up-bold-outline</v-icon>
                            </v-btn>
                        </template>
                        <span v-if="pathSegments.length === 1">Up to "root"</span>
                        <span v-else>Up to "{{ pathSegments[pathSegments.length - 2].name }}"</span>
                    </v-tooltip> -->
                            <!-- <v-menu :close-on-content-click="false" :nudge-width="200" offset-y>
                            <template v-slot:activator="{ on }">
                                <v-btn v-if="true" icon v-on="on" title="Create Folder">
                                    <v-icon>mdi-folder-plus-outline</v-icon>
                                </v-btn>
                            </template>
                            <v-card>
                                <v-card-text>
                                    <v-text-field label="Name" hide-details></v-text-field>
                                </v-card-text>
                                <v-card-actions>
                                    <div class="flex-grow-1"></div>
                                    <v-btn depressed>Cancel</v-btn>
                                    <v-btn color="success" depressed>Create Folder</v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-menu> -->
                            <!-- <v-btn v-if="true" icon title="Upload Files">
                            <v-icon>mdi-plus-circle</v-icon>
                            <input v-show="false" ref="inputUpload" type="file" multiple />
                        </v-btn> -->
                            <FilterMenu class="mr-4" :fields="filterFields" @filtersUpdated="filtersUpdated"></FilterMenu>
                            <v-text-field
                                class="pr-5"
                                v-model="researchSearch"
                                rounded
                                dense
                                style="max-width: 400px"
                                filled
                                append-icon="mdi-magnify"
                                label="search projects..."
                                single-line
                                hide-details
                                id="kwsearch"></v-text-field>
                            <v-btn :disabled="$store.state.content_suite_group.id !== 1" rounded class="ml-auto" @click="createNew()" color="primary" filled>
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                                new project
                            </v-btn>
                        </template>
                        <template v-else>
                            <v-btn :disabled="$store.state.content_suite_group.id !== 1" rounded class="ml-auto" @click="createNew()" color="primary" filled>
                                <v-icon class="mr-2">mdi-plus-circle-outline</v-icon>
                            </v-btn>
                        </template>
                    </v-toolbar>
                    <v-row no-gutters class="d-flex" style="flex-wrap: nowrap">
                        <v-slide-x-reverse-transition>
                            <v-col v-if="showfolder" class="fixed-column">
                                <!-- folderbar -->
                                <folder-bar
                                    v-if="showfolder"
                                    :projects="projects"
                                    @folderSelect="folderSelect"
                                    @addedToFolderNotify="addFolderNotification"
                                    :clearSelection="clearFolderSelection"
                                    :addProjectToFolderVar="addProjectToFolderVar"
                                    :projectToFolderAdd="projectToFolderAdd"
                                    :deleteProjectToFolderVar="deleteProjectToFolderVar"
                                    :projectToFolderDelete="projectToFolderDelete"
                                    :updateSelection="updateFolderSelection"></folder-bar>
                            </v-col>
                        </v-slide-x-reverse-transition>
                        <v-divider v-if="showfolder" vertical :style="$vuetify.theme.dark ? 'border-width: 2px' : 'border-color: rgb(237 239 241); border-width: 2px'"></v-divider>
                        <v-col style="overflow-x: hidden">
                            <!-- projects -->
                            <v-card flat tile min-height="380" class="flexible-column" style="height: 100%">
                                <v-card-text class="grow pa-0">
                                    <v-data-table
                                        id="cs_project_table"
                                        :height="tableHeight"
                                        class="pl-0"
                                        fixed-header
                                        :search.sync="researchSearch"
                                        @click:row="onRowClick"
                                        :headers="headers"
                                        :loading="loading"
                                        :items="filteredProjects"
                                        :items-per-page="20"
                                        :sort-by="'_id'"
                                        :sort-desc="true"
                                        :footer-props="{
                                            'items-per-page-options': [10, 20, 50]
                                        }">
                                        <!-- header slot -->
                                        <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                                            <span :key="index">
                                                <span v-bind:style="{ width: header.width }">
                                                    <!-- Adding tooltips to header -->
                                                    <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                                        <template v-slot:activator="{ on }">
                                                            <span v-on="on" class="quarotblheader" style="font-size: 14px">
                                                                {{ header.text }}
                                                                <v-icon v-if="header.tooltip" x-small>mdi-help</v-icon>
                                                            </span>
                                                        </template>
                                                        <span>
                                                            <v-row no-gutters>
                                                                <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                            </v-row>
                                                            {{ header.tooltip }}
                                                        </span>
                                                    </v-tooltip>
                                                </span>
                                            </span>
                                        </template>
                                        <!-- Table Body -->
                                        <template v-slot:body="{ items }">
                                            <tbody v-if="items.length" style="color: rgb(60, 60, 60); font-weight: 400 !important">
                                                <tr v-for="(item, itemIndex) in items" :key="itemIndex" @click="onRowClick(item)" style="cursor: pointer">
                                                    <td v-for="(header, headerIndex) in headers" :key="headerIndex" class="py-4">
                                                        <!-- ------- -->
                                                        <!-- title -->
                                                        <!-- ------- -->
                                                        <div v-if="header.value === 'title'" class="pl-5">
                                                            <span class="tabletext"> {{ item[header.value] }}</span>
                                                            <div v-if="item.url.length" class="table_url mt-1">
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-chip outlined color="grey darken-2" small @click="openURL(item.url)" class="pl-2">
                                                                            <!-- <v-icon :size="10" class="mr-1 py-auto">mdi-web</v-icon> -->
                                                                            <img
                                                                                class="mr-1"
                                                                                style="width: 12; border-radius: 5px"
                                                                                :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + $helpers.formatURL(item.url) + '&sz=12'"
                                                                                alt="" />
                                                                            {{ $helpers.formatURL(item.url, 50) }}
                                                                        </v-chip>
                                                                    </template>
                                                                    <span>{{ item.url + " " + item.url.length }} </span>
                                                                </v-tooltip>
                                                            </div>
                                                            <div v-else>
                                                                <v-btn
                                                                    v-if="$store.state.content_suite_group.id == 1"
                                                                    rounded
                                                                    @click="
                                                                        (event) => {
                                                                            event.stopPropagation()
                                                                            addURLDialog.id = item._id
                                                                            addURLDialog.show = true
                                                                        }
                                                                    "
                                                                    class="px-1"
                                                                    x-small
                                                                    outlined
                                                                    color="primary">
                                                                    <v-icon class="mr-1" x-small>mdi-plus-circle-outline</v-icon>add url</v-btn
                                                                >
                                                            </div>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- contentscore ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'editor.content_score'" v-bind="(color = scoreColor(item))" class="d-flex">
                                                            <vue-ellipse-progress
                                                                :color="color.linecolor"
                                                                empty-color="#f5f5f5"
                                                                :empty-color-fill="color.emptyColorFill"
                                                                :thickness="3"
                                                                :empty-thickness="2"
                                                                class="white--text my-auto"
                                                                lineMode="out 2"
                                                                :legend="true"
                                                                animation="rs 700 1000"
                                                                fontSize="1.1rem"
                                                                :size="60"
                                                                :dot="{
                                                                    size: 5,
                                                                    width: '2px',
                                                                    backgroundColor: color.linecolor,
                                                                    transitionDuration: '1000ms'
                                                                }"
                                                                :progress="item.editor ? item.editor.content_score : 0">
                                                                <!-- <span slot="legend-caption" style="font-size: 9px"> SCORE </span> -->
                                                            </vue-ellipse-progress>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- main_keyword ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'main_keyword'" style="white-space: nowrap">
                                                            <!-- <v-chip style="background-color: rgb(237 239 241) !important" color="primary" outlined class="white--text">
                                                                {{ item[header.value] }}
                                                            </v-chip> -->
                                                            <v-icon small class="mr-1">mdi-key-outline</v-icon> {{ item[header.value] }}
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- device ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'device'" style="text-transform: capitalize; white-space: nowrap">
                                                            <v-icon small>{{ item[header.value] == "desktop" ? "mdi-monitor" : "mdi-cellphone" }}</v-icon> {{ item[header.value] }}
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- Language ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'langloc.language_code'" style="text-transform: uppercase; white-space: nowrap">
                                                            <v-icon small>mdi-map-marker</v-icon> {{ textMap.find((e) => e.location_code == item.langloc.location_code).location_text }}
                                                        </div>
                                                        <!-- ---------------- -->
                                                        <!---- Competitors ----->
                                                        <!-- ---------------- -->
                                                        <div v-if="header.value === 'competitors'">
                                                            <!-- Competitors BTN -->
                                                            <div class="mb-2">
                                                                <v-btn outlined @click="openAnalysis(item)" class="px-1" small color="primary">
                                                                    <v-icon v-if="item.competitors_status == 'done'" class="mr-1" x-small>mdi-google-analytics</v-icon>
                                                                    <v-progress-circular
                                                                        v-else-if="item.competitors_status == 'loading'"
                                                                        class="mr-1"
                                                                        :size="12"
                                                                        :width="2"
                                                                        indeterminate
                                                                        color="primary"></v-progress-circular>
                                                                    <v-tooltip v-else top :disabled="item.competitors_status == 'failed' ? true : false">
                                                                        <template v-slot:activator="{ on }">
                                                                            <v-icon v-on="on" hint="10" color="#FCBA03" class="mr-1" small>mdi-alert</v-icon>
                                                                        </template>
                                                                        <span>Something went wrong while fetching some competitor data. Click here to start the process again!</span>
                                                                    </v-tooltip>
                                                                    competitor analysis
                                                                </v-btn>
                                                            </div>
                                                            <!-- WDF BTN  -->
                                                            <div>
                                                                <v-btn outlined @click="openWDFAnalysis(item)" class="px-1" small color="primary">
                                                                    <v-icon class="mr-1" x-small>mdi-google-analytics</v-icon>WDF*IDF analysis
                                                                </v-btn>
                                                            </div>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- status ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'workflow.active_stage.title'">
                                                            <v-btn
                                                                @click="
                                                                    (event) => {
                                                                        event.stopPropagation()
                                                                        workflowDialog = true
                                                                        selectedWorkflowProject = item
                                                                    }
                                                                "
                                                                small
                                                                outlined
                                                                rounded
                                                                color="primary"
                                                                class="qchip">
                                                                <v-icon class="mr-1" small>mdi-list-status</v-icon>
                                                                {{ item.workflow ? item.workflow.active_stage.title : "Briefing" }}
                                                            </v-btn>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- editor ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'editor.word_count'">
                                                            <!-- <div
                                                                style="
                                                                    white-space: nowrap;
                                                                    color: #666666;
                                                                    font-weight: 400 !important;
                                                                    text-transform: uppercase !important;
                                                                    white-space: nowrap !important;
                                                                ">
                                                                {{ item.editor ? item.editor.word_count : 0 }} / {{ Math.ceil(item.settings.word_count) }} WORDS
                                                            </div> -->
                                                            <!-- <v-progress-linear class="white--text" style="border-radius: 12px; font-size: 12px" rounded :value="80" color="primary" height="24">
                                                                {{ item.editor ? item.editor.word_count : 0 }} / {{ Math.ceil(item.settings.word_count) }}
                                                            </v-progress-linear> -->
                                                            <v-progress-linear
                                                                class="white--text"
                                                                style="border-radius: 12px; font-size: 12px"
                                                                rounded
                                                                outlined
                                                                :value="calculateWCProgress(item.editor ? item.editor.word_count : 0, item.settings.word_count)"
                                                                :color="getColor(item.editor ? item.editor.word_count : 0, item.settings.word_count)"
                                                                height="24">
                                                                {{ item.editor ? item.editor.word_count : 0 }} / {{ Math.ceil(item.settings.word_count) }}
                                                            </v-progress-linear>
                                                            <!-- <div>
                                                                <v-btn outlined @click="openEditor(item)" class="px-1" small color="primary"
                                                                    ><v-icon class="mr-1" x-small>mdi-square-edit-outline</v-icon>editor</v-btn
                                                                >
                                                            </div> -->
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- briefing ----->
                                                        <!-- -------------- -->
                                                        <!-- <div v-if="header.value === 'briefing'">
                                                            -
                                                            <div>
                                                                <v-btn outlined @click="openBriefing(item)" class="px-1" small color="primary"
                                                                    ><v-icon class="mr-1" x-small>mdi-open-in-new</v-icon>briefing</v-btn
                                                                >
                                                            </div>
                                                        </div> -->
                                                        <div v-if="header.value === 'actions'" style="overflow: hidden">
                                                            <v-menu :disabled="$store.state.content_suite_group.id !== 1" offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                                                                <template v-slot:activator="{ on }">
                                                                    <v-btn v-on="on" icon class="ml-auto">
                                                                        <v-icon>mdi-dots-vertical</v-icon>
                                                                    </v-btn>
                                                                </template>
                                                                <v-list rounded dense>
                                                                    <v-list-item @click="openAddAuthorsDialog(item)">
                                                                        <v-icon small class="mr-2">mdi-account-multiple-plus</v-icon><v-list-item-title>add authors</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item @click="addProjectToFolder(item)">
                                                                        <v-icon small class="mr-2">mdi-folder-plus</v-icon><v-list-item-title>add to folder</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        @click="
                                                                            shareMenu = true
                                                                            shareItem = {
                                                                                _id: item._id,
                                                                                listname: item.title
                                                                            }
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-share-variant</v-icon><v-list-item-title>share</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        @click="
                                                                            editProjectDialog = true
                                                                            editProject = item
                                                                            trackEvent('CS - Projects', 'edit', item._id, item.title)
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-cog</v-icon><v-list-item-title>settings</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item
                                                                        @click="
                                                                            selectedDeleteItem = item
                                                                            deleteDialog.show = true
                                                                        ">
                                                                        <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>delete</v-list-item-title>
                                                                    </v-list-item>
                                                                    <v-list-item v-if="malte" @click="$router.push('/content-project/setup?id=674db4378a47311bab30cb2e&step=1')">
                                                                        <v-icon small class="mr-2">mdi-cog</v-icon><v-list-item-title>setup</v-list-item-title>
                                                                    </v-list-item>
                                                                </v-list>
                                                            </v-menu>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- authors ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'authors.username'">
                                                            <v-slide-x-transition hide-on-leave>
                                                                <v-row
                                                                    no-gutters
                                                                    v-if="item.authors && item.authors.length > 0"
                                                                    style="display: inline-flex !important; width: 150px; position: relative"
                                                                    @click="openAddAuthorsDialog(item)">
                                                                    <!-- <div class="authoravat" :style="'position: absolute; top: -22px; '">
                                                                        <v-tooltip top>
                                                                            <template v-slot:activator="{ on }">
                                                                                <v-avatar v-on="on" style="background-color: rgb(243 243 243)" size="36" class="pr-0 autoravat">
                                                                                    <span class="white--text" style="font-size: 1.1em">
                                                                                        <v-icon>mdi-plus</v-icon>
                                                                                    </span>
                                                                                </v-avatar>
                                                                            </template>
                                                                            <span>add author</span>
                                                                        </v-tooltip>
                                                                    </div> -->
                                                                    <div v-for="(author, index) in item.authors">
                                                                        <div class="authoravat" v-if="author.username" :style="'position: absolute; top: -22px; left: ' + (index * 20 + 'px;')">
                                                                            <v-tooltip top>
                                                                                <template v-slot:activator="{ on }">
                                                                                    <v-avatar
                                                                                        v-on="on"
                                                                                        :style="{ backgroundColor: $helpers.stringToBackgroundColor(author.username) }"
                                                                                        size="36"
                                                                                        class="pr-0 autoravat">
                                                                                        <span class="white--text" style="font-size: 1.1em"> {{ initials(author.username) }}</span>
                                                                                    </v-avatar>
                                                                                </template>
                                                                                <span>{{ author.username }}</span>
                                                                            </v-tooltip>
                                                                        </div>
                                                                    </div>
                                                                </v-row>
                                                            </v-slide-x-transition>
                                                            <v-slide-x-transition hide-on-leave>
                                                                <v-tooltip top v-if="!item.authors || !item.authors.length">
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-btn
                                                                            v-on="on"
                                                                            :style="{ backgroundColor: $vuetify.theme.dark ? 'rgb(70, 70, 70)' : 'rgb(243, 243, 243)' }"
                                                                            icon
                                                                            @click="openAddAuthorsDialog(item)">
                                                                            <v-icon>mdi-plus</v-icon>
                                                                        </v-btn>
                                                                    </template>
                                                                    <span>Add author</span>
                                                                </v-tooltip>
                                                            </v-slide-x-transition>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- created ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === '_id'">
                                                            <span>{{ getCreatedDate(item._id) }}</span>
                                                        </div>
                                                        <!-- -------------- -->
                                                        <!---- created by ----->
                                                        <!-- -------------- -->
                                                        <div v-if="header.value === 'owner'">
                                                            <div>
                                                                <v-tooltip top>
                                                                    <template v-slot:activator="{ on }">
                                                                        <v-avatar
                                                                            v-on="on"
                                                                            v-if="item[header.value]"
                                                                            :style="{ backgroundColor: $helpers.stringToBackgroundColor(getDecryptedName(item).value) }"
                                                                            size="35"
                                                                            class="pr-0 avat">
                                                                            <span class="white--text" style="font-size: 1.1em">{{ initials(getDecryptedName(item).value) }}</span>
                                                                        </v-avatar>
                                                                    </template>
                                                                    <span>{{ getDecryptedName(item).value }}</span>
                                                                </v-tooltip>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                            <tbody v-else>
                                                <tr>
                                                    <td colspan="10">
                                                        <div class="d-flex">
                                                            <div style="margin-left: 30vw; color: grey" class="my-1">
                                                                <v-icon color="grey" size="16px">mdi-alert-circle-outline</v-icon>
                                                                no projects found...
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td></td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-data-table>
                                </v-card-text>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card>
            </div>
        </v-slide-x-transition>
        <!--         
        <v-dialog v-model="manageFolderProjects" max-width="500px" transition="slide-x-transition" hide-details @click:outside="manageFolderProjects = false">
            <v-card>
                <div class="backsheet"></div>
                <v-btn class="close-btn" absolute top right icon color="primary" @click="manageFolderProjects = false"><v-icon>mdi-close</v-icon></v-btn>
                <v-card-text class="pt-5 pb-0">
                    <div class="lbl pb-1">MANAGE PROJECTS</div>
                    <v-autocomplete label="Search..." filled rounded v-model="selectedFolderProjects" :items="projects" item-text="title" item-value="_id" chips deletable-chips multiple>
                    </v-autocomplete>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" color="primary" @click="updateFolderProjects" rounded>Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog> -->

        <!-- ----------------------- -->
        <!--   Project Settings Dialog  -->
        <!-- ----------------------- -->
        <EditProject
            :show="editProjectDialog"
            :project="editProject"
            @close="editProjectDialog = false"
            @refresh="
                fetchProjects()
                editProjectDialog = false
            "></EditProject>

        <WorkflowDialog
            @close="
                stageToShow = null
                workflowDialog = false
                selectedWorkflowProject = null
            "
            @addAuthors="addAuthors"
            :show="workflowDialog"
            :project="selectedWorkflowProject"
            :stageToShow="stageToShow"></WorkflowDialog>
        <!-- ----------------------- -->
        <!--   Delete Column Dialog  -->
        <!-- ----------------------- -->
        <DeleteDialog
            :deleteDialog="deleteDialog.show"
            :selectedDeleteItem="selectedDeleteItem ? selectedDeleteItem.title : ''"
            deleteLabel="Content Project"
            :loading="deleteDialog.loading"
            @delete="deleteProject(selectedDeleteItem)"
            @close="deleteDialog.show = false">
        </DeleteDialog>
        <AddUrlDialogVue
            :show="addURLDialog.show"
            :id="addURLDialog.id"
            @started="addedURLNotification()"
            @added="
                fetchProjects()
                this.url_loading = false
            "
            @close="addURLDialog.show = false"
            :setMainURL="true"
            :addLighthouseData="true"></AddUrlDialogVue>
        <Sharedialog :shareMenu="shareMenu" :shareItem="shareItem" @disableShareMenu="shareMenu = false" :mountedOn="{ url: 'content-projects', name: 'Content Project' }"> </Sharedialog>
        <responsible-selection v-model="responsibleDialog" @assign="setAuthors" :user="authors ? authors : []" authors />

        <v-snackbar top v-model="notification.show" :color="notification.color" v-if="notification">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
    </div>
</template>

<script>
    import DeleteDialog from "../../components/dialogs/DeleteDialog.vue"
    import AddUrlDialogVue from "../../components/content-suite/AddUrlDialog.vue"
    import WorkflowDialog from "../../components/content-suite/Workflow.vue"
    import EditProject from "../../components/content-suite/EditProject.vue"
    import FolderBar from "../../components/content-suite/FolderBar.vue"
    import Sharedialog from "../../components/dialogs/shareDialog/Sharedialog.vue"
    import FilterMenu from "../../components/monitoring/FilterMenu.vue"
    import ResponsibleSelection from "../../components/content-suite/AddResponsible.vue" // Passe den Pfad entsprechend deiner Dateistruktur an

    export default {
        name: "cs-projects",
        components: {
            AddUrlDialogVue,
            DeleteDialog,
            Sharedialog,
            FolderBar,
            EditProject,
            WorkflowDialog,
            FilterMenu,
            ResponsibleSelection
        },
        data() {
            return {
                //share
                authors: null,
                responsibleDialog: false,
                stageToShow: null,
                selectedWorkflowProject: null,
                workflowDialog: false,
                editProject: null,
                editProjectDialog: false,
                showfolder: true,
                storageObject: {
                    name: "All Projects",
                    code: "all",
                    icon: "mdi-folder-multiple-outline"
                },
                storages: [
                    {
                        name: "All Projects",
                        code: "all",
                        icon: "mdi-folder-multiple-outline"
                    },
                    {
                        name: "Archived Projects",
                        code: "archived",
                        icon: "mdi-database-outline"
                    }
                ],
                researchSearch: "",
                shareMenu: false,
                shareItem: {},

                deleteDialog: { show: false, loading: false },
                selectedDeleteItem: null,
                textMap: [
                    { location_text: "de", language_code: "de", location_code: "2276" },
                    { location_text: "ch", language_code: "de", location_code: "2756" },
                    { location_text: "at", language_code: "de", location_code: "2040" },
                    { location_text: "uk", language_code: "en", location_code: "2826" },
                    { location_text: "usa", language_code: "en", location_code: "2840" },
                    { location_text: "it", language_code: "it", location_code: "2380" }
                ],
                addURLDialog: {
                    id: null,
                    show: false
                },
                noProjects: false,
                bannertext: {
                    title: "Create a Project",
                    firstp: "Use Content Projects to create new or optimize existing content pieces or landing pages!"
                },
                notification: null,
                projects: null,
                headers: [
                    {
                        text: "Project",
                        value: "title", //domain and path
                        sortable: true,
                        width: 400,
                        filterData: { allowed: true, type: "text" }
                    },
                    {
                        text: "Content Score",
                        value: "editor.content_score",
                        sortable: true,
                        filterData: { allowed: true, type: "number" }
                    },
                    {
                        text: "Topic",
                        value: "main_keyword",
                        sortable: true,
                        filterData: { allowed: true, type: "text" }
                    },

                    {
                        text: "Word Count",
                        value: "editor.word_count",
                        sortable: true,
                        filterData: { allowed: true, type: "number" }
                    },
                    {
                        text: "Status",
                        value: "workflow.active_stage.title",
                        sortable: true,
                        type: "text",
                        filterData: {
                            allowed: true,
                            type: "option",
                            options: {
                                values: []
                            }
                        }
                    },
                    {
                        text: "Authors",
                        value: "authors.username",
                        sortable: true,
                        type: "array",
                        filterData: {
                            allowed: true,
                            type: "array",
                            options: {
                                values: []
                            }
                        }
                    },
                    {
                        text: "Device",
                        value: "device",
                        sortable: true,
                        filterData: {
                            allowed: true,
                            type: "option",
                            options: {
                                values: ["mobile", "desktop", "tablet"]
                            }
                        }
                    },
                    {
                        text: "Location",
                        value: "langloc.language_code",
                        sortable: true,
                        filterData: {
                            allowed: true,
                            type: "option",
                            options: {
                                values: []
                            }
                        }
                    },

                    {
                        text: "Created by",
                        value: "owner",
                        sortable: false,
                        filterData: {
                            allowed: true,
                            type: "option",
                            options: {
                                values: []
                            }
                        }
                    },
                    {
                        text: "Created",
                        value: "_id",
                        sortable: true,
                        filterData: { allowed: false },
                        sort: (a, b) => this.mongoIdSort(a, b)
                    },

                    {
                        text: "",
                        value: "actions",
                        sortable: false,
                        align: "end",
                        width: 50,
                        filterData: { allowed: false }
                    }
                ],
                selectedFolderTitle: "",
                selectedFolder: null,
                unfinishedProjects: [],
                statusNotification: null,
                filteredProjects: [],
                loading: false,
                tableHeight: "",
                table_sort_desc: [true],
                table_sort_by: ["title"],
                table_items_per_page: 10,
                projectToFolderAdd: null,
                addProjectToFolderVar: 0,
                projectToFolderDelete: null,
                deleteProjectToFolderVar: 0,
                filterFields: [],
                filters: [],
                clearFolderSelection: 0,
                updateFolderSelection: 0
            }
        },
        watch: {
            table_sort_desc() {
                console.log(this.table_sort_desc)
                console.log(this.table_sort_by)
                if (!this.user_settings.cs_projects_sort) this.user_settings.cs_projects_sort = {}
                this.user_settings.cs_projects_sort.sort = this.table_sort_desc
                this.user_settings.cs_projects_sort.column = this.table_sort_by

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            table_sort_by() {
                console.log(this.table_sort_by)
                // this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            },
            table_items_per_page() {
                console.log(this.table_items_per_page)
                this.user_settings.cs_projects_rowsperpage = this.table_items_per_page

                this.$store.dispatch("CHANGE_USER_SETTINGS", this.user_settings)
            }
        },
        computed: {
            user_settings() {
                return this.$store.state.user_settings
            },
            malte() {
                return this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) == "malte@quaro.io"
            },
            company_id() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            },
            obi() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) == 1547
            }
        },
        methods: {
            getSrc() {
                if (this.company_id == "1547") {
                    return require("@/assets/quaro-q-obi.svg")
                } else {
                    return require("@/assets/quaro-q.svg")
                }
            },
            async setAuthors(responsible) {
                try {
                    console.log("responsioble", responsible)
                    let projectindex_filtered = this.filteredProjects.findIndex((x) => this.selectedWorkflowProject._id == x._id)
                    if (projectindex_filtered > -1) {
                        console.log("project found")
                        this.filteredProjects[projectindex_filtered].authors = []
                        responsible.user.map((u) => {
                            this.filteredProjects[projectindex_filtered].authors.push({ id: u.id, username: u.username, rights: {} })
                        })
                    }
                    let projectindex = this.projects.findIndex((x) => this.selectedWorkflowProject._id == x._id)
                    if (projectindex > -1) {
                        console.log("project found")
                        this.projects[projectindex].authors = []
                        responsible.user.map((u) => {
                            this.projects[projectindex].authors.push({ id: u.id, username: u.username, rights: {} })
                        })
                    }
                    const post_data = {
                        company_id: localStorage.company_id,
                        user: responsible.user,
                        project_id: this.selectedWorkflowProject._id
                    }
                    const url = process.env.VUE_APP_APIURL + "/cs/author/set"
                    let response_data = await this.$helpers.axiosPost(url, post_data)

                    this.sendMailNotification(
                        this.selectedWorkflowProject,
                        responsible.user,
                        this.selectedWorkflowProject.langloc.language_code == "de" ? "notifiy_new_project_assigned_mail_de.html" : "notifiy_new_project_assigned_mail_en.html",
                        this.selectedWorkflowProject.langloc.language_code == "de" ? "Dir wurde ein Projekt zugewiesen!" : "A project has been assigned to you!"
                    )
                } catch (error) {
                    console.log(error)
                }
            },
            async addAuthors(responsible) {
                try {
                    console.log("responsioble", responsible)
                    let projectindex = this.projects.findIndex((x) => this.selectedWorkflowProject._id == x._id)
                    if (projectindex > -1) {
                        console.log("project found add")
                        if (!this.projects[projectindex].authors) this.projects[projectindex].authors = []
                        responsible.user.map((u) => {
                            this.projects[projectindex].authors.push({ id: u.id, username: u.username, rights: {} })
                        })
                    }
                    let projectindex_filtered = this.filteredProjects.findIndex((x) => this.selectedWorkflowProject._id == x._id)
                    if (projectindex_filtered > -1) {
                        console.log("project found add")
                        if (!this.filteredProjects[projectindex_filtered].authors) this.filteredProjects[projectindex_filtered].authors = []
                        responsible.user.map((u) => {
                            this.filteredProjects[projectindex_filtered].authors.push({ id: u.id, username: u.username, rights: {} })
                        })
                    }
                    const post_data = {
                        company_id: localStorage.company_id,
                        user: responsible.user,
                        project_id: this.selectedWorkflowProject._id
                    }
                    const url = process.env.VUE_APP_APIURL + "/cs/author/add"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                } catch (error) {
                    console.log(error)
                }
            },
            openAddAuthorsDialog(project) {
                event.stopPropagation()
                let projectindex = this.filteredProjects.findIndex((x) => project._id == x._id)
                console.log(project)
                this.authors = this.filteredProjects[projectindex].authors
                console.log("authors", this.authors)
                this.selectedWorkflowProject = project
                this.responsibleDialog = true
            },
            async sendMailNotification(project, users, template = "notifiy_new_project_assigned_mail_en.html", subject = "Dir wurde ein Projekt zugewiesen!", additionalData = {}) {
                try {
                    console.log(project)

                    let mailData = []
                    if (users) {
                        let userMailData = users.map((userData) => {
                            let obj = { ...additionalData }
                            let name = userData.username.split(" ")[0] || userData.username
                            obj.address = userData.user
                            obj.firstname = name
                            obj.button = this.obi ? "https://apollon.obi.com" + "/content-projects?ow=" + project._id : process.env.VUE_APP_URL + "/content-projects?ow=" + project._id
                            obj.projectTitle = project.title
                            obj.template = template
                            obj.subject = subject
                            return obj
                        })
                        mailData = [...mailData, ...userMailData]
                    }

                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: project._id,
                        mailData: mailData
                    }

                    const url = process.env.VUE_APP_APIURL + "/cs/notify-user"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                } catch (error) {
                    console.log(error)
                }
            },
            mongoIdSort(a, b) {
                const extractDateTimestamp = (id) => {
                    return parseInt(id.toString().slice(0, 8), 16)
                }

                const timestampA = extractDateTimestamp(a)
                const timestampB = extractDateTimestamp(b)

                // Vergleichslogik für die Sortierreihenfolge
                if (timestampA < timestampB) {
                    return -1
                }
                if (timestampA > timestampB) {
                    return 1
                }
                return 0
            },
            setFilterFields() {
                console.log(this.projects)
                let filterFields = this.headers.filter((h) => h.filterData.allowed === true)
                console.log(filterFields)
                filterFields = filterFields.map((h) => {
                    if (h.filterData.options && h.filterData.options.values.length === 0) {
                        // generate Options
                        let optionsSet = []
                        // this.projects.map((project) => {
                        //     optionsSet.push(project[h.value])
                        // })
                        for (let index = 0; index < this.projects.length; index++) {
                            try {
                                const project = this.projects[index]
                                if (h.value == "owner") {
                                    optionsSet.push(this.getDecryptedName(project).value)
                                } else if (h.value == "authors.username") {
                                    project.authors.map((a) => {
                                        if (a.username) {
                                            optionsSet.push(a.username)
                                        }
                                    })
                                } else {
                                    const propertyPath = h.value.split(".") // Splitte den String an den Punkten
                                    let propertyValue = project
                                    // Zugriff auf die Eigenschaft im verschachtelten Objekt
                                    propertyPath.forEach((path) => {
                                        propertyValue = propertyValue[path]
                                    })
                                    optionsSet.push(propertyValue)
                                }
                            } catch (error) {
                                console.log(error)
                            }
                        }
                        h.filterData.options.values = [...new Set(optionsSet)]
                    }
                    let obj = { name: h.text, key: h.value, ...h.filterData }
                    return obj
                })

                console.log(filterFields)
                this.filterFields = filterFields
            },
            filtersUpdated(filters) {
                console.log("filter updated: ", filters)
                this.filters = filters
                this.fetchProjects()
            },
            openURL(url) {
                event.stopPropagation()
                window.open(url, "_blank").focus()
            },
            getColor(currentCount, targetCount) {
                const percentage = this.calculateWCProgress(currentCount, targetCount)
                if (percentage <= 20) {
                    return "orange darken-4"
                } else if (percentage <= 50) {
                    return "amber darken-2"
                } else if (percentage <= 80) {
                    return "yellow darken-2"
                } else {
                    return "light-green darken-1"
                }
            },
            calculateWCProgress(currentCount, targetCount) {
                // Berechne den Prozentsatz
                const percentage = (currentCount / targetCount) * 100

                // Begrenze den Prozentsatz auf maximal 100
                return Math.min(100, percentage)
            },
            addFolderNotification() {
                this.notification = this.$helpers.createNotification("Project added to folder successfully!", "mdi-check-circle-outline", "primary")
                this.fetchProjects()
            },
            onRowClick(item) {
                this.$router.push("/briefing/" + item._id)
                // this.openEditor(item)
            },
            addProjectToFolder(item) {
                this.projectToFolderAdd = item
                this.addProjectToFolderVar++
            },
            deleteProjectFromFolder(item) {
                this.projectToFolderDelete = item
                this.deleteProjectToFolderVar++
            },
            folderSelect(folder) {
                console.log("Selected Folder: ", folder)
                this.loading = true
                setTimeout(() => {
                    if (folder) {
                        console.log(folder)
                        this.selectedFolder = folder
                        this.selectedFolderTitle = folder.name
                        if (folder.projects.length > 0) {
                            this.filteredProjects = this.projects.filter((p) => folder.projects.includes(p._id))
                        } else {
                            this.filteredProjects = []
                        }

                        console.log(this.filteredProjects)
                    } else {
                        this.selectedFolderTitle = null
                        this.selectedFolder = null
                        this.filteredProjects = JSON.parse(JSON.stringify(this.projects))
                        this.clearFolderSelection++
                    }
                    this.loading = false
                }, 400)
            },
            trackEvent(component, action, param1, param2) {
                try {
                    this.$matomo.trackEvent(component, action, param1, param2)
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
            },
            refetchCompetitors(project) {
                this.notification = this.$helpers.createNotification("Refetching competitor data. Please stand by!", "mdi-check-circle-outline", "primary")
                this.projects[this.projects.findIndex((x) => x._id == project._id)].competitors_status = "loading"
                const url = process.env.VUE_APP_APIURL + "/cs/add/lh-report"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: project,
                    socket_id: this.$socket.id
                }
                this.$helpers.axiosPost(url, post_data)
            },
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers)
                console.log(sortedItems)
                return sortedItems
            },
            async fetchProjects() {
                const url = process.env.VUE_APP_APIURL + "/cs/get"
                let post_data = {
                    company_id: localStorage.company_id,
                    filters: this.filters
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.projects = response_data.data.projects.filter((proj) => (proj.setup ? proj.setup.finished : false))
                let user_id = this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                console.log("right group", this.$store.state.content_suite_group.id == 2)
                if (this.$store.state.content_suite_group.id == 2)
                    this.projects = this.projects.filter((proj) => {
                        if (proj.authors) {
                            let userindex = proj.authors.findIndex((x) => x.id == user_id)
                            if (userindex > -1) {
                                return true
                            } else {
                                return false
                            }
                        } else {
                            return false
                        }
                    })
                console.log(this.projects)
                this.filteredProjects = JSON.parse(JSON.stringify(this.projects))
                // this.unfinishedProjects = response_data.data.projects.filter((proj) => (proj.setup ? !proj.setup.finished && proj.owner == localStorage.username : true))

                this.unfinishedProjects = response_data.data.projects.filter((proj) =>
                    proj.setup
                        ? !proj.setup.finished &&
                          proj.owner &&
                          this.CryptoJS.AES.decrypt(proj.owner, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) ==
                              this.CryptoJS.AES.decrypt(localStorage.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                        : true
                )
                if (this.projects.length == 0 && this.filters.length == 0) {
                    this.noProjects = true
                }
                if (this.$route.query.ow && this.$route.query.s) {
                    let projecToOpen = this.projects.find((project) => project._id == this.$route.query.ow)
                    console.log(projecToOpen)
                    if (projecToOpen) {
                        this.selectedWorkflowProject = projecToOpen
                        this.stageToShow = this.$route.query.s
                        this.workflowDialog = true
                    }
                }
                if (this.selectedFolderTitle) {
                    //filter projects
                    this.updateFolderSelection++
                }
                this.setFilterFields()
                console.log(response_data.data.projects)
            },
            async openEditor(project) {
                event.stopPropagation()
                console.log(project)
                /**
                 * fetches editor data of project
                 */
                const url = process.env.VUE_APP_APIURL + "/cs/get/editor/entrycheck"
                let post_data = {
                    company_id: localStorage.company_id,
                    user_id: localStorage.id,
                    project_id: project._id
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                // if (response_data.data.editorData && response_data.data.editorData._id) {
                console.log(response_data.data)
                var now = new Date()
                console.log(response_data.data.editorData.user_id)
                console.log(localStorage.id)

                if (response_data.data.editorData.user_id == localStorage.id) {
                    console.log(response_data.data.editorData)
                    this.$router.push("/content-editor/" + project._id)
                } else {
                    console.log("diff : ", Math.floor(now - new Date(response_data.data.editorData.last_updated)) / 1000)

                    if (Math.floor(now - new Date(response_data.data.editorData.last_updated)) / 1000 > 15) {
                        console.log(response_data.data.editorData)
                        this.$router.push("/content-editor/" + project._id)
                    } else {
                        this.$router.push("/briefing/" + project._id)
                        // this.notification = this.$helpers.createNotification(
                        //     "This Content Editor is opened by another user. The Content Editors can be used by one user at a time!",
                        //     "mdi-alert-circle-outline",
                        //     "#FCBA03"
                        // )
                    }
                }
                // } else {
                //     console.log("no editor data yet -> first opening")
                //     this.$router.push("/content-editor/" + project._id)
                // }
            },
            openBriefing(project) {
                event.stopPropagation()
                console.log(project)
                this.$router.push("/briefing/" + project._id)
            },
            openAnalysis(project) {
                event.stopPropagation()
                console.log(project)
                if (project.competitors_status == "done") {
                    this.$router.push("/content-project/competitors/" + project._id)
                } else if (project.competitors_status == "loading") {
                    this.notification = this.$helpers.createNotification("We are currently fetching page speed data of your competitors. Please stand by!", "mdi-alert-circle-outline", "#FCBA03")
                } else {
                    this.refetchCompetitors(project)
                }
            },
            openWDFAnalysis(project) {
                event.stopPropagation()
                console.log(project)
                this.$router.push("/content-project/wdf-idf/" + project._id)
            },
            scoreColor(item) {
                let emptyColorFill = {
                    radial: false,
                    colors: [
                        {
                            color: "#097e6e",
                            offset: "0",
                            opacity: "0.3"
                        },
                        {
                            color: "#019e86",
                            offset: "100",
                            opacity: "0.6"
                        }
                    ]
                }
                const ranges = [
                    { id: 1, min: 0, max: 1, color: "grey" },
                    { id: 1, min: 0, max: 30, color: "#B71C1C" },
                    { id: 2, min: 30, max: 50, color: "#D32F2F" },
                    { id: 3, min: 50, max: 60, color: "#F57C00" },
                    { id: 4, min: 60, max: 70, color: "#FFA000" },
                    { id: 5, min: 70, max: 80, color: "#FBC02D" },
                    { id: 6, min: 80, max: 90, color: "#689F38" },
                    { id: 7, min: 90, max: 101, color: "#43A047" }
                ]
                if (item.editor && item.editor.content_score) {
                    let score = item.editor.content_score

                    let range = ranges.find((entry) => score >= entry.min && score < entry.max)
                    emptyColorFill.colors[0].color = range.color
                    emptyColorFill.colors[1].color = range.color
                    let data = { linecolor: range.color, emptyColorFill: emptyColorFill }
                    return data
                } else {
                    let range = ranges[0]
                    emptyColorFill.colors[0].color = range.color
                    emptyColorFill.colors[1].color = range.color
                    let data = { linecolor: range.color, emptyColorFill: emptyColorFill }
                    return data
                }
            },
            async deleteProject(item) {
                const url = process.env.VUE_APP_APIURL + "/cs/delete"
                let post_data = {
                    company_id: localStorage.company_id,
                    project_id: item._id
                }
                this.deleteDialog.loading = true
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.deleteDialog.loading = false
                this.deleteDialog.show = false

                if (response_data.data.deleted) {
                    this.notification = this.$helpers.createNotification("Content Project successfully deleted!", "mdi-check-circle-outline", "primary")
                    this.deleteProjectFromFolder(item)
                    this.fetchProjects()
                    this.trackEvent("CS - Projects", "delete project", item._id, this.selectedDeleteItem.title)
                } else {
                    this.notification = this.$helpers.createNotification("Something went wrong! Please try again or contact support via chat or email!", "mdi-alert-circle-outline", "#ff6b00")
                }
            },
            getCreatedDate(id) {
                let local = "de-de"
                let date = new Date(parseInt(id.toString().slice(0, 8), 16) * 1000)
                const f = new Intl.DateTimeFormat(local, {
                    dateStyle: "long"
                })
                return f.format(date)
            },
            getDecryptedName(item) {
                if (item.owner && item.owner.length > 0) {
                    let name = this.CryptoJS.AES.decrypt(item.owner, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                    return { value: name }
                } else {
                    return { value: null }
                }
            },
            initials(name) {
                if (!name) return ""
                let splittedName = name.split(" ")
                let initials = ""
                splittedName.forEach((namepart) => {
                    let firstletter = namepart.substring(0, 1)
                    initials += firstletter
                })
                return initials
            },

            createNew() {
                if (!this.unfinishedProjects.length) {
                    this.trackEvent("CS - Projects", "new project", "", "")
                    this.$router.push(this.selectedFolder ? "/content-project/setup?folder=" + this.selectedFolder._id : "/content-project/setup")
                } else {
                    this.trackEvent("CS - Projects", "new project denied", "A project is still waiting for completion. Please delete the project or finish the setup!", "")
                    this.notification = this.$helpers.createNotification(
                        "A project is still waiting for completion. Please delete the project or finish the setup!",
                        "mdi-alert-circle-outline",
                        "#ff6b00"
                    )
                }
            },
            addedURLNotification() {
                this.addURLDialog.show = false

                this.notification = this.$helpers.createNotification("The URL has been added! All data will be fetched within the next seconds.", "mdi-check-circle-outline", "primary")
            },
            handleWindowResize() {
                const viewportWidth = window.innerWidth
                if (viewportWidth < 1200) {
                    this.showfolder = false
                } else {
                    this.showfolder = true
                }
                this.updateTableHeight()
            },
            updateTableHeight() {
                const viewportHeight = window.innerHeight
                const navHeight = 64
                const toolBarHeight = 64
                const topPadding = 30
                const tableHeader = 48
                const bufferHeight = viewportHeight > 1000 ? 40 : 25
                // Subtract a certain pixel value to adjust the height as needed
                const adjustedHeight = viewportHeight - navHeight - toolBarHeight - topPadding - tableHeader - bufferHeight // You can adjust the pixel value here as per your requirement

                // Return the calculated height
                this.tableHeight = `${adjustedHeight}px`
            }
        },
        mounted() {
            console.log(process.env.VUE_APP_URL)
            // this.fetchProjects()

            this.$socket.on("getCSNotification", (data) => {
                this.statusNotification = data
            })
            this.$socket.on("CsLightHouseFetchUpdated", (data) => {
                console.log(this.projects.findIndex((x) => x._id == data.project_id))
                console.log(data)
                console.log(this.projects)
                let project_index = this.projects.findIndex((x) => x._id == data.project_id)
                if (project_index > -1) {
                    this.projects[project_index].competitors_status = data.competitors_status
                    this.projects.push({})
                    this.projects.pop()
                }
            })
            this.$socket.on("csAddUrl", (data) => {
                console.log("ADD-")
                if (data.url_updated) {
                    this.fetchProjects()
                    this.url_loading = false
                } else {
                    this.notification = this.$helpers.createNotification("Something went wrong adding your URL! Please try again or contact support.", "mdi-alert", "orange darken-2")
                    this.url_loading = false
                }
            })
            console.log(this.user_settings.cs_projects_sort)
            this.table_sort_desc = this.user_settings.cs_projects_sort == undefined ? [true] : this.user_settings.cs_projects_sort.sort
            this.table_sort_by = this.user_settings.cs_projects_sort == undefined ? ["title"] : this.user_settings.cs_projects_sort.column
            this.table_items_per_page = this.user_settings.cs_projects_rowsperpage == undefined ? 10 : this.user_settings.cs_projects_rowsperpage
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.handleWindowResize()

                // Add event listener to window resize
                window.addEventListener("resize", vm.handleWindowResize)

                console.log("beforeenter")
                vm.fetchProjects()
            })
        },
        beforeDestroy() {
            // Remove event listener when the component is destroyed
            window.removeEventListener("resize", this.handleWindowResize)
        }
    }
</script>

<style scoped lang="scss">
    .circularprogress {
        margin-top: 20vh;
    }
    .tabletext {
        font-size: 16px;
        color: rgb(102, 102, 102);
    }
    /* .ep-legend--value {
        position: absolute !important;
    }
    .ep-legend--value__counter {
        position: absolute !important;
        top: -18px !important;
        left: -12px !important;
        display: flex;
    }
    .ep-legend--value__counter > span {
        margin-left: auto;
        margin-right: auto;
    }*/
    .ep-legend--container > span {
        position: absolute !important;
        bottom: -5px !important;
        left: -3px !important;
    }
    #cs_project_table th {
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 500;
        font-size: 14px !important;
    }
    #cs_project_table table > tbody > tr > td:last-child {
        background: var(--v-keywordcol-base) !important;
        border-left: 2px solid var(--v-primary-base);
        z-index: 9999;
        overflow: hidden;
    }
    #cs_project_table table > tbody > tr > td:last-child,
    #cs_project_table table > thead > tr > th:last-child {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
    }
    #cs_project_table table > thead {
        text-transform: uppercase !important;
    }
    #cs_project_table {
        border-radius: 10px !important;
    }
    #cs_project_table > div.v-data-table__wrapper {
        border-radius: 10px 10px 0 0 !important;
    }
    #cs_project_table table > thead {
        // background: var(--v-keywordcol-base) !important;
        border-radius: 10px !important;
    }
    .v-data-table--fixed-header > .v-data-table__wrapper {
        border-radius: 10px 10px 0 0 !important;
    }
    #cs_project_table > div.v-data-table__wrapper {
        border-radius: 10px !important;
    }
    .table_url {
        color: grey;
    }
    .up_status {
        margin-top: 2px;
        font-size: 12px;
        color: grey;
    }
    .urllink {
        text-decoration: none;
        &:hover {
            cursor: pointer !important;
            text-decoration: underline;
        }
    }
    // .theme--light#cs_project_table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(4),
    // .theme--light#cs_project_table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(5),
    // .theme--light#cs_project_table > .v-data-table__wrapper > table > tbody > tr > td:nth-child(6) {
    //     background-color: #f6f6f6 !important;
    // }
    .theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr:hover:not(.v-data-table__expanded__content):not(.v-data-table__empty-wrapper) {
        background: #f6f6f6 !important;
    }
    #cs_project_table > div.v-data-table__wrapper {
        border-radius: 10px 10px 0 0 !important;
    }
    .fixed-column {
        flex: 0 0 300px; /* Feste Größe von 300px, verändert sich nicht */
    }
    .flexible-column {
        flex: 1; /* Flexible Größe, nimmt den verfügbaren Platz ein */
    }
    #projects-tool-bar .v-toolbar__content {
        padding-left: 0px !important;
    }
    .theme--dark #cs_project_table span,
    .theme--dark #cs_project_table div {
        color: white;
    }
    .theme--dark #cs_project_table div > div {
        color: white !important;
    }
    .autoravat {
        border: 2px solid rgb(255, 255, 255);
    }
    .autoravat:hover {
        border: 1px solid white;
        z-index: 9999;
    }
</style>
