var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{attrs:{"flat":"","tile":""}},[_c('AddCategoryDialog',{attrs:{"show":_vm.showAddDialog,"newCategory":_vm.newCategory,"presetParentId":_vm.presetParentId},on:{"close":function($event){_vm.showAddDialog = false},"catCreated":_vm.catCreated}}),_c('DeleteCategoryDialog',{attrs:{"show":_vm.showDeleteDialog,"selectedDeleteItem":_vm.selectedDeleteItem},on:{"catDeleted":_vm.catDeleted,"close":function($event){_vm.showDeleteDialog = false}}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","z-index":"5","top":"-38px","right":"16px"}},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.test_set},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-btn',{attrs:{"elevation":"0","disabled":_vm.$store.state.monitoring_suite_group.id == 2 || _vm.test_set,"color":"primary","outlined":"","rounded":"","small":""},on:{"click":function($event){return _vm.setAddCategoryDialog()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus")]),_vm._v("category")],1)],1)]}}])},[_c('span',[_vm._v("Disabled on Example-Set")])])],1)]),_c('v-data-table',{staticClass:"row-pointer",attrs:{"id":"monitoringCatTable","headers":_vm.headers,"items":_vm.categories,"loading":_vm.loading,"items-per-page":_vm.itemsPerPage,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"custom-sort":_vm.customSort,"item-class":"row-pointer","fixed-header":"","height":"70vh"},on:{"click:row":_vm.switchCat,"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event}},scopedSlots:_vm._u([_vm._l((_vm.headers),function(header_entry,index){return {key:("header." + (header_entry.value)),fn:function(ref){
var header = ref.header;
return [_c('span',{key:index},[_c('span',{style:({ width: header.width })},[_c('v-tooltip',{attrs:{"max-width":"250","color":"","top":"","disabled":header.tooltip ? false : true},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({staticStyle:{"white-space":"nowrap","text-transform":"uppercase","font-weight":"500"}},on),[_vm._v(" "+_vm._s(header.text)+" "),(header.tooltip)?_c('v-icon',{staticClass:"questionIcon",attrs:{"x-small":""}},[_vm._v("mdi-help-circle-outline")]):_vm._e()],1)]}}],null,true)},[_c('span',[_c('v-row',{attrs:{"no-gutters":""}},[_c('div',{attrs:{"cols":"12"}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v("mdi-help-circle-outline")])],1)]),_vm._v(" "+_vm._s(header.tooltip)+" ")],1)])],1)])]}}}),{key:"item.category",fn:function(ref){
var item = ref.item;
return [_c('span',_vm._b({staticClass:"rtd my-5"},'span',(_vm.catName = _vm.getCatName(item.category_id)),false),[_vm._v(_vm._s(_vm.catName.value))])]}},{key:"item.keyword_count",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{staticClass:"qchip my-3",attrs:{"outlined":"","color":"primary"}},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(item.keyword_count))+" ")])]}},_vm._l((_vm.selectedCompetitors),function(competitor){return {key:("item.visibility_" + (competitor.domain) + ".value"),fn:function(ref){
var item = ref.item;
return [_c('div',_vm._b({key:competitor.domain},'div',(_vm.visibData = _vm.getCompVisib(item, competitor.domain)),false),[_c('v-row',{staticClass:"mx-5",staticStyle:{"flex-wrap":"nowrap !important"}},[_c('span',{staticClass:"ml-auto my-auto"},[_vm._v(" "+_vm._s(_vm.$helpers.formatNumber(_vm.visibData.value)))]),(_vm.visibData)?_c('v-chip',{staticClass:"ml-2 qchip",attrs:{"color":_vm.visibData.change == 0 ? '' : _vm.visibData.change < 0 ? 'red' : 'green',"outlined":""}},[(!_vm.visibData.status)?_c('span',[(_vm.visibData.change !== 0)?_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":_vm.visibData.change < 0 ? 'red' : 'green'}},[_vm._v(_vm._s(_vm.visibData.change < 0 ? "mdi-arrow-down" : "mdi-arrow-up"))]):_c('v-icon',{staticClass:"mr-1",attrs:{"small":"","color":"grey darken-2"}},[_vm._v("mdi-arrow-right")]),_c('span',{staticClass:"my-auto"},[_vm._v(_vm._s(_vm.visibData.change.toFixed(2))+"%")])],1):_c('span',[_vm._v(_vm._s(_vm.visibData.status))])]):_vm._e()],1)],1)]}}}),{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',[_c('v-menu',{attrs:{"offset-y":"","rounded":"lg","color":"primary","transition":"slide-y-transition","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-auto",attrs:{"disabled":_vm.$store.state.monitoring_suite_group.id == 2,"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',{attrs:{"rounded":"","dense":""}},[_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.test_set},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":_vm.test_set},on:{"click":function($event){return _vm.setAddCategoryDialog(item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-plus-circle")]),_c('v-list-item-title',[_vm._v("SUB-CATEGORY")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Disabled on Example-Set")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.test_set},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":_vm.test_set},on:{"click":function($event){return _vm.$emit('showAddKwDialog', item)}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-cloud-upload")]),_c('v-list-item-title',[_vm._v("KEYWORDS")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Disabled on Example-Set")])]),_c('v-tooltip',{attrs:{"top":"","disabled":!_vm.test_set},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_c('v-list-item',{attrs:{"disabled":_vm.test_set},on:{"click":function($event){_vm.selectedDeleteItem = item
                                            _vm.showDeleteDialog = true}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-delete")]),_c('v-list-item-title',[_vm._v("DELETE")])],1)],1)]}}],null,true)},[_c('span',[_vm._v("Disabled on Example-Set")])])],1)],1)],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"my-10"},[(_vm.category_id)?_c('span',[_c('p',[_vm._v("No child categories found...")]),_c('v-btn',{attrs:{"text":"","small":"","color":"primary"},on:{"click":function($event){return _vm.$emit('clearCategoryId')}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-close")]),_vm._v(" clear selection")],1)],1):_c('span',[_c('v-row',{staticClass:"mt-5"},[_c('v-col',[_c('v-card',{staticClass:"mx-auto rounded_card quaroshadow",attrs:{"color":"primary","max-width":"600px"}},[_c('v-card-text',[_c('v-row',[_c('v-col',{staticStyle:{"display":"flex"},attrs:{"md":"4","sm":"4"}},[_c('img',{staticClass:"mx-auto",attrs:{"width":"120","src":require("../../assets/undraw_folder.svg")}})]),_c('v-col',{staticClass:"white--text",staticStyle:{"text-align":"left"},attrs:{"md":"8","sm":"8"}},[_c('h2',{staticClass:"my-2"},[_vm._v(" No categories yet! ")]),_c('p',{staticClass:"mb-3"},[_vm._v("Create your first Category and assign Keywords to organize an analyze your Rankings, upcoming Trends and Competitors individually.")]),_c('v-btn',{staticClass:"pl-2",attrs:{"disabled":_vm.$store.state.monitoring_suite_group.id == 2,"outlined":"","color":"white","small":""},on:{"click":function($event){return _vm.setAddCategoryDialog()}}},[_c('v-icon',{staticClass:"mr-1",attrs:{"small":""}},[_vm._v("mdi-plus-circle-outline")]),_vm._v(" create first category")],1)],1)],1)],1)],1)],1)],1)],1)])]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }