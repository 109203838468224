<template>
    <div>
        <v-dialog v-model="show" max-width="1100px" @click:outside="$emit('close')">
            <v-card id="workflow">
                <v-card-title style="font-size: 18px" class="ml-1"
                    >Content Workflow
                    <v-btn class="qchip" @click="$emit('close')" style="background-color: rgb(246, 246, 246); top: 14px" x-small fab outlined color="primary" absolute right
                        ><v-icon>mdi-close</v-icon></v-btn
                    >
                </v-card-title>

                <v-card-subtitle
                    ><v-chip outlined color="primary" small class="mt-1 mb-2 qchip">{{ project ? project.title : "" }}</v-chip></v-card-subtitle
                >

                <v-stepper v-model="currentStep" style="box-shadow: none">
                    <v-divider style="border-color: rgb(237, 239, 241); border-width: 2px"></v-divider>
                    <!-- ---------------------------- -->
                    <!-- ------  Steps Header  ------ -->
                    <!-- ---------------------------- -->
                    <v-stepper-header class="workflowHeader">
                        <template v-for="(stage, index) in workflow.stages">
                            <v-divider :class="isStageComplete(stage) ? 'active mx-3' : 'inactive mx-3  '" style="border-color: rgb(237, 239, 241); border-width: 1px" v-if="index > 0"></v-divider>
                            <v-stepper-step color="#4caf50" editable edit-icon="mdi-check" :complete="isStageComplete(stage)" :step="index + 1" :key="index">
                                {{ stage.title }}
                            </v-stepper-step>
                        </template>
                    </v-stepper-header>

                    <v-divider style="border-color: rgb(237, 239, 241); border-width: 2px"></v-divider>

                    <!-- ---------------------------- -->
                    <!-- ------  Steps Content  ------ -->
                    <!-- ---------------------------- -->
                    <v-stepper-content class="px-0" v-for="(stage, index) in workflow.stages" :key="index" :step="index + 1">
                        <v-card class="mb-12" flat>
                            <!-- Button to add new tasks -->
                            <v-btn class="qchip" :disabled="$store.state.content_suite_group.id !== 1" right absolute rounded small outlined @click="openNewTaskDialog(index)" color="primary">
                                <v-icon small>mdi-plus</v-icon> Task
                            </v-btn>
                            <v-data-table id="workflowTaskTable" hide-default-footer :headers="taskHeaders" :items="stage.tasks" class="elevation-0">
                                <template v-slot:item="{ item, index }">
                                    <tr :class="getRowClass(item)">
                                        <!-- --------------------------- -->
                                        <!-- ------     Check     ------ -->
                                        <!-- --------------------------- -->
                                        <td style="border-left: 3px solid rgb(220, 220, 220)">
                                            <div class="d-flex">
                                                <v-checkbox
                                                    :disabled="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2"
                                                    class="py-auto"
                                                    on-icon="mdi-check-circle-outline"
                                                    off-icon="mdi-checkbox-blank-circle-outline"
                                                    v-model="item.completed"
                                                    color="success"
                                                    @click="checkTask(item)"></v-checkbox>
                                                <v-icon class="pb-1" v-if="item.dueDate && isOverdue(item.dueDate) && !item.completed" absolute right top color="warning"
                                                    >mdi-timer-alert-outline</v-icon
                                                >
                                            </div>
                                        </td>

                                        <!-- --------------------------- -->
                                        <!-- ------     Title     ------ -->
                                        <!-- --------------------------- -->

                                        <td class="py-3">
                                            {{ item.title }}
                                        </td>

                                        <!-- --------------------------- -->
                                        <!-- ------   Due Date    ------ -->
                                        <!-- --------------------------- -->
                                        <td>
                                            <v-menu offset-y>
                                                <template v-slot:activator="{ on }">
                                                    <v-chip
                                                        :disabled="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2"
                                                        @click.stop="openDueDateMenu(item)"
                                                        class="pl-1"
                                                        outlined
                                                        v-on="on">
                                                        <span v-if="item.dueDate">
                                                            <v-avatar color="primary" style="font-size: 10px" class="avat mr-2">
                                                                <v-icon color="white" size="16">mdi-calendar</v-icon>
                                                            </v-avatar>
                                                            {{ new Date(item.dueDate).toLocaleDateString() }}
                                                        </span>
                                                        <span v-else>
                                                            <v-avatar color="primary" style="font-size: 10px" class="avat mr-2 white--text">
                                                                <v-icon color="white" size="16" small>mdi-calendar</v-icon>
                                                            </v-avatar>
                                                            <v-icon small color="grey">mdi-plus</v-icon>
                                                        </span>
                                                    </v-chip>
                                                </template>
                                                <v-date-picker v-if="dueDateMenu" v-model="item.dueDate"></v-date-picker>
                                            </v-menu>
                                        </td>

                                        <!-- -------------------------- -->
                                        <!-- ------  Completion  ------ -->
                                        <!-- -------------------------- -->
                                        <td v-if="item.completionDate">{{ new Date(item.completionDate).toLocaleDateString() }}</td>
                                        <td v-else>-</td>
                                        <!-- --------------------------- -->
                                        <!-- ------  Responsible  ------ -->
                                        <!-- --------------------------- -->
                                        <td>
                                            <v-chip
                                                :disabled="$store.state.content_suite_group.id !== 1 && $store.state.content_suite_group.id !== 2"
                                                @click="openResponsibleDialog(item)"
                                                class="pl-1"
                                                outlined>
                                                <span v-if="item.responsible.groups.length > 0"> </span>
                                                <span v-else>
                                                    <span v-if="item.responsible.user[0]">
                                                        <v-avatar
                                                            :style="{ backgroundColor: $helpers.stringToBackgroundColor(item.responsible.user[0].username) }"
                                                            style="font-size: 10px"
                                                            class="mr-2 white--text"
                                                            >{{ $helpers.getInitials(item.responsible.user[0].username) }}</v-avatar
                                                        >
                                                        {{ item.responsible.user[0].username }}
                                                    </span>
                                                    <span v-else>
                                                        <v-avatar color="primary" style="font-size: 10px" class="avat mr-2 white--text"
                                                            ><v-icon size="16" small>mdi-account-multiple</v-icon>
                                                        </v-avatar>
                                                        <!-- <v-icon small>mdi-account-multiple-plus</v-icon> -->
                                                        <v-icon small color="grey">mdi-plus</v-icon>
                                                    </span>
                                                </span>
                                            </v-chip>
                                            <span style="font-size: 11px; color: rgb(88, 88, 88)" class="my-auto" v-if="item.responsible.user.length > 1">
                                                +{{ item.responsible.user.length - 1 }} more</span
                                            >
                                        </td>
                                        <td>
                                            <v-btn rounded color="primary" class="px-2" text @click="openCommentsSidebar(item)" small>
                                                {{ item.comments.length }} <v-icon small>mdi-comment-text-multiple-outline</v-icon></v-btn
                                            >
                                            <v-btn :disabled="$store.state.content_suite_group.id !== 1" @click="stage.tasks.splice(index, 1)" small rounded color="red darken-4" class="px-2" text>
                                                <v-icon small>mdi-delete</v-icon></v-btn
                                            >
                                        </td>
                                    </tr>
                                </template>
                            </v-data-table>
                        </v-card>
                    </v-stepper-content>
                </v-stepper>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn :disabled="!(currentStep > 1)" rounded @click="currentStep--" color="primary">Back</v-btn>
                    <v-btn :disabled="!(currentStep < this.workflow.stages.length)" rounded @click="currentStep++" color="primary">Next</v-btn>
                </v-card-actions>

                <!-- ------------------------------- -->
                <!-- ------  Comment Sidebar  ------ -->
                <!-- ------------------------------- -->
                <v-slide-x-transition>
                    <v-navigation-drawer width="350" v-if="commentsSidebar" v-model="commentsSidebar" right absolute temporary>
                        <div style="display: flex; flex-direction: column; height: 100%">
                            <v-card flat style="flex: 1; overflow-y: auto">
                                <v-card flat style="position: sticky; top: 0; z-index: 1">
                                    <v-card-title>
                                        Task Comments
                                        <v-btn @click="closeCommentsSidebar" style="background-color: rgb(246, 246, 246); top: 14px" x-small fab outlined color="primary" absolute right
                                            ><v-icon size="20">mdi-chevron-right</v-icon></v-btn
                                        >
                                    </v-card-title>
                                    <v-card-subtitle
                                        ><v-chip class="mb-2" small style="background-color: rgb(246, 246, 246) !important" outlined color="primary">{{ selectedTask.title }}</v-chip></v-card-subtitle
                                    >
                                    <v-divider style="border-color: rgb(237, 239, 241); border-width: 2px"></v-divider>
                                </v-card>
                                <div class="d-flex" v-if="selectedTask.comments.length == 0">
                                    <span class="mx-auto mt-5" style="color: grey; font-size: 14px">no comments yet...</span>
                                </div>
                                <!-- Chat-Bereich mit Scrollbar -->
                                <v-list ref="commentList" dense class="comment-list" style="overflow-y: auto; flex: 1">
                                    <v-list-item v-for="(comment, index) in selectedTask.comments" :key="index">
                                        <v-list-item-avatar>
                                            <v-avatar style="font-size: 15px" :style="{ backgroundColor: $helpers.stringToBackgroundColor(comment.user.username) }" class="pr-2 white--text">{{
                                                $helpers.getInitials(comment.user.username)
                                            }}</v-avatar>
                                        </v-list-item-avatar>
                                        <div class="comment-area">
                                            <v-list-item-title>
                                                <span class="comment-user">{{ comment.user.username }} </span>
                                                <span class="comment-date">{{ $helpers.formatRelativeDate(comment.date) }}</span>
                                            </v-list-item-title>
                                            <div class="comment-text">{{ comment.content }}</div>
                                        </div>
                                    </v-list-item>
                                </v-list>
                            </v-card>

                            <!-- Bereich für v-textarea und Button -->

                            <v-card v-if="$store.state.content_suite_group.id == 1 || $store.state.content_suite_group.id == 2" flat class="quaroshadow">
                                <v-divider style="border-color: rgb(237, 239, 241); border-width: 2px"></v-divider>

                                <v-card-text style="display: flex; align-items: center">
                                    <v-textarea rows="1" hide-details dense rounded filled v-model="newComment" label="Add a new comment..." style="flex: 1; margin-right: 8px"></v-textarea>
                                    <v-btn icon @click="addNewComment" rounded color="primary">
                                        <v-icon>mdi-send</v-icon>
                                    </v-btn>
                                </v-card-text>
                            </v-card>
                        </div>
                    </v-navigation-drawer>
                </v-slide-x-transition>
            </v-card>
        </v-dialog>

        <!-- ---------------------------- -->
        <!-- ------  ADD Task Dialog  ------ -->
        <!-- ---------------------------- -->
        <v-dialog v-model="newTaskDialog" max-width="400">
            <v-card>
                <v-card-title>
                    New Task
                    <v-btn right absolute icon @click="newTaskDialog = false" color="primary"><v-icon>mdi-close</v-icon></v-btn>
                </v-card-title>
                <v-card-subtitle v-if="selectedStageIndex != null">{{ workflow.stages[selectedStageIndex].title }}</v-card-subtitle>
                <v-card-text>
                    <v-text-field @keyup.enter="addNewTask" hide-details rounded filled v-model="newTaskTitle" label="Task Title"></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="mx-auto" @click="addNewTask" rounded color="primary">create</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <responsible-selection v-model="responsibleDialog" @assign="assignResponsible" :user="selectedTask ? selectedTask.responsible.user : []" />
    </div>
</template>

<script>
    import ResponsibleSelection from "./AddResponsible.vue" // Passe den Pfad entsprechend deiner Dateistruktur an

    export default {
        components: {
            ResponsibleSelection
        },
        filters: {
            formatDate(date) {
                return new Date(date).toLocaleString() // Hier kannst du das Datumsformat anpassen
            }
        },
        props: {
            project: Object,
            show: Boolean,
            stageToShow: Number,
            shared_company_id: String
        },
        data() {
            return {
                newComment: "",
                responsibleDialog: false,
                dueDateMenu: false,
                selectedTask: null,
                newTaskDialog: false,
                newTaskTitle: "",
                selectedStageIndex: null,
                commentsSidebar: false,
                selectedTaskComments: [],
                currentStep: 1,
                dialog: false,
                taskHeaders: [
                    { sortable: false, text: "", value: "check" },
                    { sortable: false, text: "Task", value: "title" },
                    { sortable: false, text: "Due Date", value: "dueDate" },
                    { sortable: false, text: "Completion Date", value: "completionDate" },
                    { sortable: false, text: "Responsible", value: "responsible" },
                    { sortable: false, text: "", value: "comments", width: "150px" }
                ],
                workflow: {
                    title: "Default Content Workflow",
                    stages: [
                        {
                            title: "Briefing",
                            tasks: []
                        },
                        {
                            title: "Production",
                            tasks: []
                        },
                        {
                            title: "Lectorate",
                            tasks: []
                        },
                        {
                            title: "Publishing",
                            tasks: []
                        }
                    ]
                },
                default_workflow: {
                    title: "Default Content Workflow",
                    stages: [
                        {
                            title: "Briefing",
                            tasks: [
                                {
                                    title: "Add Briefing Description",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                },
                                {
                                    title: "Review Briefing",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                }
                            ]
                        },
                        {
                            title: "Production",
                            tasks: [
                                {
                                    title: "Create Outline",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                },
                                {
                                    title: "Write Content",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                }
                            ]
                        },
                        {
                            title: "Lectorate",
                            tasks: [
                                {
                                    title: "Edit Content",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                },
                                {
                                    title: "Review Content",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                }
                            ]
                        },
                        {
                            title: "Publishing",
                            tasks: [
                                {
                                    title: "Prepare for Publishing",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                },
                                {
                                    title: "Publish Content",
                                    responsible: { user: [], groups: [] },
                                    dueDate: null,
                                    completionDate: null,
                                    completed: false,
                                    comments: []
                                }
                            ]
                        }
                    ]
                }
            }
        },
        computed: {
            userToNotify() {
                const users = []
                const groups = []
                const current_user = {
                    user: this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8),
                    username: this.CryptoJS.AES.decrypt(localStorage.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                }

                this.workflow.stages.forEach((stage) => {
                    stage.tasks.forEach((task) => {
                        task.responsible.user.forEach((user) => {
                            if (user.user !== current_user.user) {
                                const existingUser = users.find((existing) => existing.user === user.user)
                                if (!existingUser) {
                                    users.push({ user: user.user, username: user.username })
                                }
                            }
                        })

                        task.responsible.groups.forEach((group) => {
                            if (!groups.includes(group)) {
                                groups.push(group)
                            }
                        })

                        task.comments.forEach((comment) => {
                            if (comment.user.user !== current_user.user) {
                                const existingUser = users.find((existing) => existing.user === comment.user.user)
                                if (!existingUser) {
                                    users.push(comment.user)
                                }
                            }
                        })
                    })
                })

                // TODO: add creator of project
                console.log(localStorage.user)

                return {
                    user: users,
                    groups: groups
                }
            },
            obi() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) == 1547
            }
        },
        watch: {
            show(val) {
                if (!val) return
                this.fetchWorkflow()
            },
            workflow: {
                deep: true, // Hiermit überwachst du auch Änderungen in verschachtelten Objekten
                handler(newWorkflow, oldWorkflow) {
                    console.log("Workflow has changed:", newWorkflow)
                    this.updateCurrentStage()
                    this.saveWorkflow()
                    // Hier kannst du weitere Aktionen ausführen, wenn sich das Workflow-Objekt ändert
                }
            }
        },
        methods: {
            async createTaskMailNotificaton(taskdata, users, template, subject, additionalData = {}) {
                try {
                    console.log(this.project)

                    console.log(taskdata)
                    let mailData = []
                    if (users) {
                        let userMailData = users.map((userData) => {
                            let obj = { ...additionalData }
                            let name = userData.username.split(" ")[0] || userData.username
                            obj.address = userData.user
                            obj.firstname = name
                            obj.button = this.obi
                                ? "https://apollon.obi.com" + "/content-projects?ow=" + this.project._id + "&s=" + this.currentStep
                                : process.env.VUE_APP_URL + "/content-projects?ow=" + this.project._id + "&s=" + this.currentStep
                            obj.projectTitle = this.project.title
                            obj.taskTitle = taskdata.title
                            obj.template = template
                            obj.subject = subject
                            obj.dueDate = taskdata.dueDate ? taskdata.dueDate : "-"
                            return obj
                        })
                        mailData = [...mailData, ...userMailData]
                    }

                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.project._id,
                        mailData: mailData
                    }

                    const url = process.env.VUE_APP_APIURL + "/cs/notify-user"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                } catch (error) {
                    console.log(error)
                }
            },
            async updateCurrentStage() {
                try {
                    let active_stage = this.getCurrentStage()
                    if (this.project.workflow && this.project.workflow.active_stage._id == active_stage._id) return // nothing to update

                    const workflow_data = {
                        workflow_id: this.workflow._id,
                        active_stage: { _id: active_stage._id, title: active_stage.title }
                    }
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.project._id,
                        workflow_data: workflow_data
                    }
                    this.project.workflow = workflow_data
                    console.log(workflow_data.active_stage.title)
                    // console.log(post_data)
                    const url = process.env.VUE_APP_APIURL + "/cs/save/workflow-stage"

                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    console.log(response_data.data)
                } catch (error) {
                    console.log(error)
                }
            },
            async saveWorkflow() {
                const url = process.env.VUE_APP_APIURL + "/cs/save/workflow"
                let post_data = {
                    company_id: localStorage.company_id,
                    workflow: this.workflow
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
            },
            async fetchWorkflow() {
                try {
                    console.log(this.project)
                    const post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.project._id
                    }
                    const url = process.env.VUE_APP_APIURL + "/cs/get/workflow"
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    if (response_data.data.workflow) {
                        this.workflow = response_data.data.workflow
                        if (!this.stageToShow) {
                            let stageTitle = this.getCurrentStage().title
                            if (stageTitle == "Finished") {
                                this.currentStep = this.workflow.stages.length
                            } else {
                                let currentStep = this.workflow.stages.findIndex((s) => s.title == this.getCurrentStage().title)
                                currentStep++
                                this.currentStep = currentStep ? currentStep : 1
                            }
                        } else {
                            this.currentStep = this.stageToShow
                        }
                    } else {
                        console.log("workflow does not exists")
                        this.setDefaultWorkflow()
                    }
                } catch (error) {
                    console.log(error)
                }
            },
            async setDefaultWorkflow() {
                try {
                    let workflow = JSON.parse(JSON.stringify(this.default_workflow))
                    workflow.project_id = this.project._id

                    const post_data = {
                        company_id: localStorage.company_id,
                        workflow: workflow
                    }
                    const url = process.env.VUE_APP_APIURL + "/cs/set/workflow"
                    let response_data = await this.$helpers.axiosPost(url, post_data)

                    console.log(response_data.data)
                    this.workflow = response_data.data.workflow
                } catch (error) {
                    console.log(error)
                }
            },
            getCurrentStage() {
                // Iteriere durch die Stufen (stages) und finde die erste, in der nicht alle Aufgaben abgeschlossen sind
                for (let i = 0; i < this.workflow.stages.length; i++) {
                    const stage = this.workflow.stages[i]
                    if (!this.isStageComplete(stage)) {
                        return stage
                    }
                }
                // Falls alle Stufen abgeschlossen sind, gib letzte zurück
                return { title: "Finished" }
            },
            assignResponsible(respData) {
                console.log("RESPONSIBLE")
                console.log(respData)
                if (respData.user) {
                    this.selectedTask.responsible.user = respData.user
                }
                if (respData.groups) {
                    this.selectedTask.responsible.groups = respData.groups
                }
                // if (respData.content_suite_group_id == 2)
                this.$emit("addAuthors", this.selectedTask.responsible)

                this.createTaskMailNotificaton(this.selectedTask, this.selectedTask.responsible.user, "notifiy_new_taks_mail_en.html", "A task has been assigned to you!")
                console.log(this.workflow)
            },

            scrollToBottom() {
                console.log("scrollToBottom")
                const commentList = this.$refs.commentList.$el
                console.log(commentList)
                // Scrolle nach unten, um den neuen Kommentar anzuzeigen
                commentList.scrollTop = commentList.scrollHeight
            },
            addNewComment() {
                if (this.newComment.length == 0) return
                const newC = {
                    user: {
                        user: this.CryptoJS.AES.decrypt(localStorage.user, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8), // Beispiel-Benutzer-ID
                        username: this.CryptoJS.AES.decrypt(localStorage.username, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) // Beispiel-Benutzername
                    },
                    content: this.newComment,
                    date: new Date()
                }
                this.selectedTask.comments.push(newC)

                this.$nextTick(() => {
                    this.scrollToBottom()
                })

                let additionalData = {
                    comment_text: newC.content,
                    comment_time: this.$helpers.formatRelativeDate(newC.date),
                    comment_name: newC.user.username,
                    comment_initials: this.$helpers.getInitials(newC.user.username)
                }
                console.log(this.userToNotify.user)
                if (this.userToNotify.user.length) {
                    this.createTaskMailNotificaton(this.selectedTask, this.userToNotify.user, "notifiy_new_comment_mail_en.html", "New Comment in Content Project!", additionalData)
                }
                this.newComment = ""
            },
            openDueDateMenu(item) {
                this.selectedTask = item
                this.dueDateMenu = true
            },
            closeDueDateMenu() {
                this.selectedTask = null
                this.dueDateMenu = false
            },
            openNewTaskDialog(stageIndex) {
                this.selectedStageIndex = stageIndex
                this.newTaskDialog = true
            },
            addNewTask() {
                if (this.newTaskTitle.trim() !== "") {
                    const newTask = {
                        title: this.newTaskTitle.trim(),
                        responsible: { user: [], groups: [] },
                        dueDate: null,
                        completionDate: null,
                        completed: false,
                        comments: []
                    }

                    this.workflow.stages[this.selectedStageIndex].tasks.push(newTask)

                    this.closeNewTaskDialog()
                }
            },

            closeNewTaskDialog() {
                this.newTaskDialog = false
                this.newTaskTitle = ""
                this.selectedStageIndex = null
            },
            checkTask(row) {
                if (!row.completionDate) {
                    row.completionDate = new Date()
                    console.log(this.userToNotify)
                    this.createTaskMailNotificaton(row, this.userToNotify.user, "notifiy_task_completed_mail_en.html", "A task has been completed!")
                } else {
                    row.completed == false
                    row.completionDate = null
                }
                if (this.isStageComplete(this.workflow.stages[this.currentStep - 1])) {
                    setTimeout(() => {
                        if (this.currentStep < this.workflow.stages.length) {
                            this.currentStep++
                        }
                    }, 350)
                }
            },
            isOverdue(date) {
                const currentDate = new Date()
                const dueDate = new Date(date)
                // Vergleiche die aktuellen Zeitstempel mit dem Fälligkeitsdatum
                if (dueDate < currentDate) {
                    return true
                }
                return false
            },
            getRowClass(row) {
                if (row.completed) return "finished"
                if (row.dueDate) {
                    const currentDate = new Date()
                    const dueDate = new Date(row.dueDate)

                    // Vergleiche die aktuellen Zeitstempel mit dem Fälligkeitsdatum
                    if (dueDate < currentDate) {
                        return "overdue"
                    }
                }
            },
            openResponsibleDialog(item) {
                this.selectedTask = item
                this.responsibleDialog = true
            },
            closeResponsibleDialog(item) {
                this.selectedTask = null
                this.responsibleDialog = false
            },
            openCommentsSidebar(item) {
                this.selectedTask = item
                this.commentsSidebar = true
                this.$nextTick(() => {
                    this.scrollToBottom()
                })
            },
            closeCommentsSidebar() {
                this.commentsSidebar = false
                this.selectedTask = null
            },
            openDialog() {
                this.dialog = true
            },
            closeDialog() {
                this.dialog = false
                this.currentStep = 1
            },
            isStageComplete(stage) {
                // Hier kannst du Logik hinzufügen, um zu überprüfen, ob alle Aufgaben in einer Stufe abgeschlossen sind
                // Rückgabe sollte ein Boolean sein (true, wenn abgeschlossen, false, wenn nicht abgeschlossen)
                return stage.tasks.every((task) => task.completionDate !== null)
            }
        }
    }
</script>

<style scoped>
    .inactive {
        border-color: rgb(237, 239, 241);
    }
    .active {
        border-color: var(--v-success-base) !important;
    }
    .comment-list {
        height: calc(100% - 120px); /* Stelle sicher, dass die Liste die volle Höhe der Sidebar einnimmt */
        overflow-y: auto; /* Füge eine Scroll-Leiste hinzu, wenn der Chat-Inhalt die Höhe überschreitet */
    }

    /* Stil für Chat-Nachrichten */
    .v-list-item {
        margin-bottom: 8px;
    }
    .comment-area {
        margin-bottom: 10px;
        margin-top: 10px;
    }
    .comment-date {
        margin-left: 5px;
        font-style: italic;
        font-size: 12px;
    }
    .comment-user {
        margin-left: 5px;
        font-weight: bold;
        font-size: 12px;
        padding-bottom: 6px;
    }
    .comment-text {
        margin-top: 5px;
        background-color: #ecfef9;
        border-radius: 20px;
        color: #1f1f1f;
        display: inline-block;
        max-width: 100%;
        padding: 10px 12px;
        box-sizing: border-box;
        white-space: pre-wrap;
        position: relative;
        width: fit-content;
        font-size: 0.875rem;
        line-height: 1.25rem;
        word-break: break-word;
        word-wrap: break-word;
    }
    /* Hier könntest du Stilregeln für deine Komponente hinzufügen */
</style>
<style>
    #workflowTaskTable > div > table > thead > tr > th {
        text-transform: uppercase;
        white-space: nowrap;
        font-weight: 100;
    }
    #workflowTaskTable .finished {
        background-color: #4daf502b !important;
        transition: background-color 0.5s ease-in-out;
    }
    #workflowTaskTable .overdue {
        background-color: #af4d4d2b !important;
    }
    #workflow .qchip {
        background-color: rgb(245, 245, 245) !important;
    }
    .theme--dark #workflow .qchip {
        background-color: black !important;
    }
    .workflowHeader {
        box-shadow: none;
        background-color: #f6f6f6;
    }
    .theme--dark .workflowHeader {
        box-shadow: none;
        background-color: #000000;
    }
</style>
