<template>
    <v-card flat tile>
        <AddCategoryDialog :show="showAddDialog" @close="showAddDialog = false" @catCreated="catCreated" :newCategory="newCategory" :presetParentId="presetParentId"></AddCategoryDialog>
        <DeleteCategoryDialog :show="showDeleteDialog" :selectedDeleteItem="selectedDeleteItem" @catDeleted="catDeleted" @close="showDeleteDialog = false"></DeleteCategoryDialog>

        <div style="position: relative">
            <div style="position: absolute; z-index: 5; top: -38px; right: 16px">
                <!-- <v-chip outlined small class="mr-2 pl-4 pr-1">
                        {{ selectedRows.length }} SELECTED
                        <v-btn class="mx-1" x-small icon @click="selectedRows = []"><v-icon x-small>mdi-close</v-icon></v-btn>
                    </v-chip> -->
                <v-tooltip top :disabled="!test_set">
                    <template v-slot:activator="{ on, attrs }">
                        <span v-bind="attrs" v-on="on">
                            <v-btn elevation="0" :disabled="$store.state.monitoring_suite_group.id == 2 || test_set" color="primary" outlined rounded small @click="setAddCategoryDialog()">
                                <v-icon class="mr-1" small>mdi-plus</v-icon>category</v-btn
                            >
                        </span>
                    </template>
                    <span>Disabled on Example-Set</span>
                </v-tooltip>
                <!-- <v-btn elevation="0" outlined rounded color="error" small @click="deleteDialog = true"> <v-icon class="mr-1" small>mdi-delete</v-icon> delete</v-btn> -->
            </div>
        </div>
        <v-data-table
            id="monitoringCatTable"
            @click:row="switchCat"
            :headers="headers"
            :items="categories"
            :loading="loading"
            :items-per-page.sync="itemsPerPage"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :custom-sort="customSort"
            item-class="row-pointer"
            fixed-header
            height="70vh"
            class="row-pointer">
            <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                <span :key="index">
                    <span v-bind:style="{ width: header.width }">
                        <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                            <template v-slot:activator="{ on }">
                                <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                    {{ header.text }}
                                    <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                </span>
                            </template>
                            <span>
                                <v-row no-gutters>
                                    <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                </v-row>
                                {{ header.tooltip }}
                            </span>
                        </v-tooltip>
                    </span>
                </span>
            </template>
            <template v-slot:item.category="{ item }">
                <span class="rtd my-5" v-bind="(catName = getCatName(item.category_id))">{{ catName.value }}</span>
            </template>
            <template v-slot:item.keyword_count="{ item }">
                <v-chip outlined class="qchip my-3" color="primary" style="">
                    {{ $helpers.formatNumber(item.keyword_count) }}
                </v-chip>
            </template>
            <!-- Visibility for each Competitor by Category -->
            <template v-for="competitor in selectedCompetitors" v-slot:[`item.visibility_${competitor.domain}.value`]="{ item }">
                <div v-bind="(visibData = getCompVisib(item, competitor.domain))" v-bind:key="competitor.domain">
                    <v-row style="flex-wrap: nowrap !important" class="mx-5">
                        <!-- <div class="d-flex ml-auto my-auto mr-1" v-if="visibData.change != null">
                            <v-icon small class="my-auto">{{ visibData.change ? (visibData.change < 0 ? "mdi-arrow-down" : "mdi-arrow-up") : "mdi-arrow-right" }}</v-icon>
                            <span class="rankingchange my-auto">{{ visibData.change }}</span>
                        </div>
                        <v-chip outlined :class="visibData.change == null ? 'qchip ml-auto' : 'qchip'">
                            {{ visibData.value }}
                        </v-chip> -->
                        <span class="ml-auto my-auto"> {{ $helpers.formatNumber(visibData.value) }}</span>
                        <v-chip class="ml-2 qchip" :color="visibData.change == 0 ? '' : visibData.change < 0 ? 'red' : 'green'" outlined v-if="visibData">
                            <span v-if="!visibData.status">
                                <v-icon v-if="visibData.change !== 0" class="mr-1" small :color="visibData.change < 0 ? 'red' : 'green'">{{
                                    visibData.change < 0 ? "mdi-arrow-down" : "mdi-arrow-up"
                                }}</v-icon>
                                <v-icon v-else class="mr-1" small color="grey darken-2">mdi-arrow-right</v-icon>
                                <span class="my-auto">{{ visibData.change.toFixed(2) }}%</span>
                            </span>

                            <span v-else>{{ visibData.status }}</span>
                        </v-chip>
                    </v-row>
                </div>
            </template>
            <template v-slot:item.actions="{ item }">
                <div>
                    <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                        <template v-slot:activator="{ on }">
                            <v-btn :disabled="$store.state.monitoring_suite_group.id == 2" v-on="on" icon class="ml-auto">
                                <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                        </template>
                        <v-list rounded dense>
                            <v-tooltip top :disabled="!test_set">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-list-item :disabled="test_set" @click="setAddCategoryDialog(item)">
                                            <v-icon small class="mr-2">mdi-plus-circle</v-icon><v-list-item-title>SUB-CATEGORY</v-list-item-title>
                                        </v-list-item>
                                    </span>
                                </template>
                                <span>Disabled on Example-Set</span>
                            </v-tooltip>
                            <v-tooltip top :disabled="!test_set">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-list-item :disabled="test_set" @click="$emit('showAddKwDialog', item)">
                                            <v-icon small class="mr-2">mdi-cloud-upload</v-icon><v-list-item-title>KEYWORDS</v-list-item-title>
                                        </v-list-item>
                                    </span>
                                </template>
                                <span>Disabled on Example-Set</span>
                            </v-tooltip>
                            <v-tooltip top :disabled="!test_set">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-list-item
                                            :disabled="test_set"
                                            @click="
                                                selectedDeleteItem = item
                                                showDeleteDialog = true
                                            ">
                                            <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>DELETE</v-list-item-title>
                                        </v-list-item>
                                    </span>
                                </template>
                                <span>Disabled on Example-Set</span>
                            </v-tooltip>
                        </v-list>
                    </v-menu>
                </div>
            </template>
            <template v-slot:no-data>
                <div class="my-10">
                    <span v-if="category_id">
                        <p>No child categories found...</p>
                        <v-btn @click="$emit('clearCategoryId')" text small color="primary"> <v-icon small class="mr-1">mdi-close</v-icon> clear selection</v-btn>
                    </span>
                    <span v-else>
                        <v-row class="mt-5">
                            <v-col>
                                <v-card color="primary" max-width="600px" class="mx-auto rounded_card quaroshadow">
                                    <v-card-text>
                                        <v-row>
                                            <v-col md="4" sm="4" style="display: flex">
                                                <img width="120" class="mx-auto" src="../../assets/undraw_folder.svg" />
                                            </v-col>
                                            <v-col md="8" sm="8" class="white--text" style="text-align: left">
                                                <h2 class="my-2">
                                                    No categories yet!
                                                    <!-- <v-chip x-small color="red" class="white--text ml-1 px-2"> new feature</v-chip> -->
                                                </h2>

                                                <p class="mb-3">Create your first Category and assign Keywords to organize an analyze your Rankings, upcoming Trends and Competitors individually.</p>

                                                <v-btn :disabled="$store.state.monitoring_suite_group.id == 2" outlined color="white" class="pl-2" small @click="setAddCategoryDialog()">
                                                    <v-icon small class="mr-1">mdi-plus-circle-outline</v-icon>
                                                    create first category</v-btn
                                                >
                                            </v-col>
                                        </v-row>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                    </span>
                </div>
            </template>
        </v-data-table>
    </v-card>
</template>

<script>
    import AddCategoryDialog from "./AddCategoryDialog"
    import DeleteCategoryDialog from "./DeleteCategoryDialog"

    export default {
        components: {
            AddCategoryDialog,
            DeleteCategoryDialog
        },
        props: {
            category_id: String,
            selectedDates: Array,
            selectedCompetitors: Array,
            search: String,
            exportData: Number,
            project_domain: String
        },
        data() {
            return {
                count: 0,
                loading: false,
                sortDesc: true,
                sortBy: "keyword_count",
                page: 1,
                itemsPerPage: 25,
                pagination: {},
                categories: [],
                showDeleteDialog: false,
                showAddDialog: false,
                newCategory: {
                    parent: null,
                    name: ""
                },
                selectedDeleteItem: null,
                presetParentId: ""
            }
        },
        computed: {
            test_set() {
                return (
                    process.env.VUE_APP_MONITORING_TEST_SET_COMPANY_ID !== this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8) &&
                    process.env.VUE_APP_MONITORING_TEST_SET_ID == this.$route.params.id
                )
            },
            footerProps() {
                return {
                    itemsPerPageOptions: [10, 25, 50, 100],
                    showFirstLastPage: true
                }
            },
            headers() {
                const headerColumns = [
                    { text: "Category", value: "category", tooltip: "", align: "start", width: "250px" },
                    { text: "Keyword Count", value: "keyword_count", tooltip: "The amount of keywords which are assigned to a category", align: "end", width: "170px" }
                ]

                // Loop through selected competitors and add their headers
                this.selectedCompetitors.forEach((competitor) => {
                    console.log(competitor.domain)
                    headerColumns.push({
                        text: competitor.domain,
                        value: `visibility_${competitor.domain}.value`,
                        align: "end",
                        tooltip: "Visibility for " + competitor.domain + ". The visibility value is based on the rankings the competitor got in the selected timeperiod and category"
                    })
                })
                console.log(this.project_domain)
                let indexOfDomain = headerColumns.findIndex((e) => e.text == this.project_domain)
                console.log("indexOfDomain", indexOfDomain)
                if (indexOfDomain > -1) {
                    let targetIndex = 2
                    let removedItem = headerColumns.splice(indexOfDomain, 1)[0]
                    headerColumns.splice(targetIndex, 0, removedItem)
                }
                headerColumns.push({
                    text: "",
                    value: "actions",
                    sortable: false,
                    align: "end",
                    width: 50
                })
                return headerColumns
            }
            // return [
            //     { text: "Category", value: "category", tooltip: "", align: "start", width: "250px" },
            //     { text: "Keyword Count", value: "keyword_count", tooltip: "", align: "end" },
            //     ...this.selectedCompetitors.map((domain) => ({ text: domain.domain, value: `ranking_${domain.domain}`, align: "end" }))
            // ]
        },
        methods: {
            customSort(items, index, isDesc) {
                console.log(items[0])
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers, { date: this.selectedDates[1], date_old: this.selectedDates[0] }, "monitoring_categories")
                console.log(sortedItems)
                return sortedItems
            },
            async fetchCategories(init = false) {
                try {
                    this.loading = true
                    const domainArray = this.selectedCompetitors.map((c) => c.domain)
                    const requestData = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        //  "mother_cat": "Baby",
                        // cat_level: "1",
                        cat_level: "0",
                        domains: domainArray,
                        selectedDates: this.selectedDates
                    }
                    if (this.category_id) {
                        let catParts = this.category_id.split(";")
                        console.log(catParts)
                        requestData.cat_level = catParts.length.toString()
                        requestData.mother_cat = catParts[catParts.length - 1]
                    }
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/categories/frontend"
                    const response = await this.$helpers.axiosPost(url, requestData)
                    this.categories = response.data.categories.map((c) => {
                        let obj = { ...c.category }
                        obj.competitorData = c.competitorData
                        return obj
                    })

                    console.log(this.categories)
                    this.count = response.data.count
                    if (this.count === 0 && !init) {
                        this.$emit("bottomReached")
                    }
                    this.loading = false
                } catch (error) {
                    console.error(error)
                    // Handle error here
                    this.loading = false
                }
            },
            getCompVisib(item, domain) {
                // console.log(domain)
                try {
                    let compData = item.competitorData.find((entry) => entry.domain == domain)
                    // check if no comp entry or only one date
                    if (!compData) return { value: 0, change: 0, status: null }
                    if (!this.selectedDates[0] || !this.selectedDates[1]) return { value: compData.visibility[0] ? compData.visibility[0].value : 0, change: 0, status: null }

                    let visibData = compData.visibility.find((entry) => entry.date == this.selectedDates[1])
                    let visibDataOld = compData.visibility.find((entry) => entry.date == this.selectedDates[0])

                    if (visibDataOld !== undefined && visibData !== undefined) {
                        if (visibDataOld.value == 0 && visibData.value == 0) {
                            return { value: 0, change: 0, status: null }
                        } else if (visibDataOld.value == 0) {
                            return { value: visibData.value, change: 100, status: "new" }
                        } else if (visibData.value == 0) {
                            return { value: visibData.value, change: -100, status: "lost" }
                        } else {
                            let change = ((visibData.value - visibDataOld.value) / visibDataOld.value) * 100
                            return { value: visibData.value, change: change, status: null }
                        }
                    } else if (visibDataOld !== undefined) {
                        let change = -100 // Consider this a large negative change for "lost" status
                        return { value: 0, change: change, status: "lost" }
                    } else if (visibData !== undefined) {
                        let change = 100 // Consider this a large positive change for "new" status
                        return { value: visibData.value, change: change, status: "new" }
                    } else {
                        return { value: 0, change: 0, status: null }
                    }
                } catch (error) {
                    console.log(error)
                    console.log(item)
                }
            },
            getCatName(category_id) {
                let catParts = category_id.split(";")
                return { value: catParts[catParts.length - 1] }
            },
            switchCat(item) {
                if (item.keyword_count == 0) {
                    // message: `<b>There are no keywords assigned to this category!</b></br>Please assign or upload keywords first! `,
                    let notifidata = {
                        label: `<h3>There are no keywords assigned to this category!</h3><p>Please assign or upload keywords first!</p> `,
                        icon: "mdi-alert",
                        color: "#e5a049",
                        type: "empty-cat",
                        id: item.category_id
                    }
                    this.$emit("notify", notifidata)
                    return
                }

                this.$emit("switchCat", item.category_id, item._id)
            },
            catCreated(newCat) {
                console.log("category created")
                this.showAddDialog = false
                let notifidata = {
                    label: "<h3>A new but empty category has been created!</h3><p>Now you can upload new or assign existing keywords from your Project</p> ",
                    icon: "mdi-check-circle-outline",
                    color: "primary",
                    type: "created-cat",
                    id: newCat.category_id
                }
                this.$emit("notify", notifidata)
                this.fetchCategories()
            },
            catDeleted() {
                console.log("category deleted")
                this.showDeleteDialog = false
                let notifidata = { label: "Category successfully deleted!", icon: "mdi-check-circle-outline", color: "primary" }
                this.$emit("notify", notifidata)
                this.fetchCategories()
            },
            setAddCategoryDialog(item) {
                console.log(this.category_id)
                if (item) {
                    this.presetParentId = ""
                    this.newCategory.parent = item
                    this.newCategory.name = ""
                } else {
                    if (this.category_id) {
                        this.presetParentId = this.category_id + ""
                    }
                    this.newCategory.parent = null
                    this.newCategory.name = ""
                }
                this.showAddDialog = true
            }
        },
        watch: {
            selectedCompetitors() {
                this.fetchCategories()
            },
            selectedDates() {
                this.fetchCategories()
            },
            category_id() {
                this.fetchCategories()
            },
            async exportData() {
                console.log("download triggered")
                console.log(this.categories)

                let fields = this.headers.map((header) => header.text)
                let rows = this.categories.map((category) => {
                    let row = {}
                    this.headers.map((header) => {
                        if (header.value.substring(0, 11) == "visibility_") {
                            let compEntry = category.competitorData.find((r) => r.domain == header.text)
                            let compVisib = compEntry ? compEntry.visibility.find((e) => e.date == this.selectedDates[1]) : null
                            row[header.text] = compVisib ? compVisib.value : 0
                            // row[header.text + " - " + this.selectedDates[0]] = ranking.rankingsOld.find((r) => r.domain == header.text)
                        } else if (header.value == "category") {
                            row[header.text] = this.getCatName(category.category_id).value
                        } else {
                            row[header.text] = category[header.value]
                        }
                    })
                    return row
                })
                console.log(rows, fields)

                let post_data = {
                    rows: rows,
                    fields: fields
                }
                if (this.selectedDates[1]) {
                    post_data.fileTitle = "Categories_" + this.selectedDates[1].substring(0, 10)
                } else {
                    post_data.fileTitle = "Categories_" + this.selectedDates[0].substring(0, 10)
                }
                const url = process.env.VUE_APP_MONITORING_API_URL + "/project/export/csv"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                window.location = process.env.VUE_APP_MONITORING_API_URL + "/" + response_data.data
            }
        },
        mounted() {
            this.fetchCategories(true)
        }
    }
</script>

<style scoped>
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }
    .row-pointer table > thead > tr > th {
        white-space: nowrap !important;
    }
</style>
<style>
    #monitoringCatTable th {
        white-space: nowrap !important;
    }
    #monitoringCatTable table > tbody > tr > td:last-child {
        background: var(--v-keywordcol-base) !important;
        border-left: 2px solid var(--v-primary-base);
    }
    /* #monitoringCatTable table > thead > tr > th:last-child  */
    #monitoringCatTable table > tbody > tr > td:last-child {
        position: sticky !important;
        position: -webkit-sticky !important;
        right: 0 !important;
    }
    #monitoringCatTable .qchip {
        background-color: rgb(245, 245, 245) !important;
        border-width: 1px;
    }
    #monitoringCatTable table > tbody > tr > td:nth-child(3),
    #monitoringCatTable table > thead > tr > th:nth-child(3) {
        /* background: #edeff1 !important; */
        border-left: 2px solid #edeff1;
        border-right: 2px solid #edeff1;
    }

    .theme--dark #monitoringCatTable .qchip {
        background-color: black !important;
        border-width: 1px;
    }
</style>
