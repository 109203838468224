<template>
    <!-- <div class="backsheet"></div>

        <v-card-title class="sheet-card-title">
            <v-icon class="mr-2">mdi-chart-timeline-variant</v-icon>
            <span style="color: rgb(86, 86, 86)"> {{ title }} </span></v-card-title
        > -->
    <!-- <v-card-subtitle class="sheet-card-subtitle">{{ subtitle }}</v-card-subtitle> -->

    <!-- <div data-v-02953856="" class="selectionCol mr-2"> -->
    <line-chart :dataChart="chartData" :countGraph2="renderChart" :options="graphOptions"></line-chart>
    <!-- </div> -->
</template>

<script>
    import LineChart from "../charts/LineChart"
    export default {
        components: {
            LineChart
        },
        props: {
            selectedCompetitors: Array,
            category_id: String
        },
        data() {
            return {
                title: "Visibility",
                subtitle: "test",
                renderChart: 0,
                chartData: {
                    labels: [
                        // "January", "February", "March", "April", "May", "June", "July", "August"
                    ],
                    datasets: [
                        // {
                        //     label: "telekom.de",
                        //     data: [26766422, 27766422, 28766422, 28566422, 28466422, 25822933, 29889069, 28766422],
                        //     backgroundColor: "transparent",
                        //     borderColor: "rgb(255, 20, 255)",
                        //     pointBackgroundColor: "rgba(255, 255, 255, 1)"
                        //     // gradient: true
                        // },
                        // {
                        //     label: "vodafone.de",
                        //     data: [27126835, 26126835, 25126835, 26426835, 26826835, 24578652, 25751666, 26126835],
                        //     backgroundColor: "transparent",
                        //     borderColor: "rgb(255, 55, 50)",
                        //     pointBackgroundColor: "rgba(255, 255, 255, 1)"
                        //     // gradient: true
                        // },
                        // {
                        //     label: "1und1.de",
                        //     data: [12592264, 11562986, 13523450, 12592264, 13520010, 12592264, 11958610, 10375036],
                        //     backgroundColor: "transparent",
                        //     borderColor: "rgb(155, 156, 255)",
                        //     pointBackgroundColor: "rgba(255, 255, 255, 1)"
                        //     // gradient: true
                        // },
                        // {
                        //     label: "o2online.de",
                        //     data: [9860303, 9860303, 9860303, 9860303, 9860303, 9860303, 10609766, 11300249],
                        //     backgroundColor: "transparent",
                        //     borderColor: "rgb(5, 50, 255)",
                        //     pointBackgroundColor: "rgba(255, 255, 255, 1)"
                        //     // gradient: true
                        // },
                        // {
                        //     label: "congstar.de",
                        //     data: [2656464, 2656464, 2656464, 2656464, 2656464, 2656464, 3513922, 3754336],
                        //     backgroundColor: "transparent",
                        //     borderColor: "rgb(50, 50, 55)",
                        //     pointBackgroundColor: "rgba(255, 255, 255, 1)"
                        //     // gradient: true
                        // }
                    ]
                },
                graphOptions: {
                    tooltips: {
                        callbacks: {
                            label: function (tooltipItems, data) {
                                return data.datasets[tooltipItems.datasetIndex].label + ": " + data.datasets[tooltipItems.datasetIndex].data[tooltipItems.index].toLocaleString()
                            }
                        }
                    },
                    legend: {
                        display: true,
                        labels: {
                            fontSize: 14
                        }
                    },
                    plugins: {
                        legend: {
                            labels: {
                                usePointStyle: true,
                                pointStyle: "rectRounded"
                            }
                        }
                    },
                    scales: {
                        yAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Visibility"
                                },
                                ticks: {
                                    beginAtZero: false,
                                    callback: function (value, index, values) {
                                        return value.toLocaleString()
                                    }
                                }
                                // ticks: {
                                //     beginAtZero: true
                                // }
                            }
                        ],
                        xAxes: [
                            {
                                scaleLabel: {
                                    display: true,
                                    labelString: "Dates"
                                }
                                // ticks: {
                                //     beginAtZero: true
                                // }
                            }
                        ]
                    },
                    responsive: true,
                    maintainAspectRatio: false
                }
            }
        },
        methods: {
            async fetchVisibility() {
                try {
                    console.log(this.selectedCompetitors)
                    const competitorArray = this.selectedCompetitors.map((c) => c.domain)
                    // Fetch visibility historical for selected Comps
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/competitors"
                    let post_data = {
                        company_id: localStorage.company_id,
                        project_id: this.$route.params.id,
                        query: {
                            project_id: this.$route.params.id,
                            category_id: this.category_id ? this.category_id : null,
                            domain: {
                                $in: competitorArray
                            }
                        },
                        fields: "domain visibility"
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.formatChartData(
                        response_data.data.competitors.sort(function (a, b) {
                            return b.visibility[0].value - a.visibility[0].value
                        })
                    )

                    console.log(response_data)
                } catch (error) {
                    //TODO: create notification
                    console.log(error)
                }
            },
            formatChartData(competitors) {
                // Chart-Daten-Objekt
                this.chartData = {
                    labels: [],
                    datasets: []
                }

                // Labels für den Chart erstellen
                const dates = competitors[0].visibility.map((entry) => entry.date)
                this.chartData.labels = dates.map((date) => this.getDayMonthAndYear(date)).reverse()

                // Daten für jeden Konkurrenten extrahieren und dem Chart-Daten-Objekt hinzufügen
                competitors.forEach((competitor) => {
                    const color = this.getColorForDomain(competitor.domain)
                    const dataset = {
                        label: competitor.domain,
                        data: competitor.visibility.map((entry) => entry.value).reverse(),
                        backgroundColor: "transparent",
                        borderColor: color,
                        backgroundColor: color + "05",
                        pointBackgroundColor: "rgba(255, 255, 255, 1)",
                        pointStyle: "circle",
                        pointRadius: 6,
                        pointHoverRadius: 10,
                        borderWidth: 2
                        // gradient: true
                    }
                    this.chartData.datasets.push(dataset)
                })
                this.renderChart++
                console.log(this.chartData)
            },

            // Hilfsfunktion zum Generieren eines Labels
            getDayMonthAndYear(dateString) {
                const date = new Date(dateString)
                const day = date.getDate()
                const month = date.toLocaleString("default", { month: "long" })
                const year = date.getFullYear()
                return `${day} ${month} ${year}`
            },
            // Hilfsfunktion zum Generieren einer Farbe aus einer Zeichenkette
            getColorFromString(str) {
                let hash = 0
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash)
                }

                const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16)
                return "#" + "0".repeat(6 - color.length) + color
            },

            // Neue Hilfsfunktion zum Abrufen der Farbe für eine Domain
            getColorForDomain(domain) {
                const fixedColors = {
                    "www.obi.de": "#f57b42",
                    "www.hornbach.de": "#c40075",
                    "www.bauhaus.info": "#ee1f26",
                    "www.ikea.com": "#ffdb00"
                }

                return fixedColors[domain] || this.getColorFromString(domain)
            }
        },
        watch: {
            selectedCompetitors(val) {
                console.log("chart: ", val)
                this.fetchVisibility()
            }
        },
        mounted() {
            this.fetchVisibility()
            this.renderChart++
        }
    }
</script>

<style lang="scss">
    #comp-chart-card {
        z-index: 3 !important;
    }
    #comp-chart-card > div.v-card__text {
        flex-grow: 1;
        min-height: 0;
        height: 100%;
        > div {
            position: relative;
            height: 280px;
        }
    }
</style>
