<template>
    <v-card flat>
        <v-card-text>
            <!-- Research Suite Credits -->
            <div class="credit-section mb-4">
                <div class="credittype mb-2">Research Suite</div>
                <div class="credittype_sub">Keyword Requests:</div>
                <div style="position: relative">
                    <v-sheet width="100%" height="20px" color="grey-lighten-3" class="d-flex overflow-hidden creditsheet my-1" style="border-radius: 10px">
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet
                                    :color="getColor({ count: credits.rs.update.count, credits: credits.rs.research.credits })"
                                    height="100%"
                                    :width="`${(credits.rs.update.count / credits.rs.research.credits) * 100}%`"
                                    v-bind="attrs"
                                    v-on="on" />
                            </template>
                            <span>{{ ((credits.rs.update.count / credits.rs.research.credits) * 100).toFixed(2) }}% of requests used for saved collections</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet color="#009f89bf" height="100%" :width="`${(credits.rs.research.count / credits.rs.research.credits) * 100}%`" v-bind="attrs" v-on="on" />
                            </template>
                            <span>{{ ((credits.rs.research.count / credits.rs.research.credits) * 100).toFixed(2) }}% of requests used for ideas or suggests</span>
                        </v-tooltip>
                        <v-tooltip top>
                            <template v-slot:activator="{ on, attrs }">
                                <v-sheet
                                    color="rgb(218 218 218)"
                                    height="100%"
                                    :width="`${(1 - (credits.rs.update.count / credits.rs.research.credits + credits.rs.research.count / credits.rs.research.credits)) * 100}%`"
                                    v-bind="attrs"
                                    v-on="on" />
                            </template>
                            <span>{{ ((1 - (credits.rs.update.count / credits.rs.research.credits + credits.rs.research.count / credits.rs.research.credits)) * 100).toFixed(2) }}% remaining</span>
                        </v-tooltip>
                        <!-- Zahl in der Mitte -->
                        <div style="position: absolute; left: 50%; top: 2px; transform: translate(-50%, -8%); color: white">
                            {{ Math.ceil(((credits.rs.update.count + credits.rs.research.count) / credits.rs.research.credits) * 100) }}%
                        </div>
                    </v-sheet>
                    Reset date: {{ calculateNextUpdateDate(credits.rs.reset_date) }}
                </div>
            </div>

            <!-- Content Suite Credits -->
            <div v-if="!isContentDisabled" :class="isMonitoringDisabled ? 'credit-section' : 'credit-section mb-4'">
                <div class="credittype mb-2">Content Suite</div>
                <div class="credittype_sub">Content Projects:</div>

                <div class="relative my-1">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <v-progress-linear
                                v-on="on"
                                :value="(credits.cs.new_projects.count / credits.cs.new_projects.credits) * 100"
                                :color="getColor(credits.cs.new_projects)"
                                height="20"
                                class="progress-bar"
                                ><template v-slot:default="{ value }"
                                    ><span class="white--text my-auto">{{ credits.cs.new_projects.count }} / {{ credits.cs.new_projects.credits }}</span>
                                </template></v-progress-linear
                            >
                        </template>
                        <span> {{ credits.cs.new_projects.count + " of " + credits.cs.new_projects.credits + " monthly content projects created" }}</span>
                    </v-tooltip>
                </div>

                Reset date: {{ calculateNextUpdateDate(credits.cs.reset_date) }}
            </div>

            <!-- Monitoring Credits -->
            <div v-if="!isMonitoringDisabled" class="credit-section">
                <div class="credittype mb-2">Monitoring Suite</div>
                <div class="credittype_sub">Tracked Keywords:</div>

                <div class="relative my-1">
                    <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                            <!-- :color="getColor(credits.ms.keywords)" -->

                            <v-progress-linear
                                v-on="on"
                                :value="(credits.ms.keywords.count / credits.ms.keywords.credits) * 100"
                                :color="getColor(credits.ms.keywords)"
                                height="20"
                                class="progress-bar"
                                ><template v-slot:default="{ value }">
                                    <span class="white--text my-auto"
                                        >{{ $helpers.formatNumber(credits.ms.keywords.count) }} / {{ $helpers.formatNumber(credits.ms.keywords.credits) }}
                                    </span></template
                                ></v-progress-linear
                            >
                        </template>
                        <span> {{ $helpers.formatNumber(credits.ms.keywords.count) + " of " + $helpers.formatNumber(credits.ms.keywords.credits) + " monthly keywords tracked" }}</span>
                    </v-tooltip>
                </div>
                Reset date: {{ calculateNextUpdateDate(credits.ms.reset_date) }}
            </div>
        </v-card-text>
    </v-card>
</template>

<script>
    export default {
        data() {
            return {
                credits: {},
                credits_fetched: false
            }
        },
        props: {
            show_all: Boolean,
            show_rs: Boolean,
            show_ms: Boolean,
            show_cs: Boolean
        },
        computed: {
            isMonitoringDisabled() {
                return this.$store.state.company_status_monitoring_suite != "active" && this.$store.state.company_status_monitoring_suite != "testing"
            },
            isResearchDisabled() {
                return this.$store.state.company_status != "active" && this.$store.state.company_status != "testing"
            },
            isContentDisabled() {
                return this.$store.state.company_status_content_suite != "active" && this.$store.state.company_status_content_suite != "testing"
            }
        },
        methods: {
            calculateNextUpdateDate(creationDate) {
                // Das Erstellungsdatum in ein Date-Objekt umwandeln
                const creation = new Date(creationDate)
                const today = new Date()

                // Das nächste Update-Datum initialisieren
                let nextUpdate = new Date(today.getFullYear(), today.getMonth(), creation.getDate())

                // Überprüfen, ob das nächste Update in diesem Monat oder im nächsten Monat liegt
                if (today > nextUpdate) {
                    // Wenn heute nach dem Update-Datum ist, auf den nächsten Monat verschieben
                    nextUpdate = new Date(today.getFullYear(), today.getMonth() + 1, creation.getDate())
                }

                // Formatierung für Deutschland
                const options = {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                }
                return new Intl.DateTimeFormat("de-DE", options).format(nextUpdate)
            },
            format_date(date_input) {
                // In ein Date-Objekt umwandeln
                const date = new Date(date_input)

                // Formatierung für Deutschland
                const options = {
                    day: "2-digit",
                    month: "2-digit",
                    year: "numeric"
                }
                let germanDate = new Intl.DateTimeFormat("de-DE", options).format(date)
                return germanDate
            },
            getColor(credit) {
                const percentage = (credit.count / credit.credits) * 100
                if (percentage > 75) return "red lighten-2"
                else if (percentage > 50) return "orange lighten-2"
                return "primary"
            },
            async getCredits() {
                const post_data = { company_id: localStorage.company_id }

                const url = process.env.VUE_APP_APIURL + "/company/credits/get"
                let response_data = await this.$helpers.axiosPost(url, post_data)
                this.credits = response_data.data
                console.log("CREDITS", this.credits)
                this.credits_fetched = true
            }
        },
        mounted() {
            this.getCredits()
        }
    }
</script>

<style scoped>
    .credit-section {
        position: relative;
        background-color: #f6f6f6;
        padding: 15px;
        border-radius: 10px;
    }

    .relative {
        position: relative;
    }

    .progress-bar {
        width: 100%;
        border-radius: 10px;
    }

    .credit-text {
        position: absolute;
        top: -5px;
        right: 10px;
        font-size: 14px;
        font-weight: bold;
        color: black;
        z-index: 1;
    }
    .credittype {
        text-transform: uppercase;
        font-size: 14px;
    }
    .creditsheet {
        border-radius: 10px;
    }
    .v-progress-linear__background {
        color: rgb(218 218 218) !important;
    }
    .credittype_sub {
        font-size: 14px;
    }
</style>
