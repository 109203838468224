<template>
    <v-dialog v-model="dialog" max-width="500px" @click:outside="$emit('close')">
        <v-card>
            <v-card-title
                >Change Table Columns
                <v-btn icon right absolute top color="primary" @click="$emit('close')"><v-icon>mdi-close</v-icon></v-btn>
            </v-card-title>
            <v-card-text class="mt-2">
                <draggable
                    :list="filterableHeaders"
                    class="list-group"
                    ghost-class="ghost"
                    @start="dragging = true"
                    @end="
                        dragging = false
                        headerOrderChanged()
                    "
                    handle=".handle">
                    <div class="list-group-item" v-for="header in filterableHeaders" :key="header.value">
                        <!-- <v-list-item-action>
                            <v-checkbox @change="logheaders" v-model="header.visible" :label="header.text"></v-checkbox>
                        </v-list-item-action> -->

                        <div class="header-list-entry">
                            <div class="column-dialog-row-item px-3 mx-auto">
                                <span class="handle mr-2">
                                    <v-icon>mdi-drag</v-icon>
                                </span>
                                <span class="my-auto ml-1">{{ header.text }}</span>

                                <v-switch inset flat hide-details class="ml-auto my-auto" v-model="header.visible"></v-switch>
                            </div>
                        </div>
                    </div>
                </draggable>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" rounded @click="$emit('close')">close</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
    import draggable from "vuedraggable"
    export default {
        components: {
            draggable
        },
        props: {
            headers: {
                type: Array,
                required: true
            },
            dialog: {
                type: Boolean,
                required: true
            }
        },
        methods: {
            headerOrderChanged() {
                this.$emit("headerOrderChanged", [this.headers[0], ...this.filterableHeaders])
            },
            logheaders() {
                console.log(this.headers)
            }
        },
        computed: {
            filterableHeaders() {
                // Nur Header anzeigen, die ein- und ausgeblendet werden können
                return this.headers.filter((header) => header.filterable !== false)
            }
        },
        watch: {
            headers(val) {
                console.log(val)
            }
        }
    }
</script>
<style>
    .column-dialog-row-item:hover #edit-column-icon {
        color: grey !important;
    }
    .handle {
        cursor: grab;
        float: left;
        padding-top: 9px;
        padding-bottom: 8px;
    }
</style>
