<template>
    <div id="contentcontainter" class="standard-mode">
        <v-container id="allresearches" fluid tag="section">
            <!-- Info -->
            <v-snackbar top :color="info.color" v-model="showInfo">
                <v-icon>{{ info.icon }}</v-icon>
                <span class="font-weight-bold pl-2">{{ info.message }}</span>
            </v-snackbar>
            <!-- SHARE ITEMS -->
            <ShareDialog :shareMenu="shareMenu" :shareItem="shareItem" @disableShareMenu="disableShareMenu" @createInfo="createInfo" :mountedOn="{ url: 'brand-index', name: 'Brand Dashboard' }">
            </ShareDialog>
            <!-- <v-row>
          <v-col cols="12" sm="12" md="6" xl='5'>
            <v-card class="px-5 py-3 rounded-card">
                <v-row>
                  <v-sheet
                    color="#42defa "
                    class="pa-12"
                    dark
                    top
                    id="tablesheet"
                    rounded
                    elevation='6'  
                    >    
                    <div class='headingsheet2'>  
                        <v-icon id="sheeticon" large>mdi-file-table </v-icon> 
                    </div>
                                  
                    </v-sheet>

                <v-card-title id="heading3">
                    {{addBrandIndex.title}}
                </v-card-title>

                </v-row>
                <v-card-text>
                    <v-row>
                        <v-col>
                            <v-text-field   
                                label="Project Name"
                                v-model="name"
                                required
                                prepend-icon="mdi-pencil-box"
                            ></v-text-field> 
                        </v-col>
                        <v-col xs12 id="kwpformflex">
                            <v-select
                                v-model="searchvolumeFrom"
                                :items="searchvolumeFromItems"
                                item-text="value"                          
                                label="country for searchvolume"
                                prepend-icon="mdi-map-marker"
                                return-object
                                overflow
                                attach>
                            </v-select>
                        </v-col>
                    </v-row>
                    
                        <file-pond
                            v-if="name && searchvolumeFrom.lang"
                            name="file"
                            ref="file"
                            class-name="my-pond"
                            label-idle="Drop csv files here..."
                            allow-multiple="true"
                            allow-revert="false"
                            max-files="3"
                            accepted-file-types="text/csv, application/*"
                            instant-upload: false
                            server = "http://localhost:3005/brands/"
                            v-on:init="handleFilePondInit"
                            v-on:addfile="setMeta"/>
                        <v-row justify='center'>
                            <v-btn v-if="name && searchvolumeFrom.lang" @click="upload" color="#42defa" dark >Upload</v-btn>
                        </v-row>
                </v-card-text>
            </v-card>
        </v-col>
    </v-row> -->

            <div v-if="loading && !brandindizes.length">
                <div class="loaderwrapper">
                    <center class="circularprogress">
                        <img id="loader" :src="getSrc()" />
                    </center>
                </div>
            </div>
            <v-row class="mt-10">
                <v-col cols="12">
                    <v-slide-x-transition>
                        <v-card class="px-5 py-3 rounded_card quaroshadow" v-if="!loading">
                            <v-row>
                                <v-sheet color="primary" max-height="200" class="pa-12 rounded_card" dark top id="tablesheet" elevation="6">
                                    <div class="headingsheet2">
                                        <v-icon id="sheeticon" large>mdi-file-table</v-icon>
                                    </div>
                                </v-sheet>

                                <v-card-title>
                                    <span id="heading3">Brand Dashboards</span>
                                </v-card-title>
                                <v-spacer></v-spacer>
                                <v-text-field rounded filled v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details dense class="searchfield"></v-text-field>
                            </v-row>
                            <v-spacer></v-spacer>
                            <v-tooltip top>
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn absolute right fab dark small @click="addDashboard" color="primary" class="fabicon" v-bind="attrs" v-on="on">
                                        <v-icon dark>mdi-plus</v-icon>
                                    </v-btn>
                                </template>
                                <span>create new Dashboard</span>
                            </v-tooltip>
                            <v-card-text id="tablecard">
                                <v-data-table id="brandstable" class="row-pointer" :headers="headers" :items="brandindizes" :search.sync="search" :custom-sort="customSort">
                                    <!-- header slot -->
                                    <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                                        <span :key="index">
                                            <span v-bind:style="{ width: header.width }">
                                                <!-- Adding tooltips to header -->

                                                <span class="quarotblheader">
                                                    {{ header.text }}
                                                </span>
                                            </span>
                                        </span>
                                    </template>
                                    <template v-slot:body="{ items }">
                                        <tbody>
                                            <tr v-for="item in items" :key="item.listname">
                                                <td @click="onColumnClick(item)">
                                                    <div class="listnameColumn">
                                                        {{ item.listname }}
                                                    </div>
                                                </td>
                                                <td @click="onColumnClick(item)">
                                                    <div class="spark" v-if="item.status !== 'creating' && item.status != 'updating'">
                                                        <!-- <v-sparkline
                                                            id="sparkline"
                                                            :value="item.trendData"
                                                            :gradient="gradient"
                                                            smooth="25"
                                                            padding="1.5"
                                                            line-width="0.4"
                                                            width="22"
                                                            height="7"
                                                            stroke-linecap="round"
                                                            gradient-direction="top"
                                                            auto-draw
                                                            :fill="true"></v-sparkline> -->
                                                        <v-row no-gutters>
                                                            <v-col class="d-flex">
                                                                <v-sparkline
                                                                    class="my-auto"
                                                                    style="max-width: 110px; max-height: 40px"
                                                                    id="sparkline"
                                                                    color="primary"
                                                                    :value="item.trendData.data"
                                                                    padding="2"
                                                                    smooth="20"
                                                                    gradient-direction="top"
                                                                    auto-draw
                                                                    :fill="false"></v-sparkline>
                                                                <v-sparkline
                                                                    class="my-auto"
                                                                    style="max-width: 110px; max-height: 40px; position: absolute"
                                                                    id="sparkline"
                                                                    color="primary"
                                                                    :value="item.trendData.data"
                                                                    :gradient="gradient"
                                                                    padding="2"
                                                                    smooth="20"
                                                                    gradient-direction="top"
                                                                    auto-draw
                                                                    :fill="true"></v-sparkline>
                                                                <v-row outlined small class="qchip my-auto pl-3" v-if="item.trendData.change">
                                                                    <span :style="'color: ' + item.trendData.icon_color" class="my-auto ml-2">{{ Math.abs(item.trendData.change) + "%" }} </span>

                                                                    <v-icon size="16px" :color="item.trendData.icon_color">{{ item.trendData.icon }}</v-icon>
                                                                </v-row>
                                                            </v-col>
                                                            <!-- <v-col style="max-width: 65px" class="d-flex">
                                                        <span outlined :color="item.trendData.icon_color" small class="qchip my-auto pl-1" v-if="item.trendData.change">
                                                            <v-icon>{{ item.trendData.icon }}</v-icon>
                                                            <span class="my-auto">{{ item.trendData.change }} </span>
                                                        </span>
                                                    </v-col> -->
                                                        </v-row>
                                                    </div>
                                                    <div v-else>
                                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                    </div>
                                                </td>
                                                <td @click="onColumnClick(item)">
                                                    <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                        <v-chip color="primary">{{ $helpers.formatNumber(item.count) }}</v-chip>
                                                    </div>
                                                    <div v-else>
                                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                    </div>
                                                </td>
                                                <td @click="onColumnClick(item)">
                                                    <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                        {{ $helpers.returnResearchDate(item.updated) }}
                                                    </div>
                                                    <div v-else>
                                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                    </div>
                                                </td>
                                                <td @click="onColumnClick(item)">
                                                    <div v-if="item.status != 'creating' && item.status != 'updating'">
                                                        <v-row>
                                                            <v-col class="ma-auto" style="display: flex">
                                                                <v-icon class="my-auto mr-1" small>mdi-map-marker</v-icon>
                                                                {{ item.country }}</v-col
                                                            >
                                                        </v-row>
                                                    </div>
                                                    <div v-else>
                                                        <v-progress-circular indeterminate color="primary"></v-progress-circular>
                                                    </div>
                                                </td>
                                                <td>
                                                    <v-menu rounded="lg" color="primary" transition="slide-y-transition" bottom v-if="item.status != 'creating' && item.status != 'updating'">
                                                        <template v-slot:activator="{ on }">
                                                            <v-btn
                                                                v-if="
                                                                    $helpers.isActionAllowed(22, user_rights) ||
                                                                    $helpers.isActionAllowed(27, user_rights) ||
                                                                    (decryptedId == item.owner && $helpers.isActionAllowed(29, user_rights))
                                                                "
                                                                id="cogbtn"
                                                                icon
                                                                v-on="on">
                                                                <v-icon>mdi-dots-vertical</v-icon>
                                                            </v-btn>
                                                        </template>
                                                        <v-list rounded dense>
                                                            <v-list-item
                                                                v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(27, user_rights)"
                                                                @click="openShareMenu(item)">
                                                                <v-icon small class="mr-2">mdi-share-variant</v-icon><v-list-item-title>Share</v-list-item-title>
                                                            </v-list-item>
                                                            <!-- <v-list-item v-if="item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(2, user_rights)" @click="initChangeNameDialog(item)">
                                    <v-icon small class="mr-2">mdi-square-edit-outline</v-icon><v-list-item-title>Rename</v-list-item-title>
                                    </v-list-item> -->
                                                            <v-list-item
                                                                v-if="
                                                                    (item.status != 'creating' && item.status != 'updating' && $helpers.isActionAllowed(22, user_rights)) ||
                                                                    (decryptedId == item.owner && $helpers.isActionAllowed(29, user_rights))
                                                                "
                                                                @click="prepareDeleteItem(item)">
                                                                <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title>
                                                            </v-list-item>
                                                        </v-list>
                                                    </v-menu>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </template>

                                    <!-- <template v-slot:item.action="{ item }" id="actionsColumn">

                        <v-icon v-if="item.status != 'creating' && $helpers.isActionAllowed(22, user_rights)"
                            @click="prepareDeleteItem(item)"
                        >
                            mdi-delete-outline
                        </v-icon> -->
                                    <!-- <v-icon
                            class="pl-3"
                                @click="share(item)"
                            >
                                mdi-share-variant-outline
                            </v-icon>
                            <v-menu
                                transition="slide-y-transition"
                                bottom
                            >
                                <template v-slot:activator="{ on, attrs }">
                                <v-btn
                                    icon
                                    v-bind="attrs"
                                    v-on="on"
                                    class="ml-2"
                                >
                                    <v-icon>mdi-dots-vertical</v-icon>
                                </v-btn>
                                </template>
                                <v-list>
                                <v-list-item
                                    v-for="(item, i) in items"
                                    :key="i"
                                    @click=""
                                >
                                    <v-list-item-title>{{ item.title }}</v-list-item-title>
                                </v-list-item>
                                </v-list>
                            </v-menu> -->
                                    <!-- </template> -->
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-slide-x-transition>
                </v-col>
            </v-row>
            <!-- delete dialog -->
            <v-dialog v-model="deleteQuestion" max-width="300">
                <v-card>
                    <v-card-title
                        style="
                             {
                                text-align: center;
                            }
                        ">
                        Are you sure?</v-card-title
                    >

                    <v-card-actions>
                        <v-btn class="mr-auto" text @click="deleteQuestion = false"> Nevermind </v-btn>
                        <v-btn class="ml-auto" color="primary" text @click="deleteItem(selectedDeleteItem)"> Yes </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

            <!-- delete alert -->
            <v-snackbar v-model="deleteSuccess" color="#16c96d" type="success" top>
                <v-icon size="25">mdi-check</v-icon><span class="font-weight-bold pl-2">The selected research has been deleted!</span>
            </v-snackbar>
        </v-container>
    </div>
</template>

<script>
    import ShareDialog from "../components/dialogs/shareDialog/Sharedialog"

    export default {
        components: {
            ShareDialog
        },
        data() {
            return {
                loading: false,
                showInfo: false,
                info: {
                    message: null,
                    icon: null,
                    color: null
                },
                items: [{ title: "Click Me" }, { title: "Click Me" }, { title: "Click Me" }, { title: "Click Me 2" }],
                gradient: [this.$vuetify.theme.themes.light.sparklineGradient1, this.$vuetify.theme.themes.light.sparklineGradient2, this.$vuetify.theme.themes.light.sparklineGradient3],
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
                headers: [
                    {
                        text: "TITLE",
                        value: "listname"
                    },
                    {
                        text: "TREND (GOOGLE SV)",
                        value: "trendData",
                        algin: "center"
                    },
                    {
                        text: "KEYWORDS",
                        value: "count"
                    },
                    {
                        text: "UPDATED ON",
                        value: "updated"
                    },
                    {
                        text: "COUNTRY",
                        value: "country"
                    },
                    {
                        sortable: false,
                        text: "",
                        value: "action"
                    }
                ],
                search: "",
                brandindizes: [],
                deleteQuestion: false,
                deleteSuccess: false,
                searchvolumeFrom: {},
                searchvolumeFromItems: [
                    { lang: "1001", loc: "2276", value: "Germany" },
                    { lang: "1001", loc: "2040", value: "Austria" },
                    { lang: "1001", loc: "2756", value: "Switzerland" },
                    { lang: "1000", loc: "2826", value: "United Kingdom" },
                    { lang: "1004", loc: "2380", value: "Italy" },
                    { lang: "1030", loc: "2616", value: "Poland" },
                    { lang: "1031", loc: "2643", value: "Russia" },
                    { lang: "1039", loc: "2191", value: "Croatia" },
                    { lang: "1021", loc: "2203", value: "Czechia" },
                    { lang: "1033", loc: "2703", value: "Slovakia" },
                    { lang: "1034", loc: "2705", value: "Slovenia" },
                    { lang: "1024", loc: "2348", value: "Hungary" },
                    { lang: "1002", loc: "2250", value: "France" }
                ],
                name: "",
                addBrandIndex: {
                    title: "Upload a Brand Competiton Analysis",
                    subtitle: "Change global settings or add new indexes"
                },

                //sharing
                shareMenu: false,
                shareItem: {}
            }
        },
        computed: {
            user_rights() {
                return this.$store.state.user_rights
            },
            decryptedId() {
                if (localStorage.id) {
                    return this.CryptoJS.AES.decrypt(localStorage.id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
                } else {
                    return ""
                }
            },
            company_id() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            }
        },
        methods: {
            getSrc() {
                if (this.company_id == "1547") {
                    return require("@/assets/quaro-q-obi.svg")
                } else {
                    return require("@/assets/quaro-q.svg")
                }
            },
            customSort(items, index, isDesc) {
                if (!index[0]) return items
                items.sort((a, b) => {
                    if (index[0] == "trendData") {
                        if (!a[index[0]].change) a[index[0]].change = 0
                        if (!b[index[0]].change) b[index[0]].change = 0
                        if (!isDesc[0]) {
                            return Number(a[index[0]].change) - Number(b[index[0]].change)
                        } else {
                            return Number(b[index[0]].change) - Number(a[index[0]].change)
                        }
                    } else {
                        //  standard columns
                        if (!isNaN(a[index[0]])) {
                            // String
                            if (!isDesc[0]) {
                                return a[index[0]] - b[index[0]]
                            } else {
                                return b[index[0]] - a[index[0]]
                            }
                        } else {
                            // Number
                            if (!isDesc[0]) {
                                return a[index[0]] < b[index[0]] ? -1 : 1
                            } else {
                                return b[index[0]] < a[index[0]] ? -1 : 1
                            }
                        }
                    }
                })
                return items
            },
            disableShareMenu() {
                this.shareMenu = false
            },
            openShareMenu(item) {
                this.shareItem = item
                this.shareMenu = true
                console.log("Sharemenu")
            },

            addDashboard() {
                try {
                    this.$matomo.trackEvent("Brands", "addDashboard", "", "")
                } catch (error) {
                    console.log(error)
                    console.log("matomo blocked")
                }
                this.$router.push("/add-dashboard")
            },
            returnDate(date) {
                if (date) {
                    let dateSplits = date.split("/")
                    let months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]
                    return months[dateSplits[0] - 1] + " " + dateSplits[1]
                }
                return null
            },
            fetchBrandindizes() {
                var type = "brandindex"
                this.loading = true
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/research/list", { type: type, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        //console.log(response);
                        let creatingDashboards = []
                        for (let index = 0; index < response.body.length; index++) {
                            if (response.body[index].mdata.status == "creating") {
                                let brandindex = {}
                                //console.log(response.body[index].mdata.updated)
                                brandindex.listname = response.body[index].listname
                                brandindex.count = response.body[index].count
                                brandindex.updated = response.body[index].mdata.updated
                                brandindex.country = response.body[index].mdata.langloc.value
                                brandindex.trendData = this.getTrendData(response.body[index].trendData)
                                brandindex.status = response.body[index].mdata.status
                                creatingDashboards.push(brandindex)
                            }
                        }

                        this.brandindizes = creatingDashboards
                        for (let index = 0; index < response.body.length; index++) {
                            if (response.body[index].mdata.status !== "creating") {
                                let brandindex = {}
                                //console.log(response.body[index].mdata.updated)
                                brandindex.listname = response.body[index].listname
                                brandindex.count = response.body[index].count
                                brandindex.updated = response.body[index].mdata.updated
                                brandindex.country = response.body[index].mdata.langloc.value
                                brandindex.trendData = this.getTrendData(response.body[index].trendData)
                                brandindex.status = response.body[index].mdata.status
                                this.brandindizes.push(brandindex)
                            }
                        }
                        this.loading = false
                    })
            },
            getTrendData(data) {
                //TODO: the if can be removed after one update of sv - everey colection will have data[0].volume
                // <!-- check if new format -  introduced in march 2024 -->
                if (data && data[0] && data[0].volume) {
                    let trendData = JSON.parse(JSON.stringify(data))
                        .slice(0, 12)
                        .reverse()
                        .map((e) => e.volume)
                    let latest = trendData[trendData.length - 1]
                    let previous = trendData[trendData.length - 2]
                    let change = (latest - previous) / previous
                    let color = change < 0 ? "#c10000" : "#008000"
                    console.log()
                    color += this.$vuetify.theme.dark == false ? "91" : "" // adding transparency for dark mode
                    return {
                        data: trendData,
                        icon: change < 0 ? "mdi-chevron-down-circle-outline" : "mdi-chevron-up-circle-outline",
                        icon_color: color,
                        change: Math.ceil(change * 100, 2)
                    }
                }
                return { data: data }
            },
            onColumnClick(item) {
                console.log(item)
                if (item.count) {
                    this.$router.push({ path: "/brand-index/" + item.listname })
                } else {
                    this.createInfo("This dashboard is currently being created", "#fcba03", "mdi-alert-circle-outline")
                }
            },
            createInfo(message, color, icon) {
                this.info.message = message
                this.info.color = color
                this.info.icon = icon
                this.showInfo = true
            },
            prepareDeleteItem(props) {
                event.stopPropagation()
                this.deleteQuestion = true
                this.selectedDeleteItem = props
            },
            deleteItem(props) {
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/research/delete", { listname: props.listname, company_id: localStorage.company_id }, { headers: { Authorization: this.auth } })
                    .then(function (response) {
                        this.brandindizes = this.$helpers.removeElement(props.listname, this.brandindizes, "listname")
                        this.deleteQuestion = false
                        this.deleteSuccess = true
                    })
            }
        },
        beforeRouteLeave(to, from, next) {
            next()
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.fetchBrandindizes()

                // if (vm.keywords.length) {
                //     vm.unfilteredKeywords = [...vm.keywords]
                //     vm.keywords = vm.$helpers.filterKeywordTable(vm.unfilteredKeywords)
                // }
                // vm.checkIfSendTo()

                //test end check
                if (vm.$store.state.company_status === "test_ended") {
                    vm.$router.push("/endoftest")
                }
            })
        },
        mounted: function () {
            //SOCKET AREA
            this.$socket.on("reloadResearches", (data) => {
                console.log("Reloading Brands")
                this.deleteQuestion = false
                this.fetchBrandindizes()
            })
        }
    }
</script>
<style>
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
</style>
<style scoped>
    #actionsColumn {
        text-align: end !important;
    }
    #tablecard {
        padding-left: 0px;
        padding-right: 0px;
    }
    hr {
        margin-left: 25px;
        margin-right: 25px;
        border: 1px solid lightgrey;
        border-radius: 5px;
    }
    #heading3 {
        font-weight: 300 !important;
        color: rgb(105, 105, 105);
    }
    #sparkline {
        max-width: 100px;
    }
    .headingsheet {
        margin-top: -15px;
    }
    .headingsheet2 {
        margin-top: -15px;
    }
    #searchsheet {
        margin-top: -50px;
        width: 30px;
        height: 30px;
    }
    #tablesheet {
        margin-left: 10px;
        margin-top: -30px;
        width: 60px;
        height: 60px;
    }
    #tablesheet2 {
        margin-top: -30px;
        height: 60px;
    }
    .rounded-card {
        margin-top: 30px;
    }
    #sheeticon {
        margin-left: -25px;
        margin-top: -25px;
        margin: auto !important;
        padding-bottom: 50% !important;
        width: 50% !important;
    }
    #sheeticon2 {
        padding-bottom: 70% !important;
        margin-bottom: 10px;
    }
    .inline {
        display: inline !important;
    }
    .shortcol {
        max-width: 30% !important;
    }
    .row-pointer >>> tbody tr :hover {
        cursor: pointer;
    }

    .v-card__title {
        padding-top: 0px;
    }
    #kwcountCard {
        padding-bottom: 10px !important;
    }

    .v-application .pa-12 {
        padding: 50px !important;
    }
    #mainCardTitle {
        padding-top: 20px !important;
    }
    .addIndexTitle {
        font-size: 1.25rem;
        padding-top: 3px;
        font-weight: 300 !important;
        padding-left: 5px;
        text-align: center;
    }
    .searchfield {
        max-width: 20%;
    }
    #allresearches {
        padding-top: -0px;
        margin-top: -10px;
    }
    tr:hover #cogbtn {
        color: var(--v-darkgreen-base) !important;
    }
    .circularprogress {
        padding-top: 15vh;
    }
</style>
