<template>
    <v-data-table id="cluster_rankings_table" hide-default-headers :headers="urlHeader" :items="urls">
        <!-- header slot -->
        <template v-for="(header_entry, index) in urlHeader" v-slot:[`header.${header_entry.value}`]="{ header }">
            <span :key="index">
                <span v-bind:style="{ width: header.width }">
                    <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                {{ header.text }}
                                <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                            </span>
                        </template>
                        <span>
                            <v-row no-gutters>
                                <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                            </v-row>
                            {{ header.tooltip }}
                        </span>
                    </v-tooltip>
                </span>
            </span>
        </template>

        <template v-slot:item.url="{ item }">
            <div class="py-5">
                <span @click="openUrl(item.url)" class="urllink">{{ item.url }}</span>
            </div>
        </template>
        <template v-slot:item.estimatedTraffic="{ item }">
            <v-chip outlined class="qchip" color="primary" style="">
                {{ $helpers.formatNumber(item.estimatedTraffic) }}
            </v-chip>
        </template>
        <template v-slot:item.averagePosition="{ item }">
            <v-chip outlined class="qchip" color="primary" style="">
                {{ $helpers.formatNumber(item.averagePosition) }}
            </v-chip>
        </template>
        <template v-slot:item.top10Count="{ item }">
            <v-chip outlined class="qchip" color="primary" style="">
                {{ $helpers.formatNumber(item.top10Count) }}
            </v-chip>
        </template>
        <template v-slot:item.top50Count="{ item }">
            <v-chip outlined class="qchip" color="primary" style="">
                {{ $helpers.formatNumber(item.top50Count) }}
            </v-chip>
        </template>
        <template v-slot:item.top100Count="{ item }">
            <v-chip outlined class="qchip" color="primary" style="">
                {{ $helpers.formatNumber(item.top100Count) }}
            </v-chip>
        </template>
    </v-data-table>
</template>
<script>
    import { positionColors } from "../helper/global.js"
    export default {
        props: {
            category_id: {
                type: String
            },
            search: {
                type: String
            },
            project_domain: {
                type: String
            },
            selectedCompetitors: {
                type: Array,
                required: true
            },
            parent: { type: Object },
            selectedDates: { type: Array },
            fetchClusterRanking: { type: Number }
        },
        data: () => ({
            urls: [],
            rankings: [],
            tooltipMenuData: {},
            menuOpenState: {},
            showAllCategories: false,
            menu: false, // Für das Datumsfeld
            dateRange: "",
            datePickerRange: null, // Bereich für Datumsauswahl
            ctr: [
                {
                    position: "1",
                    desktop: 28.74,
                    mobile: 26.17
                },
                {
                    position: "2",
                    desktop: 12.32,
                    mobile: 13.28
                },
                {
                    position: "3",
                    desktop: 7.01,
                    mobile: 8.45
                },
                {
                    position: "4",
                    desktop: 4.66,
                    mobile: 5.39
                },
                {
                    position: "5",
                    desktop: 3.3,
                    mobile: 3.76
                },
                {
                    position: "6",
                    desktop: 2.43,
                    mobile: 2.75
                },
                {
                    position: "7",
                    desktop: 1.85,
                    mobile: 2
                },
                {
                    position: "8",
                    desktop: 1.42,
                    mobile: 1.51
                },
                {
                    position: "9",
                    desktop: 1.17,
                    mobile: 1.19
                },
                {
                    position: "10",
                    desktop: 0.98,
                    mobile: 0.99
                },
                {
                    position: "11",
                    desktop: 0.86,
                    mobile: 0.9
                },
                {
                    position: "12",
                    desktop: 0.82,
                    mobile: 0.96
                },
                {
                    position: "13",
                    desktop: 0.93,
                    mobile: 1.08
                },
                {
                    position: "14",
                    desktop: 0.97,
                    mobile: 1.26
                },
                {
                    position: "15",
                    desktop: 1,
                    mobile: 1.47
                },
                {
                    position: "16",
                    desktop: 1.02,
                    mobile: 1.57
                },
                {
                    position: "17",
                    desktop: 0.99,
                    mobile: 1.65
                },
                {
                    position: "18",
                    desktop: 1.04,
                    mobile: 1.66
                },
                {
                    position: "19",
                    desktop: 0.99,
                    mobile: 1.64
                },
                {
                    position: "20",
                    desktop: 0.88,
                    mobile: 1.62
                }
            ]
        }),
        computed: {
            urlHeader() {
                let h = [
                    { text: "Url", value: "url", width: "500px", width: "350px" },
                    { text: "Est. Traffic", value: "estimatedTraffic", align: "end", width: "150px", tooltip: "Shows the average google search volume for a keyword" },
                    { text: "Avg. Position", value: "averagePosition", align: "end", width: "150px", tooltip: "Shows the average google search volume for a keyword" },
                    { text: "Top 10 Rankings", value: "top10Count", align: "end", width: "150px", tooltip: "Shows the average google search volume for a keyword" },
                    { text: "Top 50 Rankings", value: "top50Count", align: "end", width: "150px", tooltip: "Shows the average google search volume for a keyword" },
                    { text: "Top 100 Rankings", value: "top100Count", align: "end", width: "150px", tooltip: "Shows the average google search volume for a keyword" }
                ]

                return h
            }
        },
        watch: {
            fetchClusterRanking() {
                // Fetch data when the domains prop changes
                console.log("waaaaaaaaaaatch")
                console.log("waaaaaaaaaaatch")
                console.log("waaaaaaaaaaatch")
                console.log("waaaaaaaaaaatch")

                this.fetchClusterKeywords()
            }
        },
        methods: {
            openUrl(url) {
                window.open(url, "_blank")
            },
            getColor(position) {
                let colorIndex = Math.ceil(position / 10) - 1
                let color = positionColors[colorIndex] ? positionColors[colorIndex] : positionColors[positionColors.length - 1]
                return color
            },
            getColoFromIndex(index) {
                let colors = ["#C2185B", "#7B1FA2", "#1976D2", "#0097A7", "#388E3C", "#AFB42B", "#F57C00", "#5D4037", "#455A64"]
                return colors[index]
            },
            getRankingPosition(item, domain) {
                if (!item.rankings) {
                    return { position: "-", change: null }
                }
                const ranking = item.rankings.find((r) => r.domain === domain)
                // console.log(item)
                const oldRanking = item.rankingsOld ? item.rankingsOld.find((r) => r.domain === domain) : null
                const change = oldRanking && ranking ? ranking.position - oldRanking.position : null
                return { position: ranking ? ranking.position : "-", change: change * -1 } // *-1 : 16 --> 20 = +4 sollte aber minuse 4 ergeben daher *-1
            },
            getColorFromString(str) {
                let hash = 0
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash) + str.length
                }
                const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16)
                return "#" + "0".repeat(6 - color.length) + color
            },
            menuOpen(item, competitor) {
                const isMenuOpen = this.menuOpenState[competitor + item.keyword]
                console.log(isMenuOpen)
                if (isMenuOpen) {
                    console.log("Menu opened:", item, competitor)
                    //set tooltipdata
                    let labels = this.selectedDates[1] == null ? [JSON.parse(JSON.stringify(this.selectedDates[0]))] : JSON.parse(JSON.stringify(this.selectedDates))
                    let datasets = []
                    if (item.rankings) {
                        let ranking = item.rankings.find((e) => e.domain == competitor)
                        if (ranking) {
                            // best ranking
                            let dataset = {
                                label: ranking.url,
                                data: [undefined, ranking.position],
                                borderColor: this.getColoFromIndex(0) || this.getColorFromString(ranking.url),
                                backgroundColor: (this.getColoFromIndex(0) || this.getColorFromString(ranking.url)) + "20",
                                pointBackgroundColor: (this.getColoFromIndex(0) || this.getColorFromString(ranking.url)) + "20",
                                pointStyle: "circle",
                                pointRadius: 6,
                                pointHoverRadius: 10,
                                borderWidth: 2,
                                fill: "start"
                            }
                            datasets.push(dataset)
                            ranking.multiple.forEach((r, index) => {
                                // adding additional rankigns
                                let dataset = {
                                    label: r.url,
                                    data: [undefined, r.position],
                                    borderColor: this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url),
                                    backgroundColor: (this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url)) + "20",
                                    pointBackgroundColor: (this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url)) + "20",
                                    pointStyle: "circle",
                                    pointRadius: 6,
                                    pointHoverRadius: 10,
                                    borderWidth: 2,
                                    fill: "start"
                                }
                                datasets.push(dataset)
                            })
                        }
                    }
                    if (item.rankingsOld) {
                        let rankingOld = item.rankingsOld ? item.rankingsOld.find((e) => e.domain == competitor) : null
                        if (rankingOld) {
                            // find eintrag und setzte ersten data punkt
                            let index = datasets.findIndex((e) => e.label == rankingOld.url)
                            if (index === -1) {
                                let dataset = {
                                    label: rankingOld.url,
                                    data: [rankingOld.position, undefined],
                                    borderColor: this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url),
                                    backgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                    pointBackgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                    pointStyle: "circle",
                                    pointRadius: 6,
                                    pointHoverRadius: 10,
                                    borderWidth: 2,
                                    fill: "start"
                                }
                                datasets.push(dataset)
                            } else {
                                datasets[index].data[0] = rankingOld.position
                            }
                            rankingOld.multiple.forEach((r) => {
                                let index = datasets.findIndex((e) => e.label == r.url)
                                // adding additional rankigns
                                if (index === -1) {
                                    let dataset = {
                                        label: r.url,
                                        data: [r.position, undefined],
                                        borderColor: this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url),
                                        backgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                        pointBackgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                        pointStyle: "circle",
                                        pointRadius: 6,
                                        pointHoverRadius: 10,
                                        borderWidth: 2,
                                        fill: "start"
                                    }
                                    datasets.push(dataset)
                                } else {
                                    datasets[index].data[0] = r.position
                                }
                            })
                        }
                    }
                    this.tooltipMenuData.labels = labels
                    this.tooltipMenuData.datasets = datasets
                    this.tooltipMenuData.keyword = item.keyword
                    this.tooltipMenuData.domain = competitor
                    this.tooltipMenuData.chartData = {
                        labels: labels,
                        datasets: datasets
                    }
                    this.tooltipMenuData.tableHeaders = [
                        { text: "URL", value: "url" },
                        ...labels.map((l) => {
                            return { text: l.substring(0, 10), value: l, width: "150px" }
                        })
                    ]
                    this.tooltipMenuData.tableRows = this.tooltipMenuData.datasets.map((d) => {
                        let row = { url: d.label }
                        this.tooltipMenuData.labels.forEach((l, index) => {
                            row[l] = d.data[index]
                        })
                        return row
                    })

                    setTimeout(() => {
                        this.renderChart++
                    }, 110)
                } else {
                    console.log("Menu closed:", item, competitor)
                }
            },
            showAllCatsInTable() {
                event.stopPropagation()
                this.showAllCategories = !this.showAllCategories
            },

            async fetchClusterKeywords() {
                console.log(this.parent.keywords)

                try {
                    console.log(this.selectedDates)
                    console.time("fetch rankings")
                    const [dataForDate1, dataForDate2] = await Promise.all([
                        this.fetchDataForDate(this.selectedDates[0], this.parent.keywords),
                        this.fetchDataForDate(this.selectedDates[1], this.parent.keywords)
                    ])
                    console.timeEnd("fetch rankings")
                    if (!dataForDate2.rankings.length) {
                        dataForDate2.rankings = JSON.parse(JSON.stringify(dataForDate1.rankings))
                        dataForDate2.totalCount = JSON.parse(JSON.stringify(dataForDate1.totalCount))
                    }
                    dataForDate1.rankings.map((oldRanking) => {
                        let actualRanking = dataForDate2.rankings.find((r) => r.keyword == oldRanking.keyword)

                        if (actualRanking) {
                            actualRanking.rankingsOld = oldRanking.rankings
                        } else {
                            console.log("couldnt find ranking")
                        }
                    })

                    let rankings = dataForDate2.rankings.map((ranking) => {
                        // if (ranking.search_intent) {
                        //     this.show_intents = true
                        //     ranking.sorted_search_intent = this.sortIntent(JSON.parse(JSON.stringify(ranking.search_intent)))
                        // }
                        ranking.category_ids.sort()
                        ranking.category_id = ranking.category_ids[ranking.category_ids.length - 1]
                        return ranking
                    })
                    this.rankings = rankings

                    console.log(rankings)

                    let urlsData = []
                    let filtered = rankings.map((ranking) => {
                        let domainRanking = ranking.rankings.find((r) => r.domain === this.project_domain)
                        // ranking.rankings.map((domainRanking) => {
                        if (domainRanking) {
                            var data = { keyword: ranking.keyword, avg_search_volume: ranking.avg_search_volume, position: domainRanking.position }
                            let urlIndex = urlsData.findIndex((urlsEntry) => urlsEntry.url == domainRanking.url)
                            if (urlIndex > -1) {
                                urlsData[urlIndex].rankings.push(data)
                            } else {
                                urlsData.push({ url: domainRanking.url, rankings: [data] })
                            }
                            domainRanking.multiple.map((mr) => {
                                var data = { keyword: ranking.keyword, avg_search_volume: ranking.avg_search_volume, position: mr.position }
                                let urlIndex = urlsData.findIndex((urlsEntry) => urlsEntry.url == mr.url)
                                if (urlIndex > -1) {
                                    urlsData[urlIndex].rankings.push(data)
                                } else {
                                    urlsData.push({ url: mr.url, rankings: [data] })
                                }
                            })
                        }
                    })

                    // .map((ranking) => {
                    //     console.log(ranking)

                    //     urls.push(ranking.url)
                    //     ranking.multiple.length ? ranking.multiple.map((r) => urls.push(r.url)) : ""
                    // })
                    urlsData.map((entry) => {
                        const rankings = entry.rankings
                        const totalPositions = rankings.map((r) => r.position).reduce((a, b) => a + b, 0)
                        entry.averagePosition = totalPositions / rankings.length

                        entry.top10Count = rankings.filter((r) => r.position <= 10).length
                        entry.top50Count = rankings.filter((r) => r.position <= 50).length
                        entry.top100Count = rankings.filter((r) => r.position <= 100).length

                        entry.estimatedTraffic = rankings.map((r) => (r.position < 21 ? this.ctr[r.position - 1].mobile * r.avg_search_volume : 0)).reduce((a, b) => a + b, 0)
                    })
                    this.urls = urlsData
                    console.log("urls", urlsData)
                    console.log("cluster sv", this.parent.cluster_search_volume)
                } catch (error) {
                    // loading = false
                    console.error(`Fehler beim Laden der Keywords für Clusters ${parent._id}:`, error)
                }
            },
            async fetchDataForDate(date, keywords) {
                try {
                    const domainArray = this.selectedCompetitors.map((c) => c.domain)
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/frontend/keywords"
                    // const sort = this.getSortObject(tableOptions)
                    // console.log(tableOptions.sortDesc)
                    const post_data = {
                        company_id: localStorage.company_id,
                        company_status: this.$store.state.company_status_monitoring_suite,
                        project_id: this.$route.params.id,
                        category_id: this.category_id ? this.category_id : null,
                        domains: domainArray,
                        sortDesc: -1,
                        keywords: keywords.map((k) => k.keyword),
                        date: date, // Use the current date from the loop
                        search: "",
                        start: 1,
                        limit: 10000,
                        sort: { avg_search_volume: -1 },
                        filters: null
                    }

                    // if (this.sortDomain) {
                    //     post_data.sortDomain = this.sortDomain
                    // }

                    const response_data = await this.$helpers.axiosPost(url, post_data)
                    return response_data.data
                } catch (error) {
                    console.log(error)
                    throw error
                }
            }
        },
        mounted() {
            this.fetchClusterKeywords()
        }
    }
</script>
<style lang="scss">
    #cluster_rankings_table .v-data-footer {
        background-color: #edeff1 !important;
    }
    .urllink:hover {
        cursor: pointer;
        text-decoration: underline;
        color: blue;
    }
</style>
