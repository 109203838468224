<template>
    <div id="contentcontainter" class="standard-mode">
        <v-snackbar v-model="notification.show" vertical timeout="-1" top transition="slide-x-transition">
            <div class="text-subtitle-1 pb-2">{{ notification.title }}</div>
            <p>{{ notification.text }}</p>
            <template v-slot:action="{ attrs }">
                <v-btn color="primary" v-bind="attrs" @click="notification.show = false"> OK </v-btn>
            </template>
        </v-snackbar>
        <v-snackbar top v-model="classicnotification.show" :color="classicnotification.color" v-if="classicnotification">
            <v-icon>{{ classicnotification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ classicnotification.message }} </span>
        </v-snackbar>
        <div v-if="!project || loading">
            <div style="display: flex">
                <span style="margin-top: 20vh" class="mx-auto">
                    <img id="loader" :src="getSrc()" />
                </span>
            </div>
        </div>
        <v-slide-x-transition>
            <div class="mt-10 mb-5" v-if="project && !loading">
                <component-header
                    :active="{ url: 'content-projects', name: 'Content Project', destination: 'content-project/competitors' }"
                    :title="project.title"
                    :id="project._id"
                    :device="project.device"
                    :project="project"
                    :language_code="project.langloc.language_code"
                    :share_data="share_data"
                    :shared_extern="shared_extern"
                    :shared_company_id="sharedCompanyId"></component-header>
            </div>
        </v-slide-x-transition>
        <v-row>
            <v-col md="12" sm="12" x-sm="12">
                <v-slide-x-transition>
                    <v-expansion-panels v-model="metrics_panel" flat accordion elevation-1 class="px-0" v-if="project && !loading">
                        <v-expansion-panel class="quaroshadow rounded_card">
                            <div class="backsheet"></div>
                            <v-expansion-panel-header dense class="py-1">
                                <div class="quarolbl">
                                    <v-icon class="pb-1 mr-1" small color="grey">mdi-cog</v-icon>
                                    Settings
                                </div>
                                <template v-slot:actions>
                                    <v-icon color="primary"> mdi-chevron-down </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <v-row no-gutters>
                                    <v-col class="pa-0 mb-2" md="6" sm="12">
                                        <div class="selectionCol mr-2">
                                            <span class="qsublbl">Reference</span>
                                            <v-row class="pl-3" v-if="project.url">
                                                <v-text-field class="my-auto" disabled hide-details rounded filled dense :value="project.url" placeholder="select manually"></v-text-field>

                                                <v-chip-group class="pl-2" color="orange darken-2" column show-arrows v-model="reffilter" next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                    <v-chip filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle"> Pin URL </v-chip>
                                                </v-chip-group>
                                            </v-row>
                                            <v-row v-else>
                                                <v-btn
                                                    v-if="$store.state.content_suite_group.id == 1"
                                                    :disabled="addURLLoading"
                                                    small
                                                    class="ml-2 mt-2"
                                                    color="primary"
                                                    outlined
                                                    @click="addURLDialog.show = true">
                                                    <span v-if="!addURLLoading">add url</span>
                                                    <v-progress-circular class="mx-3" v-else indeterminate size="20" width="2" color="primary"></v-progress-circular>
                                                </v-btn>

                                                <AddUrlDialog
                                                    :show="addURLDialog.show"
                                                    :id="$route.params.project_id"
                                                    @started="
                                                        addURLLoading = true
                                                        notification.show = true
                                                        addURLDialog.show = false
                                                    "
                                                    @added="
                                                        fetchProject($route.params.project_id)
                                                        addURLLoading = false
                                                    "
                                                    @close="addURLDialog.show = false"
                                                    :setMainURL="true"
                                                    :addLighthouseData="true"></AddUrlDialog>
                                            </v-row>
                                        </div>
                                    </v-col>

                                    <v-col class="pa-0 mb-2" md="6" sm="12">
                                        <div class="selectionCol mr-2">
                                            <v-tooltip max-width="250" color="primary" top>
                                                <template v-slot:activator="{ on }">
                                                    <span class="qsublbl" v-on="on">
                                                        {{ project.setup.from_monitoring ? "Topic Cluster" : "Topic Keywords" }}

                                                        <v-icon class="questionIcon" x-small>mdi-help-circle-outline</v-icon>
                                                    </span>
                                                </template>
                                                <span>
                                                    <v-row no-gutters>
                                                        <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                                    </v-row>
                                                    Specifies the selected focus topics or keywords for the content of the project. These topics or keywords help to guide the writer and ensure that
                                                    the content is relevant to the desired subject matter.
                                                </span>
                                            </v-tooltip>

                                            <v-chip-group color="primary" v-model="keywordfilter" mandatory show-arrows multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                                <v-chip v-for="topic in selectedTopics" :key="topic.keyword" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                                    {{ topic.keyword || topic.name }}
                                                </v-chip>
                                            </v-chip-group>
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-item-group v-model="selectedHeaders" multiple>
                                    <v-row no-gutters>
                                        <v-col class="pr-2" xl="3" md="3" sm="6" xs="12">
                                            <div class="selectionCol">
                                                <div class="qsublbl">Lighthouse Metrics</div>
                                                <div v-for="(lh_score_header, i) in lh_score_headers" :key="i">
                                                    <v-item v-slot="{ active, toggle }">
                                                        <v-checkbox
                                                            color="cyan darken-2"
                                                            on-icon="mdi-check-circle"
                                                            off-icon="mdi-checkbox-blank-circle-outline"
                                                            rounded
                                                            class="mt-0"
                                                            single-line
                                                            hide-details
                                                            v-model="lh_score_header.selected"
                                                            :label="lh_score_header.text"></v-checkbox>
                                                    </v-item>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col class="pr-2" xl="3" md="3" sm="6" xs="12">
                                            <div class="selectionCol">
                                                <div class="qsublbl">Snippet Metrics</div>
                                                <div v-for="(snippet_header, i) in snippet_headers" :key="i">
                                                    <v-item v-slot="{ active, toggle }">
                                                        <v-checkbox
                                                            color="cyan darken-2"
                                                            on-icon="mdi-check-circle"
                                                            off-icon="mdi-checkbox-blank-circle-outline"
                                                            rounded
                                                            class="mt-0"
                                                            single-line
                                                            hide-details
                                                            v-model="snippet_header.selected"
                                                            :label="snippet_header.text"></v-checkbox>
                                                    </v-item>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col class="pr-2" xl="3" md="3" sm="6" xs="12">
                                            <div class="selectionCol">
                                                <div class="qsublbl">Content Metrics</div>
                                                <div v-for="(content_header, i) in content_headers" :key="i">
                                                    <v-item v-slot="{ active, toggle }">
                                                        <v-checkbox
                                                            color="cyan darken-2"
                                                            on-icon="mdi-check-circle"
                                                            off-icon="mdi-checkbox-blank-circle-outline"
                                                            rounded
                                                            class="mt-0"
                                                            single-line
                                                            hide-details
                                                            v-model="content_header.selected"
                                                            :label="content_header.text"></v-checkbox>
                                                    </v-item>
                                                </div>
                                            </div>
                                        </v-col>
                                        <v-col class="pr-2" xl="3" md="3" sm="6" xs="12">
                                            <div class="selectionCol">
                                                <div class="qsublbl">Speed Metrics</div>
                                                <div v-for="(speed_header, i) in speed_headers" :key="i">
                                                    <v-item v-slot="{ active, toggle }">
                                                        <v-checkbox
                                                            color="cyan darken-2"
                                                            on-icon="mdi-check-circle"
                                                            off-icon="mdi-checkbox-blank-circle-outline"
                                                            rounded
                                                            class="mt-0"
                                                            single-line
                                                            hide-details
                                                            v-model="speed_header.selected"
                                                            :label="speed_header.text"></v-checkbox>
                                                    </v-item>
                                                </div>
                                            </div>
                                        </v-col>
                                    </v-row>
                                </v-item-group>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-slide-x-transition>
            </v-col>
        </v-row>
        <!-- <v-row>
            <v-col xl="8" md="10" sm="12" x-sm="12">
                <v-slide-x-transition>
                    <v-card class="quaroshadow" rounded style="height: 100%" v-if="project">
                        <div class="backsheet"></div>
                        <div class="px-5 py-3">
                            <div class="quarolbl mb-4">Selected Keywords:</div>
                            <v-chip-group color="primary" v-model="keywordfilter" mandatory multiple next-icon="mdi-chevron-right" prev-icon="mdi-chevron-left">
                                <v-chip v-for="topic in selectedTopics" :key="topic.keyword" filter close-icon="mdi-close-circle-outline" filter-icon="mdi-check-circle">
                                    {{ topic.keyword }}
                                </v-chip>
                            </v-chip-group>
                        </div>
                    </v-card>
                </v-slide-x-transition>
            </v-col>
        </v-row>
        <v-row>
            <v-col xl="8" md="10" sm="12" x-sm="12">
                <v-expansion-panels v-model="metrics_panel" flat accordion elevation-1 class="px-0" v-if="project">
                    <v-expansion-panel class="quaroshadow">
                        <div class="backsheet"></div>
                        <v-expansion-panel-header class="py-1">
                            <div class="quarolbl mb-1">Selected Metrics:</div>
                            <template v-slot:actions>
                                <v-icon color="primary"> mdi-chevron-down </v-icon>
                            </template>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            <v-item-group v-model="selectedHeaders" multiple>
                                <v-row>
                                    <v-col class="px-1" xl="3" md="3" sm="6" xs="12">
                                        <div class="selectionCol">
                                            <div class="qsublbl">Lighthouse Metrics</div>
                                            <div v-for="(lh_score_header, i) in lh_score_headers" :key="i">
                                                <v-item v-slot="{ active, toggle }">
                                                    <v-checkbox
                                                    color="cyan darken-2"
                                                        on-icon="mdi-check-circle"
                                                        off-icon="mdi-checkbox-blank-circle-outline"
                                                        rounded
                                                        class="mt-0"
                                                        single-line
                                                        hide-details
                                                        v-model="lh_score_header.selected"
                                                        :label="lh_score_header.text"></v-checkbox>
                                                </v-item>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="px-1" xl="3" md="3" sm="6" xs="12">
                                        <div class="selectionCol">
                                            <div class="qsublbl">Snippet Metrics</div>
                                            <div v-for="(snippet_header, i) in snippet_headers" :key="i">
                                                <v-item v-slot="{ active, toggle }">
                                                    <v-checkbox
                                                    color="cyan darken-2"
                                                        on-icon="mdi-check-circle"
                                                        off-icon="mdi-checkbox-blank-circle-outline"
                                                        rounded
                                                        class="mt-0"
                                                        single-line
                                                        hide-details
                                                        v-model="snippet_header.selected"
                                                        :label="snippet_header.text"></v-checkbox>
                                                </v-item>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="px-1" xl="3" md="3" sm="6" xs="12">
                                        <div class="selectionCol">
                                            <div class="qsublbl">Content Metrics</div>
                                            <div v-for="(content_header, i) in content_headers" :key="i">
                                                <v-item v-slot="{ active, toggle }">
                                                    <v-checkbox
                                                    color="cyan darken-2"
                                                        on-icon="mdi-check-circle"
                                                        off-icon="mdi-checkbox-blank-circle-outline"
                                                        rounded
                                                        class="mt-0"
                                                        single-line
                                                        hide-details
                                                        v-model="content_header.selected"
                                                        :label="content_header.text"></v-checkbox>
                                                </v-item>
                                            </div>
                                        </div>
                                    </v-col>
                                    <v-col class="px-1" xl="3" md="3" sm="6" xs="12">
                                        <div class="selectionCol">
                                            <div class="qsublbl">Speed Metrics</div>
                                            <div v-for="(speed_header, i) in speed_headers" :key="i">
                                                <v-item v-slot="{ active, toggle }">
                                                    <v-checkbox
                                                    color="cyan darken-2"
                                                        on-icon="mdi-check-circle"
                                                        off-icon="mdi-checkbox-blank-circle-outline"
                                                        rounded
                                                        class="mt-0"
                                                        single-line
                                                        hide-details
                                                        v-model="speed_header.selected"
                                                        :label="speed_header.text"></v-checkbox>
                                                </v-item>
                                            </div>
                                        </div>
                                    </v-col>
                                </v-row>
                            </v-item-group>
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-col>
            <v-col style="display: flex">
                <v-text-field
                    class="mt-auto"
                    dense
                    rounded
                    filled
                    v-model="search"
                    max-width="300px"
                    prepend-inner-icon="mdi-magnify"
                    label="Search competitors..."
                    single-line
                    hide-details></v-text-field>
            </v-col>
        </v-row> -->

        <v-slide-x-transition>
            <v-card class="quaroshadow mt-4 mb-5 rounded_card" v-if="project && !loading">
                <div class="backsheet"></div>
                <v-row>
                    <v-col class="flex">
                        <v-text-field
                            class="ml-auto mr-2"
                            v-model="search"
                            rounded
                            dense
                            style="max-width: 400px"
                            filled
                            append-icon="mdi-magnify"
                            label="Search..."
                            single-line
                            hide-details></v-text-field>
                    </v-col>
                </v-row>
                <v-data-table
                    id="cs_comp_table2"
                    :search="search"
                    fixed-header
                    height="60vh"
                    :headers="tableHeader"
                    :items="selectedCompetitors"
                    :custom-sort="customSort"
                    class="elevation-0 comp-table"
                    item-key="id">
                    <!-- header slot -->
                    <template v-for="(header_entry, index) in headers" v-slot:[`header.${header_entry.value}`]="{ header }">
                        <span :key="index">
                            <span v-bind:style="{ width: header.width }">
                                <!-- Adding tooltips to header -->
                                <v-tooltip max-width="250" color="primary" top :disabled="header.tooltip ? false : true">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            {{ header.text }}
                                            <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                                        </span>
                                    </template>
                                    <span>
                                        <v-row no-gutters>
                                            <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                                        </v-row>
                                        {{ header.tooltip }}
                                    </span>
                                </v-tooltip>
                            </span>
                        </span>
                    </template>

                    <!-- Table Body -->
                    <template v-slot:body="{ items }">
                        <tbody v-if="items.length">
                            <tr v-for="item in pinned_items" :key="item.url" :class="item.selected ? 'pinnedurl highlighted' : 'pinnedurl'">
                                <td v-for="(header, headerIndex) in tableHeader" :key="headerIndex">
                                    <!-- ------- -->
                                    <!-- position -->
                                    <!-- ------- -->
                                    <div v-if="header.selected && header.value === 'domain'" class="py-8">
                                        <v-row no-gutters>
                                            <v-col cols="1" style="display: flex">
                                                <v-icon small class="mr-2" color="primary">mdi-pin</v-icon>
                                                <!-- <v-btn x-small class="mr-5 my-auto" icon color="primary" @click="pinnRow(item)"><v-icon color="primary">mdi-pin</v-icon></v-btn> -->
                                            </v-col>
                                            <v-col cols="2" style="display: flex">
                                                <div class="fav_wrapper"><img class="fav" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item[header.value] + '&sz=24'" alt="" /></div>
                                            </v-col>
                                            <v-col cols="9">
                                                <div @click="openUrl(item[header.value] + item.path)" class="domain_td" style="font-weight: 600">{{ item[header.value] }}</div>
                                                <div @click="openUrl(item[header.value] + item.path)" class="path_td">{{ item.path }}</div></v-col
                                            >
                                        </v-row>
                                    </div>

                                    <!-- -------------- -->
                                    <!---- avg position ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'positions'" v-bind="(avgpos = getAVGpos(item))" style="display: flex">
                                        <div>
                                            <v-chip class="white--text" :color="avgpos.color"> {{ Math.trunc(avgpos.value) }}</v-chip>
                                        </div>
                                        <!-- <div style="display: flex" class="ml-auto pl-2">
                                            <div v-for="(topic, index) in selectedTopics" :key="index" class="" style="padding-left: 3px; display: flex" v-bind="(posData = getPos(topic, item))">
                                                <v-tooltip color="primary" top>
                                                    <template v-slot:activator="{ on }">
                                                        <div class="posbar mt-auto" v-on="on" :style="getPosBarStyle(topic, item)"></div>
                                                    </template>
                                                    <div><span style="font-weight: bold"> Keyword: </span>{{ posData.keyword }}</div>
                                                    <div><span style="font-weight: bold">Position: </span>{{ posData.position ? posData.position : "-" }}</div>
                                                </v-tooltip>

                                            </div>
                                        </div> -->
                                    </div>
                                    <!-- -------------- -->
                                    <!---- TITLE ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'title'" style="font-size: 12px">
                                        {{ item[header.value] }}
                                    </div>
                                    <!-- -------------- -->
                                    <!---- DESCRIPTION ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'description'" style="font-size: 12px">
                                        {{ item[header.value] }}
                                    </div>
                                    <!-- ------- -->
                                    <!---- PR----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'page_rank'">
                                        <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{
                                            item[header.value] ? item[header.value] : 0
                                        }}</v-chip>
                                        <span v-else>-</span>
                                    </div>
                                    <!-- ------ -->
                                    <!---- DR----->
                                    <!-- ------ -->
                                    <div v-else-if="header.selected && header.value === 'domain_rank'">
                                        <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{
                                            item[header.value] ? item[header.value] : 0
                                        }}</v-chip>
                                        <span v-else>-</span>
                                    </div>
                                    <div v-else-if="header.selected && header.value === 'actions'">
                                        <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon class="ml-auto">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list rounded dense>
                                                <!-- <v-list-item @click="$router.push('/content-project/setup?id=' + item._id)">
                                                    <v-icon small class="mr-2">mdi-pencil</v-icon><v-list-item-title>Edit</v-list-item-title>
                                                </v-list-item> -->
                                                <v-list-item @click="deleteComp(item)"> <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title> </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                    <div v-else-if="header.selected && header.type === 'lh_score'" :v-bind="(color = getLHScoreColor(item[header.value]))">
                                        <vue-ellipse-progress
                                            v-if="item[header.value]"
                                            :color="color.line"
                                            empty-color="white"
                                            :style="'font-weight: bold; color: ' + color.text"
                                            :empty-color-fill="color.emptyColorFill"
                                            :thickness="3"
                                            :empty-thickness="2"
                                            :legend="true"
                                            :size="40"
                                            :progress="Math.ceil(item[header.value])">
                                        </vue-ellipse-progress>
                                    </div>
                                    <div v-else-if="header.selected && header.value === 'wordCount'">{{ $helpers.formatNumber(item[header.value]) }}</div>
                                    <div v-else-if="header.selected">{{ item[header.value] }}</div>
                                </td>
                            </tr>

                            <tr v-for="(item, itemIndex) in items" :key="itemIndex" :class="item.selected ? 'highlighted' : ''">
                                <td v-for="(header, headerIndex) in tableHeader" :key="headerIndex">
                                    <!-- ------- -->
                                    <!-- position -->
                                    <!-- ------- -->
                                    <div v-if="header.selected && header.value === 'domain'" class="py-4">
                                        <v-row no-gutters>
                                            <!-- <v-col cols="1" style="display: flex">
                                                <v-btn x-small class="mr-5 my-auto" icon :color="item.pinned ? 'primary' : ''" @click="pinnRow(item)"><v-icon small>mdi-pin</v-icon></v-btn>
                                            </v-col> -->
                                            <v-col cols="2" style="display: flex">
                                                <div class="fav_wrapper"><img class="fav" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item[header.value] + '&sz=24'" alt="" /></div>
                                            </v-col>
                                            <v-col cols="9">
                                                <div @click="openUrl(item[header.value] + item.path)" class="domain_td">{{ item[header.value] }}</div>
                                                <div @click="openUrl(item[header.value] + item.path)" class="path_td">{{ item.path }}</div></v-col
                                            >
                                        </v-row>
                                    </div>

                                    <!-- -------------- -->
                                    <!---- avg position ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'positions'" v-bind="(avgpos = getAVGpos(item))" style="display: flex">
                                        <div>
                                            <v-chip class="white--text" :color="avgpos.color"> {{ avgpos.value }}</v-chip>
                                        </div>
                                        <!-- <div style="display: flex" class="ml-auto pl-2">
                                            <div v-for="(topic, index) in selectedTopics" :key="index" class="" style="padding-left: 3px; display: flex" v-bind="(posData = getPos(topic, item))">
                                                <v-tooltip color="primary" top>
                                                    <template v-slot:activator="{ on }">
                                                        <div class="posbar mt-auto" v-on="on" :style="getPosBarStyle(topic, item)"></div>
                                                    </template>
                                                    <div><span style="font-weight: bold"> Keyword: </span>{{ posData.keyword }}</div>
                                                    <div><span style="font-weight: bold">Position: </span>{{ posData.position ? posData.position : "-" }}</div>
                                                </v-tooltip>

                                            </div>
                                        </div> -->
                                    </div>
                                    <!-- -------------- -->
                                    <!---- TITLE ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'title'" style="font-size: 12px">
                                        {{ item[header.value] }}
                                    </div>
                                    <!-- -------------- -->
                                    <!---- DESCRIPTION ----->
                                    <!-- -------------- -->
                                    <div v-else-if="header.selected && header.value === 'description'" style="font-size: 12px">
                                        {{ item[header.value] }}
                                    </div>
                                    <!-- ------- -->
                                    <!---- PR----->
                                    <!-- ------- -->
                                    <div v-else-if="header.selected && header.value === 'page_rank'">
                                        <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{
                                            item[header.value] ? item[header.value] : 0
                                        }}</v-chip>
                                        <span v-else>-</span>
                                    </div>
                                    <!-- ------ -->
                                    <!---- DR----->
                                    <!-- ------ -->
                                    <div v-else-if="header.selected && header.value === 'domain_rank'">
                                        <v-chip v-if="item[header.value] >= 0" small class="white--text" :color="getRankColor(item[header.value])">{{
                                            item[header.value] ? item[header.value] : 0
                                        }}</v-chip>
                                        <span v-else>-</span>
                                    </div>
                                    <div v-else-if="header.selected && header.value === 'actions'">
                                        <v-menu offset-y rounded="lg" color="primary" transition="slide-y-transition" bottom>
                                            <template v-slot:activator="{ on }">
                                                <v-btn v-on="on" icon class="ml-auto">
                                                    <v-icon>mdi-dots-vertical</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list rounded dense>
                                                <!-- <v-list-item @click="$router.push('/content-project/setup?id=' + item._id)">
                                                    <v-icon small class="mr-2">mdi-pencil</v-icon><v-list-item-title>Edit</v-list-item-title>
                                                </v-list-item> -->
                                                <v-list-item @click="deleteComp(item)"> <v-icon small class="mr-2">mdi-delete</v-icon><v-list-item-title>Delete</v-list-item-title> </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                    <div v-else-if="header.selected && header.type === 'lh_score'" :v-bind="(color = getLHScoreColor(item[header.value]))">
                                        <span v-if="project.competitors_status == 'failed'">-</span>
                                        <span v-else-if="project.competitors_status == 'loading'">
                                            <v-progress-circular size="24" width="2" indeterminate color="grey"></v-progress-circular>
                                        </span>
                                        <vue-ellipse-progress
                                            v-else-if="item[header.value]"
                                            :color="color.line"
                                            empty-color="white"
                                            :style="'font-weight: bold; color: ' + color.text"
                                            :empty-color-fill="color.emptyColorFill"
                                            :thickness="3"
                                            :empty-thickness="2"
                                            :legend="true"
                                            :size="40"
                                            :progress="Math.ceil(item[header.value])">
                                        </vue-ellipse-progress>
                                    </div>
                                    <div v-else-if="header.selected && header.value === 'wordCount'">{{ $helpers.formatNumber(item[header.value]) }}</div>
                                    <div v-else-if="header.selected">{{ item[header.value] }}</div>
                                </td>
                            </tr>
                        </tbody>
                    </template>
                </v-data-table>
            </v-card>
        </v-slide-x-transition>
    </div>
</template>

<script>
    import AddUrlDialog from "@/components/content-suite/AddUrlDialog.vue"
    import ComponentHeader from "../../components/content-suite/ComponentHeader.vue"
    export default {
        components: {
            AddUrlDialog,
            ComponentHeader
        },
        data() {
            return {
                addURLLoading: false,
                loading: true,
                notification: { show: false, title: "Project URL added", text: "The URL has been added successfully! After a few minutes all data will be fetched." },
                classicnotification: { show: false, title: "Project URL added", text: "The URL has been added successfully! After a few minutes all data will be fetched." },
                addURLDialog: { show: false },
                preSelections: [
                    { selected: false, label: "Top 10" },
                    { selected: false, label: "Top 20" },
                    { selected: false, label: "Top 30" },
                    { selected: false, label: "Top 40" },
                    { selected: true, label: "All" }
                ],
                reffilter: 0,
                keywordfilter: [],
                search: "",
                metrics_panel: 0,
                selectedCompetitors: [],
                rankColors: [
                    "green-accent-4",
                    "green darken-2",
                    "green darken-2",
                    "green darken-1",
                    "light-green darken-2",
                    "lime darken-2",
                    "yellow darken-2",
                    "amber darken-2",
                    "orange darken-2",
                    "deep-orange darken-2"
                ],
                positionColors: [
                    "green darken-2",
                    "green darken-1",
                    "light-green darken-2",
                    "lime darken-2",
                    "yellow darken-2",
                    "amber darken-2",
                    "orange darken-2",
                    "deep-orange darken-2",
                    "red darken-2",
                    "red darken-4"
                ],
                positionColors2: ["#388E3C", "#43A047", "#689F38", "#AFB42B", "#FBC02D", "#FFA000", "#F57C00", "#E64A19", "#D32F2F", "#B71C1C"],
                project: null,
                headers: [
                    {
                        text: "Competitor",
                        selectable: false,
                        selected: true,
                        value: "domain", //domain and path
                        width: "20",
                        sortable: true,
                        width: 350
                    },
                    {
                        text: "Ø Position",
                        selectable: false,
                        selected: true,
                        value: "positions",
                        sortable: true,
                        width: 100,
                        tooltip: "The average position of the competitor website in search engine results pages (SERPs) for your target keywords."
                    },
                    {
                        text: "Title",
                        selectable: true,
                        selected: true,
                        type: "snippet",
                        value: "title",
                        width: 200,
                        sortable: true,
                        tooltip: "The title tag of the competitor website's landing page, which is displayed in SERPs."
                    },
                    {
                        text: "Title Length",
                        selectable: true,
                        selected: true,
                        type: "snippet",
                        value: "title_length",
                        sortable: true,
                        tooltip: "The character count of the title tag on the competitor website's landing page."
                    },
                    {
                        text: "Description",
                        selectable: true,
                        selected: true,
                        type: "snippet",
                        value: "description",
                        width: 350,
                        sortable: false,
                        tooltip: "The meta description tag of the competitor website's landing page, which is displayed in SERPs."
                    },
                    {
                        text: "Description Length",
                        selectable: true,
                        selected: true,
                        type: "snippet",
                        value: "description_length",
                        sortable: true,
                        tooltip: "The character count of the meta description tag on the competitor website's landing page."
                    },
                    {
                        text: "H1",
                        selectable: true,
                        selected: true,
                        type: "content",
                        value: "h1_count",
                        sortable: true,
                        tooltip: "The number of H1 tags on the competitor website's landing page, which are typically used for the main heading of the page."
                    },
                    {
                        text: "H2",
                        selectable: true,
                        selected: true,
                        type: "content",
                        value: "h2_count",
                        sortable: true,
                        tooltip: "The number of H2 tags on the competitor website's landing page, which are typically used for subheadings on the page."
                    },
                    {
                        text: "H3",
                        selectable: true,
                        selected: true,
                        type: "content",
                        value: "h3_count",
                        sortable: true,
                        tooltip: "The number of H3 tags on the competitor website's landing page, which are typically used for sub-subheadings on the page."
                    },
                    {
                        text: "Word Count",
                        selectable: true,
                        selected: true,
                        type: "content",
                        value: "wordCount",
                        sortable: true,
                        tooltip: "The number of words on the competitor website's landing page. Based on the extracted main content."
                    },
                    {
                        text: "Page Rank",
                        selectable: true,
                        selected: true,
                        value: "page_rank",
                        sortable: true,
                        tooltip:
                            "Page Rank is an algorithm designed to measure the importance of a webpage based on the quality and quantity of backlinks. High Values are perceived as more authoritative by Google and rank better"
                    },
                    {
                        text: "Domain Rank",
                        selectable: true,
                        selected: true,
                        value: "domain_rank",
                        sortable: true,
                        tooltip:
                            "Domain Rank is an algorithm designed to measure the importance of a domain based on the quality and quantity of backlinks. High Values are perceived as more authoritative by Google and rank better"
                    },
                    {
                        text: "Performance Score",
                        selectable: true,
                        selected: true,
                        type: "lh_score",
                        value: "performance_score",
                        sortable: true,
                        tooltip: "A score that evaluates how well the competitor website performs in terms of loading speed, responsiveness, and other technical aspects that affect user experience."
                    },
                    {
                        text: "SEO Score",
                        selectable: true,
                        selected: true,
                        type: "lh_score",
                        value: "seo_score",
                        sortable: true,
                        tooltip: "A score that evaluates how well the competitor website is optimized for search engines, including factors like on-page optimization."
                    },
                    {
                        text: "Accessibility Score",
                        selectable: true,
                        selected: true,
                        type: "lh_score",
                        value: "accessibility_score",
                        sortable: true,
                        tooltip:
                            "A score that evaluates how well the competitor website complies with accessibility standards, making it easy for users with disabilities to access and use the website."
                    },
                    {
                        text: "Best-Practices Score",
                        selectable: true,
                        selected: true,
                        type: "lh_score",
                        value: "best_practices_score",
                        sortable: true,
                        tooltip: "A score that evaluates how well the competitor website follows industry best practices for website design, development, and optimization."
                    },
                    {
                        text: "Speed Index",
                        selectable: true,
                        selected: true,
                        type: "speed",
                        value: "speed_index",
                        sortable: true,
                        tooltip:
                            "A metric that measures the time it takes for the visible content of a webpage to be displayed on the screen after a user requests it. Lower values indicate faster loading times and better user experience."
                    },
                    {
                        text: "First Contentful Paint",
                        selectable: true,
                        selected: true,
                        value: "first_contentful_paint",
                        type: "speed",
                        sortable: true,
                        tooltip:
                            "First Contentful Paint (FCP) measures how long it takes for the first piece of content to be displayed on a web page. A faster FCP means that users can start consuming the content of the page sooner."
                    },
                    {
                        text: "Time To Interactive",
                        selectable: true,
                        selected: true,
                        value: "interactive",
                        type: "speed",
                        sortable: true,
                        tooltip:
                            "Time To Interactive (TTI) measures the time it takes for a web page to become fully interactive, meaning that users can interact with all the elements on the page. A shorter TTI leads to a better user experience."
                    },
                    {
                        text: "Largest Contentful Paint",
                        selectable: true,
                        selected: true,
                        type: "speed",
                        value: "largest_contentful_paint",
                        sortable: true,
                        tooltip:
                            "Largest Contentful Paint (LCP) measures when the largest visible element is fully loaded, signaling that the main content is available. A faster LCP improves user experience, with under 2.5 seconds considered optimal."
                    }
                    // {
                    //     text: "",
                    //     selectable: false,
                    //     selected: true,
                    //     value: "actions",
                    //     sortable: true,
                    //     align: "end",
                    //     width: 50
                    // }
                ],
                //share
                sharedCompanyId: null,
                sharedId: null,
                sharedUserId: null,
                shared_extern: false,
                share_data: { briefing: false, editor: false, wdfidf: false, competitors: false },
                auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH)
            }
        },
        methods: {
            getSrc() {
                if (this.company_id == "1547") {
                    return require("@/assets/quaro-q-obi.svg")
                } else {
                    return require("@/assets/quaro-q.svg")
                }
            },
            openUrl(url) {
                window.open("https://" + url, "_blank")
            },
            customSort(items, index, isDesc) {
                let sortedItems = this.$helpers.globalSort(items, index, isDesc, this.headers, this.selectedTopics)
                console.log(sortedItems)
                return sortedItems
            },
            /**
             * Fetches Project Data by id
             * @param {*} id
             */
            async fetchProject(id) {
                return new Promise(async (resolve, reject) => {
                    const url = process.env.VUE_APP_APIURL + "/cs/get/project"
                    let post_data = {
                        company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                        id: this.shared_extern ? this.sharedId : id
                    }
                    let response_data = await this.$helpers.axiosPost(url, post_data)
                    this.project = response_data.data.project
                    console.log(this.project.competitors_status)

                    if (this.project.competitors_status == "loading") {
                        this.classicnotification = this.$helpers.createNotification(
                            "We are currently fetching page speed data of your competitors. Please stand by!",
                            "mdi-alert-circle-outline",
                            "#FCBA03"
                        )
                    }
                    if (this.project.competitors_status == "failed") {
                        this.refetchCompetitors(this.project)
                    }
                    this.share_data = {
                        briefing: response_data.data.project.briefing_shared_extern,
                        editor: response_data.data.project.editor_shared_extern,
                        wdfidf: response_data.data.project.wdfidf_shared_extern,
                        competitors: response_data.data.project.competitors_shared_extern
                    }
                    resolve(response_data.data.project.competitors_shared_extern)
                    if (this.shared_extern && !response_data.data.project.competitors_shared_extern) return

                    this.selectedTopics.map((topic, index) => {
                        this.keywordfilter.push(index)
                    })
                    console.log(this.project.competitors)

                    this.selectedCompetitors = this.processData([...this.project.competitors])
                    console.log("this.selectedCompetitors", this.selectedCompetitors)
                })
            },
            refetchCompetitors(project) {
                this.classicnotification = this.$helpers.createNotification("Refetching competitor data. Please stand by!", "mdi-check-circle-outline", "primary")
                project.competitors_status = "loading"
                const url = process.env.VUE_APP_APIURL + "/cs/add/lh-report"
                let post_data = {
                    company_id: localStorage.company_id,
                    project: project,
                    socket_id: this.$socket.id
                }
                this.$helpers.axiosPost(url, post_data)
            },
            processData(comps) {
                let competitors = comps ? comps : this.competitors
                console.log("this.competitors", this.competitors)

                let processedComps = competitors.map((competitor) => {
                    let row = {}
                    row.competitor = competitor.competitor
                    row.url = competitor.url
                    row.domain = competitor.domain ? competitor.domain : new URL(competitor.url).hostname
                    row.path = competitor.path ? competitor.path : new URL(competitor.url).pathname
                    row.positions = competitor.positions ? competitor.positions : competitor.rankings
                    // this.selectedTopics.map((topic) => {
                    //     let exists = competitor.positions.findIndex((entry) => entry.keyword == topic.keyword)
                    //     if (exists == -1) {
                    //         row.positions.push({ keyword: topic, position: null })
                    //     }
                    // })
                    row.title = competitor.title
                    row.description = competitor.description
                    row.title_length = competitor.title.length
                    row.description_length = competitor.description ? competitor.description.length : 0
                    row.page_rank = competitor.page_rank
                    row.domain_rank = competitor.domain_rank
                    row.wordCount = competitor.wordCount
                    row.h1_count = competitor.h1_count
                    row.h2_count = competitor.h2_count
                    row.h3_count = competitor.h3_count
                    // add lighthouse data
                    if (competitor.lighthouse) {
                        let performanceData = competitor.lighthouse.categories.find((entry) => entry.category == "performance")
                        let seoData = competitor.lighthouse.categories.find((entry) => entry.category == "seo")
                        let accessibilityData = competitor.lighthouse.categories.find((entry) => entry.category == "accessibility")
                        let bpData = competitor.lighthouse.categories.find((entry) => entry.category == "best-practices")
                        row.performance_score = performanceData.score * 100
                        row.seo_score = seoData.score * 100
                        row.accessibility_score = accessibilityData.score * 100
                        row.best_practices_score = bpData.score * 100
                        performanceData.metrics[0].map((metric) => (row[metric.metric] = metric.value))
                        console.log(row)

                        row.speed_index = performanceData.metrics[0].find((entry) => entry.metric == "speed-index").value
                        row.first_contentful_paint = performanceData.metrics[0].find((entry) => entry.metric == "first-contentful-paint").value
                        row.interactive = performanceData.metrics[0].find((entry) => entry.metric == "interactive").value
                        row.largest_contentful_paint = performanceData.metrics[0].find((entry) => entry.metric == "largest-contentful-paint-element")
                            ? performanceData.metrics[0].find((entry) => entry.metric == "largest-contentful-paint-element").value
                            : null
                    }
                    console.log(row)
                    if (competitor.averagePosition) {
                        row.averagePosition = competitor.averagePosition
                    }
                    return row
                })
                if (!comps) {
                    processedComps = processedComps.filter((comp) => comp.positions.find((p) => this.selectedKeywords.includes(p.keyword)))
                }

                return processedComps
                console.log(this.selectedCompetitors)
            },
            getRankColor(rank) {
                // #2DFF3D #6AEB50 #9EDD4E #D1EB57 #E6D05E #EDA653 #EDA653 #EB2727 #B80101 #8D0202
                // let colors = ["#00A30E", "#00B80F", "#9EDD4E", "#DDE236", "#E6D05E", "#F4BC7B", "#EDA653", "#EB2727", "#B80101", "#8D0202"]
                let helper = 9 - Math.ceil(rank / 100)
                return this.rankColors[helper]
            },
            getAVGpos(item) {
                let sum = 0
                console.log(item)

                if (item.averagePosition) {
                    let colorIndex = Math.ceil(item.averagePosition / 10)
                    let color = this.positionColors[colorIndex] ? this.positionColors[colorIndex] : this.positionColors[this.positionColors.length - 1]
                    return { value: item.averagePosition.toFixed(1), color: color }
                }
                if (item.positions.length) {
                    this.selectedTopics.map((t) => {
                        let e = item.positions.find((e) => e.keyword == t.keyword)
                        sum += e ? e.position : 100
                    })
                    // let sum = item.positions.reduce((pv, cv) => pv + cv.position, 0)
                    let avgpos = sum / this.selectedTopics.length
                    item.avgpos = avgpos

                    let colorIndex = Math.ceil(avgpos / 10)
                    let color = this.positionColors[colorIndex] ? this.positionColors[colorIndex] : this.positionColors[this.positionColors.length - 1]
                    return { value: avgpos.toFixed(0), color: color }
                }
                return { value: "-", color: "" }
            },
            selectKw(topic) {
                console.log(topic.anal_select)
                if (topic.anal_select === undefined) {
                    console.log("unde")

                    topic.anal_select = false
                } else {
                    console.log("switch")
                    topic.anal_select = !topic.anal_select
                }
            },
            async deleteComp(item) {
                console.log(item)
                const url = process.env.VUE_APP_APIURL + "/cs/remove/competitor"
                let post_data = {
                    company_id: this.shared_extern ? this.sharedCompanyId : localStorage.company_id,
                    project_id: this.project._id,
                    competitor: item
                }
                let response_data = await this.$helpers.axiosPost(url, post_data)
                console.log(response_data.data)
                this.classicnotification = this.$helpers.createNotification("Competitor has been removed!", "mdi-check-circle-outline", "primary")
                let index = this.competitors.findIndex((c) => c.url == item.url)
                this.competitors.splice(index, 1)
                this.selectedCompetitors = this.processData()
            },
            getLHScoreColor(score) {
                let emptyColorFill = {
                    radial: false,
                    colors: [
                        {
                            color: "#c7ffcd",
                            offset: "100",
                            opacity: "0.1"
                        }
                    ]
                }
                if (!score) {
                    return { line: "#f00", text: "#f00", emptyColorFill: emptyColorFill }
                }
                let ranges = [
                    { min: 0, max: 49, line: "#f33", text: "#c00" },
                    { min: 50, max: 89, line: "#fa3", text: "#c33300" },
                    { min: 90, max: 100, line: "#0c6", text: "#080" }
                ]
                let color = {}
                ranges.map((range) => {
                    if (score >= range.min) {
                        color = range
                    }
                })
                emptyColorFill.colors[0].color = color.line
                return { line: color.line, text: color.text, emptyColorFill: emptyColorFill }
            },
            getPosBarStyle(topic, comp) {
                let position = comp.positions.find((entry) => entry.keyword == topic.keyword)
                let style = ""
                if (position && position.position && position.position > 20) {
                    style = "background-color: " + "#009f89" + " !important; height: " + "7" + "px;"
                } else if (position && position.position) {
                    let colorIndex = Math.ceil(position.position / 3)
                    let color = this.positionColors2[colorIndex] ? this.positionColors2[colorIndex] : this.positionColors2[this.positionColors2.length - 1]
                    let height = (22 - position.position) * 1.5 > 30 ? 30 : (22 - position.position) * 1.5
                    style = "background-color: " + "#009f89" + " !important; height: " + height + "px;"
                } else {
                    style = "background-color: " + "#E57373" + " !important; height: " + "7" + "px;"
                }
                return style

                // let position = comp.positions.find((entry) => entry.keyword == topic.keyword)
                // let style = ""
                // if (position && position.position) {
                //     let colorIndex = Math.ceil(position.position / 3)
                //     let color = this.positionColors2[colorIndex] ? this.positionColors2[colorIndex] : this.positionColors2[this.positionColors2.length - 1]
                //     let height = (22 - position.position) * 1.5 > 30 ? 30 : (22 - position.position) * 1.5
                //     style = "background-color: " + "#009f89" + " !important; height: " + height + "px;"
                // } else {
                //     style = "background-color: " + "#39baa3" + " !important; height: " + "7" + "px;"
                // }
                // return style
            },
            getPos(topic, comp) {
                let pos = comp.positions.find((entry) => entry.keyword == topic.keyword)
                return pos ? pos : { keyword: topic.keyword, position: null }
            },
            pinnRow(item) {
                item.pinned = !item.pinned
                this.selectedCompetitors.push({})
                this.selectedCompetitors.pop()
            },
            fetchCompanyStatus() {
                return new Promise(async (resolve, reject) => {
                    this.$http.post(process.env.VUE_APP_APIURL + "/company/status", { company_id: this.sharedCompanyId }, { headers: { Authorization: this.auth } }).then(function (response) {
                        // console.log(response.body)

                        if (response.body[0].status !== "test_ended" || response.body[0].status !== "inactive") {
                            resolve(true)
                        } else {
                            resolve(false)
                        }
                    })
                })
            }
        },
        watch: {
            keywordfilter(val) {
                let keywords = (this.selectedCompetitors = this.processData())
            }
        },
        computed: {
            pinned_items() {
                console.log(this.project)
                if (this.reffilter === undefined) {
                    return []
                }
                let main_url = this.selectedCompetitors.filter((item) => {
                    try {
                        var urlObj = new URL(item.url)
                        var compareURL = urlObj.origin + urlObj.pathname
                        var uObj = new URL(this.project.url)
                        var compareU = uObj.origin + uObj.pathname
                        if (compareURL == compareU) {
                            return true
                        } else {
                            return false
                        }
                    } catch (error) {
                        return false
                    }
                })

                if (this.project.comparissonURL && main_url.length) {
                    this.project.comparissonURL.positions = main_url[0].positions
                    return this.processData([this.project.comparissonURL])
                } else if (this.project.comparissonURL) {
                    return this.processData([this.project.comparissonURL])
                } else if (main_url.length) {
                    return main_url
                } else {
                    return []
                }
            },
            selectedTopics() {
                if (this.project.cluster) return this.project.cluster.children.length ? this.project.cluster.children : this.project.cluster.keywords
                return this.project.topics.filter((topic) => topic.selected)
            },
            tableHeader() {
                return this.headers.filter((header) => header.selected)
            },
            selectHeaders() {
                return this.headers.filter((header) => header.selectable)
            },
            lh_score_headers() {
                return this.headers.filter((header) => header.selectable && header.type == "lh_score")
            },
            snippet_headers() {
                return this.headers.filter((header) => header.selectable && header.type == "snippet")
            },
            content_headers() {
                return this.headers.filter((header) => header.selectable && header.type == "content")
            },
            speed_headers() {
                return this.headers.filter((header) => header.selectable && header.type == "speed")
            },
            selectedKeywords() {
                return this.selectedTopics.filter((topic, index) => this.keywordfilter.includes(index)).map((topic) => (topic.keyword ? topic.keyword : topic.name))
            },
            competitors() {
                let selectedComps = this.project.competitors.filter((comp) => comp.selected)

                return selectedComps.filter((comp) => {
                    let status = false
                    comp.positions
                        ? comp.positions.map((position) => {
                              if (this.selectedKeywords.includes(position.keyword)) {
                                  status = true
                              }
                          })
                        : comp.rankings.map((position) => {
                              if (this.selectedKeywords.includes(position.keyword)) {
                                  status = true
                              }
                          })
                    if (comp.url == this.project.url) {
                        status = true
                    }
                    return status
                })
            },
            selectedHeaders: {
                set(val) {
                    console.log("selectedHEader", val.length)
                    this.headers.map((header) => {
                        if (header.value !== "actions") {
                            let index = val.findIndex((v) => v.value === header.value)
                            if (index === -1) {
                                header.selected = false
                            } else {
                                console.log("header selected")
                                header.selected = true
                            }
                        }
                    })
                    console.log("tableHeader", this.tableHeader.length)
                },
                get() {
                    return this.headers.filter((header) => header.selectable && header.selected)
                }
            },
            company_id() {
                return this.CryptoJS.AES.decrypt(localStorage.company_id, process.env.VUE_APP_ENCRYPTION_PW).toString(this.CryptoJS.enc.Utf8)
            }
        },
        beforeRouteEnter(to, from, next) {
            next(async (vm) => {
                console.log("BLUBV")

                if (vm.$route.params.share_id) {
                    let encrypted = ""
                    try {
                        encrypted = await vm.CryptoJS.AES.decrypt(vm.$route.params.share_id.replace(new RegExp("Por21Ld", "g"), "/"), process.env.VUE_APP_ENCRYPTION_PW).toString(vm.CryptoJS.enc.Utf8)
                    } catch (error) {
                        console.log(error)
                        vm.classicnotification = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = true
                        return
                    }

                    let encryptedSplits = encrypted.split("|")
                    vm.sharedCompanyId = encryptedSplits[0]
                    vm.sharedId = encryptedSplits[1]
                    if (!vm.sharedId || !vm.sharedCompanyId) {
                        vm.classicnotification = vm.$helpers.createNotification("Sorry, we could not find what you are looking for!", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = true
                        return
                    }

                    vm.shared_extern = true

                    let informations = await Promise.all([vm.fetchProject(vm.$route.params.project_id), vm.fetchCompanyStatus()])
                    let shared = informations[0]
                    let companyactive = informations[1]
                    if (shared && companyactive) {
                        if (localStorage.sharedUserId && localStorage.sharedUserId.includes("extern_")) {
                            vm.sharedUserId = localStorage.sharedUserId
                        } else {
                            vm.sharedUserId = "extern_" + Math.random().toString(36).slice(2)
                            localStorage.sharedUserId = vm.sharedUserId
                        }
                        await vm.fetchProject(vm.$route.params.project_id)
                        vm.loading = false
                    } else {
                        vm.shared_extern = false

                        vm.classicnotification = vm.$helpers.createNotification("The Competitor Analysis is not shared.", "mdi-alert-circle-outline", "#fcba03")
                        vm.loading = true
                    }
                } else {
                    if (!vm.$store.state.content_suite) {
                        vm.$router.push("/")
                    } else {
                        await vm.fetchProject(vm.$route.params.project_id)
                        vm.loading = false

                        // vm.checkFocus()
                    }
                }
            })
        },
        mounted() {
            // this.fetchProject(this.$route.params.project_id)
            this.$socket.on("csAddUrl", (data) => {
                console.log("ADD-")
                if (data.url_updated) {
                    this.fetchProject(this.$route.params.project_id)
                    this.addURLLoading = false
                } else {
                    this.notification = this.$helpers.createNotification("Something went wrong adding your URL! Please try again or contact support.", "mdi-alert", "orange darken-2")
                    this.url_loading = false
                }
            })
        }
    }
</script>

<style lang="scss">
    .tabletext {
        font-size: 18px;
    }

    #cs_comp_table2 th {
        white-space: nowrap;
        text-transform: uppercase;
        font-weight: 500;
    }
    // #cs_comp_table2 table > tbody > tr > td:last-child {
    //     background: var(--v-keywordcol-base) !important;
    //     border-left: 2px solid var(--v-primary-base);
    // }
    // #cs_comp_table2 table > tbody > tr > td:last-child,
    // #cs_comp_table2 table > thead > tr > th:last-child {
    //     position: sticky !important;
    //     position: -webkit-sticky !important;
    //     right: 0 !important;
    // }
    .domain_td {
        font-size: 16px;
        /* font-weight: 0 !important; */
        &:hover {
            cursor: pointer;
        }
    }
    .path_td {
        font-size: 12px;
        color: blue;
        &:hover {
            cursor: pointer;
        }
    }
    .fav_wrapper {
        display: flex;
        border: 2px solid rgb(228, 228, 228);
        width: 32px;
        background: white;
        height: 32px;
        margin-top: auto;
        margin-bottom: auto;
        border-radius: 5px;
    }
    .fav {
        border-radius: 5px;
        margin: auto;
    }

    .questionIcon {
        padding-bottom: 3px;
    }
    #cs_comp_table2 table > thead {
        // background: var(--v-keywordcol-base) !important;
        border-radius: 20px;
    }
    #cs_comp_table2 table > tbody > tr.pinnedurl {
        position: sticky !important;
        position: -webkit-sticky !important;
        top: 48px !important;
        z-index: 3 !important;
        background: var(--v-keywordcol-base) !important;
    }
    #cs_comp_table2 table > tbody > tr.pinnedurl > td {
        border-bottom: 4px solid rgb(202, 202, 202);
    }
    #cs_comp_table2 table > tbody > tr > td:nth-child(2) {
        z-index: 2 !important;
    }
    #cs_comp_table2 table > thead > tr > th:nth-child(2) {
        z-index: 3 !important;
    }
    #cs_comp_table2 table > tbody > tr > td:nth-child(1) {
        z-index: 2 !important;
    }
    #cs_comp_table2 table > thead > tr > th:nth-child(1) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
        z-index: 3 !important;
        // background: var(--v-keywordcol-base) !important;
    }
    #cs_comp_table2 table > tbody > tr > td:nth-child(1),
    #cs_comp_table2 table > tbody > tr > th:nth-child(1) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 0 !important;
        background: var(--v-keywordcol-base) !important;
    }
    #cs_comp_table2 table > tbody > tr > td:nth-child(2),
    #cs_comp_table2 table > thead > tr > th:nth-child(2) {
        position: sticky !important;
        position: -webkit-sticky !important;
        left: 350px !important;
        // background: var(--v-keywordcol-base) !important;
        border-right: 1.5px solid rgb(218, 218, 218);
    }
    #cs_comp_table2 table > tbody > tr > td:nth-child(2) {
        background: var(--v-keywordcol-base) !important;
    }
    .posbar {
        border-radius: 3px;
        background-color: var(--v-primary-base);
        width: 6px;
        margin-top: auto;
        // height: 20px;
    }
    // .posbarback {
    //     display: absolute;
    //     bottom: 0;
    //     border-radius: 2px;
    //     left: 0;
    //     background-color: var(--v-primary-base);
    //     opacity: 0.5;
    //     width: 4px;
    //     height: 15px;
    // }
    #cs_comp_table2 {
        border-radius: 10px !important;
    }
    #cs_comp_table2 > div.v-data-table__wrapper {
        border-radius: 10px 10px 0 0 !important;
    }
</style>
