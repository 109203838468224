<template>
    <div id="contentcontainter" class="standard-mode">
        <v-snackbar top v-model="notification.show" :color="notification.color">
            <v-icon>{{ notification.icon }}</v-icon
            ><span class="font-weight-bold pl-2"> {{ notification.message }} </span>
        </v-snackbar>
        <div style="max-width: 1200px; margin: 5vh auto" class="">
            <v-row class="mb-5">
                <div class="pa-5">
                    <div class="sectiontitle">
                        <h2>For every requirement, the right solution.</h2>
                    </div>
                    <div class="subtext py-2">Individual packages, only pay for what you really need. The right package for every company size. No hidden costs and no cancellation period.</div>
                    <!-- <div class="note">
                        <p>You want an individual package? Contact the support: <v-chip>contact@quaro.io</v-chip></p>
                    </div> -->
                </div>
            </v-row>
            <div class="d-flex mb-5 mt-5">
                <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                    <v-btn width="150px" outlined color="darkgreen"> monthly </v-btn>
                    <v-btn width="150px" outlined color="darkgreen"> yearly </v-btn>
                    <v-badge bordered color="error" overlap>
                        <template v-slot:badge>-10%</template>
                    </v-badge>
                </v-btn-toggle>
            </div>

            <div class="new_pricing mb-10">
                <v-container>
                    <v-row>
                        <v-col cols="3"></v-col>

                        <v-col cols="3">
                            <div class="text-center">
                                <img style="max-width: 120px" :src="rocket01" id="rocket01" />
                                <br />
                                <h3>
                                    <span class="title-quaro">Quaro</span>
                                    <span class="title-after">Starter</span>
                                </h3>
                            </div>
                        </v-col>
                        <v-col cols="3"
                            ><div class="text-center">
                                <img style="max-width: 120px" :src="rocket02" />
                                <br />
                                <h3>
                                    <span class="title-quaro">Quaro</span>
                                    <span class="title-after">{{ tabItems[1].name }}</span>
                                </h3>
                            </div>
                        </v-col>
                        <v-col cols="3"
                            ><div class="text-center">
                                <img style="max-width: 120px" :src="rocket03" />
                                <br />
                                <h3>
                                    <span class="title-quaro">Quaro</span>
                                    <span class="title-after">{{ tabItems[2].name }}</span>
                                </h3>
                            </div></v-col
                        >
                    </v-row>
                    <v-row class="pricing_seperator" style="height: 5px"></v-row>
                    <v-row>
                        <v-col cols="3"></v-col>
                        <v-col cols="3" class="d-flex"
                            ><v-chip class="mx-auto" style="background-color: #ffe10021 !important" outlined color="#c6b20a"
                                ><v-icon class="mr-1">mdi-account-circle</v-icon>Unlimited Accounts</v-chip
                            ></v-col
                        >
                        <v-col cols="3" class="d-flex"
                            ><v-chip class="mx-auto" style="background-color: #ffe10021 !important" outlined color="#c6b20a"
                                ><v-icon class="mr-1">mdi-account-circle</v-icon>Unlimited Accounts</v-chip
                            ></v-col
                        >
                        <v-col cols="3" class="d-flex"
                            ><v-chip class="mx-auto" style="background-color: #ffe10021 !important" outlined color="#c6b20a"
                                ><v-icon class="mr-1">mdi-account-circle</v-icon>Unlimited Accounts</v-chip
                            ></v-col
                        >
                    </v-row>
                    <v-row class="pricing_seperator">
                        <v-col cols="3" style="font-weight: 600"> Research Suite</v-col>
                        <v-col cols="3" class="d-flex"> <v-checkbox v-model="selectedResearchPackage" value="1" label="49€" hide-details dense class="mt-0 mx-auto"></v-checkbox></v-col>
                        <v-col cols="3" class="d-flex"> <v-checkbox v-model="selectedResearchPackage" value="2" label="199€" hide-details dense class="mt-0 mx-auto"></v-checkbox></v-col>
                        <v-col cols="3" class="d-flex"> <v-checkbox v-model="selectedResearchPackage" value="3" label="369€" hide-details dense class="mt-0 mx-auto"></v-checkbox></v-col
                    ></v-row>
                    <v-row>
                        <v-col cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedResearchPackage == '1' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>100.000 Keywords per month</v-list-item-title>
                                    <v-list-item-subtitle>Retrieve up to 100.000 keywords</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedResearchPackage == '2' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>500.000 Keywords per month</v-list-item-title>
                                    <v-list-item-subtitle>Retrieve up to 500.000 keywords</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedResearchPackage == '3' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>1.000.000 Keywords per month</v-list-item-title>
                                    <v-list-item-subtitle>Retrieve up to 1.000.000 keywords</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                    </v-row>

                    <v-row class="pricing_seperator">
                        <v-col style="font-weight: 600"> Content Suite</v-col>
                        <v-col class="d-flex">
                            <v-checkbox v-model="selectedContentPackage" label="99€" value="1" hide-details dense class="mt-0 mx-auto"></v-checkbox>
                        </v-col>
                        <v-col class="d-flex">
                            <v-checkbox v-model="selectedContentPackage" label="299€" value="2" hide-details dense class="mt-0 mx-auto"></v-checkbox>
                        </v-col>
                        <v-col class="d-flex">
                            <v-checkbox v-model="selectedContentPackage" label="499€" value="3" hide-details dense class="mt-0 mx-auto"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedContentPackage == '1' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>5 new projects per month</v-list-item-title>
                                    <v-list-item-subtitle>Create up to 5 new content projects</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item></v-col
                        >
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedContentPackage == '2' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>25 new projects per month</v-list-item-title>
                                    <v-list-item-subtitle>Create up to 25 new content projects</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item></v-col
                        >
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedContentPackage == '3' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>100 new projects per month</v-list-item-title>
                                    <v-list-item-subtitle>Create up to 100 new content projects</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item></v-col
                        >
                    </v-row>
                    <v-row class="pricing_seperator">
                        <v-col style="font-weight: 600"> Monitoring Suite</v-col>
                        <v-col class="d-flex"> <v-checkbox v-model="selectedMonitoringPackage" value="1" label="49€" hide-details dense class="mt-0 mx-auto"></v-checkbox></v-col>
                        <v-col class="d-flex"> <v-checkbox v-model="selectedMonitoringPackage" value="2" label="299€" hide-details dense class="mt-0 mx-auto"></v-checkbox></v-col>
                        <v-col class="d-flex"> <v-checkbox v-model="selectedMonitoringPackage" value="3" label="499€" hide-details dense class="mt-0 mx-auto"></v-checkbox></v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="12" md="3" v-if="$vuetify.breakpoint.mdAndUp"></v-col>
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedMonitoringPackage == '1' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>500 tracked keywords</v-list-item-title>
                                    <v-list-item-subtitle>Monitor up to 500 keywords weekly</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item></v-col
                        >
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedMonitoringPackage == '2' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>10.000 tracked keywords</v-list-item-title>
                                    <v-list-item-subtitle>Monitor up to 10.000 keywords weekly</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item></v-col
                        >
                        <v-col cols="12" md="3">
                            <v-list-item class="pricing_feature" :active="selectedMonitoringPackage == '3' ? true : false">
                                <v-list-item-content>
                                    <v-list-item-title>20.000 tracked keywords</v-list-item-title>
                                    <v-list-item-subtitle>Monitor up to 20.000 keywords weekly</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item></v-col
                        >
                    </v-row>
                    <v-row><v-divider></v-divider></v-row>

                    <v-row class="pricing_footer">
                        <v-col v-if="$vuetify.breakpoint.mdAndUp"></v-col>
                        <v-col v-if="$vuetify.breakpoint.mdAndUp"> </v-col>
                        <v-col v-if="$vuetify.breakpoint.mdAndUp"> </v-col>
                        <v-col :style="$vuetify.breakpoint.mobile ? '' : 'border-left: 1px solid #e4e4e4'">
                            <div v-if="discount" class="d-flex mb-3">
                                <v-chip class="ml-auto" style="background-color: rgb(255 151 0 / 13%) !important; border-color: rgb(198 124 10); color: rgb(198 82 10)" outlined small>
                                    <v-icon small class="mr-2">mdi-package-up</v-icon>
                                    Package Discount {{ discount }} %</v-chip
                                >
                            </div>
                            <div v-if="yearMonthSwitch" class="d-flex mb-3">
                                <v-chip class="ml-auto" style="background-color: rgb(255 151 0 / 13%) !important; border-color: rgb(198 124 10); color: rgb(198 82 10)" outlined small>
                                    <v-icon small class="mr-2">mdi-autorenew</v-icon>
                                    Runtime Discount 10 %</v-chip
                                >
                            </div>
                            <div class="flex">
                                <div class="price-style ml-auto">
                                    <ins>
                                        <span class="amount">
                                            €{{ price.rounded }},<sup>{{ price.cents }} </sup>
                                        </span>
                                    </ins>
                                </div>
                            </div>

                            <div class="d-flex">
                                <span style="font-size: 10px; color: grey; margin-left: auto">zzgl. MwSt</span>
                            </div>
                            <div class="period ml-auto">
                                <span class="mx-auto">
                                    {{ yearMonthSwitch ? "monthly, paid annually" : "monthly" }}
                                </span>
                            </div>
                            <!-- <v-text-field class="mt-4" rounded dense hide-details outlined label="enter promo code.." v-model="promocode"></v-text-field> -->
                            <v-btn :disabled="!selectedResearchPackage && !selectedContentPackage && !selectedMonitoringPackage" class="mt-4" color="primary" block rounded @click="submitStripe()">
                                <span>continue </span>
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-container>
            </div>

            <!-- <v-row class="choose">
                <v-col v-if="commpany_status !== 'active'">
                    <div :class="!cspricing ? ' quaroshadow newcontent active ml-auto' : 'ml-auto newcontent'" @click="commpany_status_content_suite !== 'active' ? (cspricing = !cspricing) : null">
                        <div class="choosetitle pa-auto" style="max-width: 59%">KEYWORD SUITE</div>
                        <div class="chooseimage">
                            <img v-if="!cspricing" src="../assets/newwhite.svg" width="90%" class="mx-auto" alt="" />
                            <img v-else src="../assets/content_project.svg" width="90%" class="mx-auto" alt="" />
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div :class="cspricing ? 'quaroshadow optimizecontent active mr-auto' : ' optimizecontent mr-auto'" @click="commpany_status !== 'active' ? (cspricing = !cspricing) : null">
                        <div class="choosetitle pa-auto"><span>CONTENT SUITE</span></div>
                        <div class="chooseimage">
                            <img src="../assets/opimize_primary.svg" width="90%" class="mx-auto" alt="" />
                        </div>
                    </div>
                </v-col>
                <v-col>
                    <div :class="cspricing ? 'quaroshadow optimizecontent active mr-auto' : ' optimizecontent mr-auto'" @click="commpany_status !== 'active' ? (cspricing = !cspricing) : null">
                        <div class="choosetitle pa-auto"><span>MONITORING SUITE</span></div>
                        <div class="chooseimage">
                            <img src="../assets/opimize_primary.svg" width="90%" class="mx-auto" alt="" />
                        </div>
                    </div>
                </v-col>
            </v-row> -->
            <!-- <v-slide-x-transition hide-on-leave>
                <div style="display: flex" class="mt-5" v-if="!cspricing">
                    <v-row>
                        <v-col xl="4" md="4" sm="12" xs="12">
                            <v-card max-width="370px" class="quaroshadow rounded_card" :style="campaign == 'fb_form1' ? 'border: 4px solid var(--v-primary-base);' : ''">
                                <v-sheet v-if="campaign == 'fb_form1'" color="primary" dark id="earlybirdSheet" rounded="xl" elevation="6">
                                    <center>
                                        <div class="headingsheet2 mt-6">
                                            <span class="plan-text pt-5"> Early Bird (save ~30%) </span>
                                        </div>
                                    </center>
                                </v-sheet>
                                <v-card-text>
                                    <div :class="campaign == 'fb_form1' ? 'text-center mt-3' : 'text-center'">
                                        <img width="130px" :src="rocket01" id="rocket01" />
                                        <br />
                                        <h3>
                                            <span class="title-quaro">Quaro</span>
                                            <span class="title-after">{{ tabItems[0].name }}</span>
                                        </h3>
                                        <br />
                                        <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                                            <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                            <v-btn id="right_toggle" small outlined color="darkgreen"> yearly </v-btn>
                                            <v-badge bordered color="error" overlap>
                                                <template v-slot:badge> -10% </template>
                                            </v-badge>
                                        </v-btn-toggle>
                                    </div>

                                    <v-list class="mt-3">
                                        <v-tooltip top v-for="attribute in tabItems[0].attributes" v-bind:key="attribute.title">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item v-bind="attrs" v-on="on">
                                                    <v-list-item-icon>
                                                        <v-icon>{{ attribute.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ attribute.title }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                                        </v-tooltip>
                                    </v-list>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <div class="flex">
                                    <div class="price-style mx-auto">
                                        <del>
                                            <span class="amount">€ {{ yearMonthSwitch ? "969" : "89" }},<sup>99</sup></span>
                                        </del>
                                        <ins>
                                            <span class="amount">
                                                €{{ getAmount(yearMonthSwitch ? tabItems[0].price.annual : tabItems[0].price.month) }},<sup
                                                    >{{ getSub(yearMonthSwitch ? tabItems[0].price.annual : tabItems[0].price.month) }}
                                                </sup>
                                            </span>
                                        </ins>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <span style="font-size: 10px; color: grey; margin: auto">zzgl. MwSt</span>
                                </div>
                                <div class="period mx-auto">
                                    <span class="mx-auto">
                                        {{ yearMonthSwitch ? "yearly" : "monthly" }}
                                    </span>
                                </div>

                         

                                <v-card-actions>
                                    <stripe-checkout ref="checkout" :pk="publishableKey" :session-id="session_id" />

                                    <v-btn
                                        :loading="loadingPackageNull"
                                        class="subscribe1btn"
                                        color="primary"
                                        block
                                        rounded
                                        @click="submitStripe(yearMonthSwitch ? tabItems[0].lineItemsAnnual : tabItems[0].lineItemsMonth, tabItems[0].id)">
                                        <span>Subscribe </span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col xl="4" md="4" sm="12" xs="12">
                            <v-card max-width="370px" class="quaroshadow rounded_card" :style="campaign != 'fb_form1' ? 'border: 4px solid var(--v-primary-base);' : ''">
                          
                                <v-card-text>
                                    <v-dialog style="z-index: 9999" v-if="subscribeDialog" v-model="dialog">
                                        <v-card> please contact support </v-card>
                                    </v-dialog>
                                    <div :class="campaign == 'fb_form1' ? 'text-center mt-11' : 'text-center mt-3'">
                                        <img width="130px" :src="rocket02" />
                                        <br />
                                        <h3>
                                            <span class="title-quaro">Quaro</span>
                                            <span class="title-after">{{ tabItems[1].name }}</span>
                                        </h3>
                                        <br />
                                        <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                                            <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                            <v-btn small outlined color="darkgreen"> yearly </v-btn>
                                            <v-badge bordered color="error" overlap>
                                                <template v-slot:badge>-10%</template>
                                            </v-badge>
                                        </v-btn-toggle>
                                    </div>

                                    <v-list double-line class="mt-3">
                                        <v-tooltip top v-for="attribute in tabItems[1].attributes" v-bind:key="attribute.title">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item v-bind="attrs" v-on="on">
                                                    <v-list-item-icon>
                                                        <v-icon>{{ attribute.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ attribute.title }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                                        </v-tooltip>
                                    </v-list>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <div class="flex">
                                    <div class="price-style mx-auto">
                                        <ins>
                                            <span class="amount">
                                                €{{ getAmount(yearMonthSwitch ? tabItems[1].price.annual : tabItems[1].price.month) }},<sup
                                                    >{{ getSub(yearMonthSwitch ? tabItems[1].price.annual : tabItems[1].price.month) }}
                                                </sup>
                                            </span>
                                        </ins>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <span style="font-size: 10px; color: grey; margin: auto">zzgl. MwSt</span>
                                </div>
                                <div class="period mx-auto">
                                    <span class="mx-auto">
                                        {{ yearMonthSwitch ? "yearly" : "monthly" }}
                                    </span>
                                </div>

                         
                                <v-card-actions>
                                    <stripe-checkout ref="checkout" :pk="publishableKey" :session-id="session_id" />

                                    <v-btn
                                        :loading="loadingPackageOne"
                                        color="primary"
                                        block
                                        rounded
                                        @click="submitStripe(yearMonthSwitch ? tabItems[1].lineItemsAnnual : tabItems[1].lineItemsMonth, tabItems[1].id)">
                                        <span>Subscribe </span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col xl="4" md="4" sm="12" xs="12">
                            <v-card max-width="370px" class="quaroshadow rounded_card">
                                <v-card-text>
                                    <div class="text-center">
                                        <img width="130px" :src="rocket03" />
                                        <br />
                                        <h3>
                                            <span class="title-quaro">Quaro</span>
                                            <span class="title-after">{{ tabItems[2].name }}</span>
                                        </h3>
                                        <br />
                                        <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                                            <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                            <v-btn small outlined color="darkgreen"> yearly </v-btn>
                                            <v-badge bordered color="error" overlap>
                                                <template v-slot:badge>-10%</template>
                                            </v-badge>
                                        </v-btn-toggle>
                                    </div>

                                    <v-list double-line class="mt-3">
                                        <v-tooltip top v-for="attribute in tabItems[2].attributes" v-bind:key="attribute.title">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item v-bind="attrs" v-on="on">
                                                    <v-list-item-icon>
                                                        <v-icon>{{ attribute.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ attribute.title }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                                        </v-tooltip>
                                    </v-list>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <div class="flex">
                                    <div class="price-style mx-auto">
                                        <ins>
                                            <span class="amount">
                                                €{{ getAmount(yearMonthSwitch ? tabItems[2].price.annual : tabItems[2].price.month) }},<sup
                                                    >{{ getSub(yearMonthSwitch ? tabItems[2].price.annual : tabItems[2].price.month) }}
                                                </sup>
                                            </span>
                                        </ins>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <span style="font-size: 10px; color: grey; margin: auto">zzgl. MwSt</span>
                                </div>
                                <div class="period mx-auto">
                                    <span class="mx-auto">
                                        {{ yearMonthSwitch ? "yearly" : "monthly" }}
                                    </span>
                                </div>
                            
                                <v-card-actions>-->
            <stripe-checkout ref="checkout" :pk="publishableKey" :session-id="session_id" />

            <!-- <v-btn
                                        :loading="loadingPackageTwo"
                                        color="primary"
                                        block
                                        rounded
                                        @click="submitStripe(yearMonthSwitch ? tabItems[2].lineItemsAnnual : tabItems[2].lineItemsMonth, tabItems[2].id)">
                                        <span>Subscribe </span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-slide-x-transition>
            <v-slide-x-transition hide-on-leave>
                <div style="display: flex" class="mt-5" v-if="cspricing">
                    <v-row>
                        <v-col xl="4" md="4" sm="12" xs="12">
                            <v-card max-width="370px" class="quaroshadow rounded_card" :style="campaign == 'fb_form1' ? 'border: 4px solid var(--v-primary-base);' : ''">
                                <v-sheet v-if="campaign == 'fb_form1'" color="primary" dark id="earlybirdSheet" rounded="xl" elevation="6">
                                    <center>
                                        <div class="headingsheet2 mt-6">
                                            <span class="plan-text pt-5"> Early Bird (save ~30%) </span>
                                        </div>
                                    </center>
                                </v-sheet>
                                <v-card-text>
                                    <div :class="campaign == 'fb_form1' ? 'text-center mt-3' : 'text-center'">
                                        <img width="130px" :src="rocket01" id="rocket01" />
                                        <br />
                                        <h3>
                                            <span class="title-quaro">Quaro</span>
                                            <span class="title-after">STARTER</span>
                                        </h3>
                                        <br />
                                        <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                                            <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                            <v-btn id="right_toggle" small outlined color="darkgreen"> yearly </v-btn>
                                            <v-badge bordered color="error" overlap>
                                                <template v-slot:badge> -10% </template>
                                            </v-badge>
                                        </v-btn-toggle>
                                    </div>

                                    <v-list class="mt-3">
                                        <v-tooltip disabled top v-for="(attribute, index) in cs_package_attributes[0]" v-bind:key="attribute.title">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item v-bind="attrs" v-on="on">
                                                    <v-list-item-icon :class="index == 0 ? 'importantPoint' : ''">
                                                        <v-icon>{{ attribute.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title :class="index == 0 ? 'importantPoint' : ''">{{ attribute.title }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                                        </v-tooltip>
                                    </v-list>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <div class="flex">
                                    <div class="price-style mx-auto">
                                      
                                        <ins>
                                            <span class="amount"> €{{ !yearMonthSwitch ? 99 : (99 * 12 * 0.9).toFixed(0) }},<sup>00 </sup> </span>
                                        </ins>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <span style="font-size: 10px; color: grey; margin: auto">zzgl. MwSt</span>
                                </div>
                                <div class="period mx-auto">
                                    <span class="mx-auto">
                                        {{ yearMonthSwitch ? "yearly" : "monthly" }}
                                    </span>
                                </div>

                                <v-card-actions class="pt-5">
                                    <v-btn color="primary" block rounded @click="contactSupport('starter')">
                                        <span>contact </span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col xl="4" md="4" sm="12" xs="12">
                            <v-card max-width="370px" class="quaroshadow rounded_card" :style="campaign != 'fb_form1' ? 'border: 4px solid var(--v-primary-base);' : ''">
                            
                                <v-card-text>
                                    <v-dialog style="z-index: 9999" v-if="subscribeDialog" v-model="dialog">
                                        <v-card> please contact support </v-card>
                                    </v-dialog>
                                    <div :class="campaign == 'fb_form1' ? 'text-center mt-11' : 'text-center mt-3'">
                                        <img width="130px" :src="rocket02" />
                                        <br />
                                        <h3>
                                            <span class="title-quaro">Quaro</span>
                                            <span class="title-after">{{ tabItems[1].name }}</span>
                                        </h3>
                                        <br />
                                        <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                                            <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                            <v-btn small outlined color="darkgreen"> yearly </v-btn>
                                            <v-badge bordered color="error" overlap>
                                                <template v-slot:badge>-10%</template>
                                            </v-badge>
                                        </v-btn-toggle>
                                    </div>

                                    <v-list double-line class="mt-3">
                                        <v-tooltip disabled top v-for="(attribute, index) in cs_package_attributes[1]" v-bind:key="attribute.title">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-list-item v-bind="attrs" v-on="on">
                                                    <v-list-item-icon :class="index == 0 ? 'importantPoint' : ''">
                                                        <v-icon>{{ attribute.icon }}</v-icon>
                                                    </v-list-item-icon>
                                                    <v-list-item-content>
                                                        <v-list-item-title :class="index == 0 ? 'importantPoint' : ''">{{ attribute.title }}</v-list-item-title>
                                                        <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </template>
                                            <span v-if="attribute.tooltip">{{ attribute.tooltip }}</span>
                                        </v-tooltip>
                                    </v-list>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <div class="flex">
                                    <div class="price-style mx-auto">
                                        <ins>
                                            <span class="amount"> €{{ !yearMonthSwitch ? 299 : (299 * 12 * 0.9).toFixed(0) }},<sup>00 </sup> </span>
                                        </ins>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <span style="font-size: 10px; color: grey; margin: auto">zzgl. MwSt</span>
                                </div>
                                <div class="period mx-auto">
                                    <span class="mx-auto">
                                        {{ yearMonthSwitch ? "yearly" : "monthly" }}
                                    </span>
                                </div>

                                <v-card-actions class="pt-5">
                                    <v-btn color="primary" block rounded @click="contactSupport('advanced')">
                                        <span>contact </span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                        <v-col xl="4" md="4" sm="12" xs="12">
                            <v-card max-width="370px" class="quaroshadow rounded_card">
                                <v-card-text>
                                    <div class="text-center">
                                        <img width="130px" :src="rocket03" />
                                        <br />
                                        <h3>
                                            <span class="title-quaro">Quaro</span>
                                            <span class="title-after">{{ tabItems[2].name }}</span>
                                        </h3>
                                        <br />
                                        <v-btn-toggle mandatory v-model="yearMonthSwitch" class="mx-auto">
                                            <v-btn small outlined color="darkgreen"> monthly </v-btn>
                                            <v-btn small outlined color="darkgreen"> yearly </v-btn>
                                            <v-badge bordered color="error" overlap>
                                                <template v-slot:badge>-10%</template>
                                            </v-badge>
                                        </v-btn-toggle>
                                    </div>

                                    <v-list double-line class="mt-3">
                                        <v-list-item v-for="(attribute, index) in cs_package_attributes[2]" v-bind:key="attribute.title">
                                            <v-list-item-icon :class="index == 0 ? 'importantPoint' : ''">
                                                <v-icon>{{ attribute.icon }}</v-icon>
                                            </v-list-item-icon>
                                            <v-list-item-content>
                                                <v-list-item-title :class="index == 0 ? 'importantPoint' : ''">{{ attribute.title }}</v-list-item-title>
                                                <v-list-item-subtitle>{{ attribute.subtitle }}</v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </v-list>
                                </v-card-text>
                                <v-spacer></v-spacer>
                                <div class="flex">
                                    <div class="price-style mx-auto">
                                        <ins>
                                            <span class="amount"> €{{ !yearMonthSwitch ? 499 : (499 * 12 * 0.9).toFixed(0) }},<sup>00</sup> </span>
                                        </ins>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <span style="font-size: 10px; color: grey; margin: auto">zzgl. MwSt</span>
                                </div>
                                <div class="period mx-auto">
                                    <span class="mx-auto">
                                        {{ yearMonthSwitch ? "yearly" : "monthly" }}
                                    </span>
                                </div>

                                <v-card-actions class="pt-5">
                                   
                                    <v-btn color="primary" block rounded @click="contactSupport('pro')">
                                        <span>contact</span>
                                    </v-btn>
                                </v-card-actions>
                            </v-card>
                        </v-col>
                    </v-row>
                </div>
            </v-slide-x-transition> -->
            <v-dialog v-model="contactdialog" max-width="350px" transition="dialog-transition">
                <v-card>
                    <div class="contactedheader text-center">
                        <div>
                            <v-icon size="125px" color="white">mdi-check-circle-outline</v-icon>
                        </div>
                        <div class="mt-2">WE WILL CONTACT YOU!</div>
                    </div>
                    <v-card-text class="pb-0">
                        <div class="flex mt-5">
                            <div class="mx-auto text-center">
                                <p>Thank you for your interest in our products. We will contact you via email!</p>
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn class="mx-auto" outlined color="primary" @click="contactdialog = false">Ok</v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</template>

<script>
    import { StripeCheckout } from "@vue-stripe/vue-stripe"
    export default {
        name: "Checkout",
        props: {
            show: Boolean,
            darkbtn: Boolean
        },
        components: {
            StripeCheckout
        },
        data: () => ({
            selectedResearchPackage: "1",
            selectedContentPackage: "1",
            selectedMonitoringPackage: "1",
            researchPrices: [
                { monthly: 49, yearly: 588, stripe_id_month: process.env.VUE_APP_RS_STARTER_MONTH, stripe_id_year: process.env.VUE_APP_RS_STARTER_YEAR },
                { monthly: 199, yearly: 2388, stripe_id_month: process.env.VUE_APP_RS_ADVANCED_MONTH, stripe_id_year: process.env.VUE_APP_RS_ADVANCED_YEAR },
                { monthly: 369, yearly: 4428, stripe_id_month: process.env.VUE_APP_RS_PRO_MONTH, stripe_id_year: process.env.VUE_APP_RS_PRO_YEAR }
            ],
            contentPrices: [
                { monthly: 99, yearly: 1188, stripe_id_month: process.env.VUE_APP_CS_STARTER_MONTH, stripe_id_year: process.env.VUE_APP_CS_STARTER_YEAR },
                { monthly: 299, yearly: 3588, stripe_id_month: process.env.VUE_APP_CS_ADVANCED_MONTH, stripe_id_year: process.env.VUE_APP_CS_ADVANCED_YEAR },
                { monthly: 499, yearly: 5988, stripe_id_month: process.env.VUE_APP_CS_PRO_MONTH, stripe_id_year: process.env.VUE_APP_CS_PRO_YEAR }
            ],
            monitoringPrices: [
                { monthly: 49, yearly: 588, stripe_id_month: process.env.VUE_APP_MS_STARTER_MONTH, stripe_id_year: process.env.VUE_APP_MS_STARTER_YEAR },
                { monthly: 299, yearly: 3588, stripe_id_month: process.env.VUE_APP_MS_ADVANCED_MONTH, stripe_id_year: process.env.VUE_APP_MS_ADVANCED_YEAR },
                { monthly: 499, yearly: 5988, stripe_id_month: process.env.VUE_APP_MS_PRO_MONTH, stripe_id_year: process.env.VUE_APP_MS_PRO_YEAR }
            ],
            contactdialog: false,
            cs_package_attributes: [
                [
                    {
                        title: "5 new projects per month",
                        subtitle: "Create up to 5 new content projects",
                        icon: "mdi-plus-circle-outline"
                    },
                    {
                        title: "20 Saved Projects",
                        subtitle: "Save up to 20 content projects",
                        icon: "mdi-database"
                    },
                    {
                        title: "Unlimited user",
                        subtitle: "Create as many user as you need",
                        icon: "mdi-account-supervisor-circle-outline"
                    }
                ],
                [
                    {
                        title: "25 new projects per month",
                        subtitle: "Create up to 25 new content projects",
                        icon: "mdi-plus-circle-outline"
                    },
                    {
                        title: "100 Saved Projects",
                        subtitle: "Save up to 100 content projects",
                        icon: "mdi-database"
                    },

                    {
                        title: "Unlimited user",
                        subtitle: "Create as many user as you need",
                        icon: "mdi-account-supervisor-circle-outline"
                    }
                ],
                [
                    {
                        title: "100 new projects per month",
                        subtitle: "Create up to 100 new content projects",
                        icon: "mdi-plus-circle-outline"
                    },
                    {
                        title: "400 Saved Projects",
                        subtitle: "Save up to 400 content projects",
                        icon: "mdi-database"
                    },

                    {
                        title: "Unlimited user",
                        subtitle: "Create as many user as you need",
                        icon: "mdi-account-supervisor-circle-outline"
                    }
                ]
            ],
            cspricing: false,
            dialog: true,
            notification: { show: false },
            loadingPackageNull: false,
            loadingPackageOne: false,
            loadingPackageTwo: false,
            continueLoading: false,
            auth: "Basic " + window.btoa(process.env.VUE_APP_AUTH),
            session_id: null,
            checkout_data: {
                success_url: process.env.VUE_APP_STRIPE_SUCCESS_URL,
                cancel_url: process.env.VUE_APP_STRIPE_CANCEL_URL,
                payment_method_types: ["card", "sofort", "sepa_debit", "paypal"],
                line_items: null,
                mode: "subscription",
                client_reference_id: localStorage.id,
                customer: localStorage.username,
                customer_email: localStorage.user,
                metadata: localStorage.company_id
            },
            publishableKey: process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY,
            subscribeDialog: false,
            yearMonthSwitch: 0,
            yearMonthLabel: "Yearly",
            rocket01: require("../assets/icons/__rocket01.svg"),
            rocket02: require("../assets/icons/__rocket02.svg"),
            rocket03: require("../assets/icons/__rocket03.svg"),
            tabItems: [
                {
                    id: 0,
                    name: "Single",
                    lineItemsMonth: [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_MONTHLY_EARLY_BIRD, quantity: 1 }],
                    lineItemsAnnual: [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_YEARLY_EARLY_BIRD, quantity: 1 }],
                    price: {
                        month: "59,99",
                        annual: "639,99"
                    },
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi.",
                    attributes: [
                        {
                            title: "100.000 Keywords",
                            subtitle: "Retrieve up to 100.000 keywords",
                            icon: "mdi-magnify",
                            tooltip: "You can search up to 100.000 keywords every month"
                        },
                        {
                            title: "Single User License",
                            subtitle: "One user to rule them all",
                            icon: "mdi-account-circle-outline",
                            tooltip: "The perfect package for a one man show"
                        },
                        {
                            title: "Unlimited use of all functions",
                            subtitle: "Ideas, Suggest, Historical...",
                            icon: "mdi-check-circle-outline",
                            tooltip: "Use all features and modules without limits and create as many projects and collections as you want"
                        },
                        {
                            title: "Share your Collections",
                            subtitle: "You can share all your collections",
                            icon: "mdi-share-variant-outline",
                            tooltip: "Share your projects and collections easily with your colleagues or customers - no need for excel!"
                        },
                        {
                            title: "Automated updating",
                            subtitle: "Keep your data always up to date",
                            icon: "mdi-update",
                            tooltip: "Quaro automatically updates the search volume of your collections"
                        }
                    ]
                },
                {
                    id: 1,
                    name: "Advanced",
                    lineItemsMonth: [
                        {
                            price: process.env.VUE_APP_ADVANCED_PRICE_ID_MONTHLY,
                            quantity: 1
                        }
                    ],
                    lineItemsAnnual: [{ price: process.env.VUE_APP_ADVANCED_PRICE_ID_YEARLY, quantity: 1 }],
                    price: {
                        month: "199,99",
                        annual: "2158,99"
                    },
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi.",
                    attributes: [
                        {
                            title: "500.000 Keywords",
                            subtitle: "Retrieve up to 500.000 keywords",
                            icon: "mdi-magnify",
                            tooltip: "You can search up to half a million keywords every month"
                        },
                        {
                            title: "Unlimited User License",
                            subtitle: "Create as many user as you need",
                            icon: "mdi-account-supervisor-circle-outline",
                            tooltip: "There is no user limit in this package - use Quaro with all your colleagues"
                        },
                        {
                            title: "Unlimited use of all functions",
                            subtitle: "Ideas, Suggest, Historical...",
                            icon: "mdi-check-circle-outline",
                            tooltip: "Use all features and modules without limits and create as many projects and collections as you want"
                        },
                        {
                            title: "Share your Collections",
                            subtitle: "You can share all your collections",
                            icon: "mdi-share-variant-outline",
                            tooltip: "Share your projects and collections easily with your colleagues or customers - no need for excel!"
                        },
                        {
                            title: "Automated updating",
                            subtitle: "Keep your data always up to date",
                            icon: "mdi-update",
                            tooltip: "Quaro automatically updates the search volume of your collections"
                        }
                    ]
                },

                {
                    id: 2,
                    name: "Pro",
                    lineItemsMonth: [{ price: process.env.VUE_APP_PRO_PRICE_ID_MONTHLY, quantity: 1 }],
                    lineItemsAnnual: [{ price: process.env.VUE_APP_PRO_PRICE_ID_YEARLY, quantity: 1 }],
                    price: {
                        month: "369,99",
                        annual: "3994,99"
                    },
                    text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Maecenas ullamcorper diam congue felis consequat fringilla. Phasellus ac orci nec velit tristique lacinia quis in purus. Maecenas eu sagittis felis, id egestas augue. Cras eu sapien vehicula, cursus lectus vitae, efficitur nunc. Phasellus sodales faucibus mi non tincidunt. Orci varius natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Nulla accumsan arcu sed dictum mattis. Suspendisse in placerat nisi.",
                    attributes: [
                        {
                            title: "1.000.000 Keywords",
                            subtitle: "Retrieve up to 1.000.000 keywords",
                            icon: "mdi-magnify",
                            tooltip: "You can search up to one million keywords every month"
                        },
                        {
                            title: "Unlimited User License",
                            subtitle: "Create as many user as you need",
                            icon: "mdi-account-supervisor-circle-outline",
                            tooltip: "There is no user limit in this package - use Quaro with all your colleagues"
                        },
                        {
                            title: "Unlimited use of all functions",
                            subtitle: "Ideas, Suggest, Historical...",
                            icon: "mdi-check-circle-outline",
                            tooltip: "Use all features and modules without limits and create as many projects and collections as you want"
                        },
                        {
                            title: "Share your Collections",
                            subtitle: "You can share all your collections",
                            icon: "mdi-share-variant-outline",
                            tooltip: "Share your projects and collections easily with your colleagues or customers - no need for excel!"
                        },
                        {
                            title: "Automated updating",
                            subtitle: "Keep your data always up to date",
                            icon: "mdi-update",
                            tooltip: "Quaro automatically updates the search volume of your collections"
                        }
                        // {
                        //     title: 'Single-Sign-On (SSO)',
                        //     subtitle: 'Use your company Login System',
                        //     icon: 'mdi-login',
                        //     tooltip: ''
                        // },
                        // {
                        //     title: 'Unlimited training courses',
                        //     subtitle: 'Use your company Login System',
                        //     icon: 'mdi-face-agent',
                        //     tooltip: ''
                        // },
                    ]
                }
            ],
            tab: 1
        }),
        computed: {
            discount() {
                let disc = parseInt(this.selectedResearchPackage ? 1 : 0) + parseInt(this.selectedContentPackage ? 1 : 0) + parseInt(this.selectedMonitoringPackage ? 1 : 0) - 1
                return disc > 0 ? disc * 5 : 0
            },
            price() {
                // selectedResearchPackage: "1",
                // selectedContentPackage: "1",
                // selectedMonitoringPackage: "1",
                let sum = 0
                sum += this.selectedResearchPackage
                    ? this.yearMonthSwitch
                        ? this.researchPrices[this.selectedResearchPackage - 1].yearly
                        : this.researchPrices[this.selectedResearchPackage - 1].monthly
                    : 0
                console.log(
                    "research ",
                    this.selectedResearchPackage
                        ? this.yearMonthSwitch
                            ? this.researchPrices[this.selectedResearchPackage - 1].yearly
                            : this.researchPrices[this.selectedResearchPackage - 1].monthly
                        : 0
                )
                sum += this.selectedContentPackage
                    ? this.yearMonthSwitch
                        ? this.contentPrices[this.selectedContentPackage - 1].yearly
                        : this.contentPrices[this.selectedContentPackage - 1].monthly
                    : 0
                console.log(
                    "cs",
                    this.selectedContentPackage ? (this.yearMonthSwitch ? this.contentPrices[this.selectedContentPackage - 1].yearly : this.contentPrices[this.selectedContentPackage - 1].monthly) : 0
                )
                sum += this.selectedMonitoringPackage
                    ? this.yearMonthSwitch
                        ? this.monitoringPrices[this.selectedMonitoringPackage - 1].yearly
                        : this.monitoringPrices[this.selectedMonitoringPackage - 1].monthly
                    : 0
                console.log(
                    "ms",
                    this.selectedMonitoringPackage
                        ? this.yearMonthSwitch
                            ? this.monitoringPrices[this.selectedMonitoringPackage - 1].yearly
                            : this.monitoringPrices[this.selectedMonitoringPackage - 1].monthly
                        : 0
                )
                console.log("discount", this.discount)
                if (this.yearMonthSwitch) {
                    sum = sum / 12
                }
                // let cents = parseInt((sum * ((100 - this.discount) / 100) - parseInt(sum * ((100 - this.discount) / 100))) * 100)
                let cents = (
                    (parseFloat((sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100)).toFixed(2)) -
                        parseInt(sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100))) *
                    100
                ).toFixed(0)
                console.log("alles", parseFloat((sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100)).toFixed(2)))
                console.log("minus0", parseInt(sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100)))
                console.log(cents)
                console.log("vvalue", parseFloat((sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100)).toFixed(2)))
                return {
                    rounded: parseInt((sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100)).toFixed(2)),
                    cents: cents == 0 || cents == 100 ? "00" : cents,
                    value: parseFloat((sum * ((100 - (this.discount + (this.yearMonthSwitch ? 10 : 0))) / 100)).toFixed(2))
                }
            },
            campaign() {
                return this.$store.state.campaign
            },
            company_status() {
                return this.$store.state.company_status
            },
            company_status_content_suite() {
                return this.$store.state.company_status_content_suite
            }
        },
        methods: {
            contactSupport() {
                this.contactdialog = true

                let url = process.env.VUE_APP_APIURL + "/service/mail"
                let post_data = {
                    company_id: localStorage.company_id,
                    user: localStorage.user,
                    type: "booking",
                    package: {
                        research_suite: {
                            price: this.selectedResearchPackage
                                ? this.yearMonthSwitch
                                    ? this.researchPrices[this.selectedResearchPackage - 1].yearly
                                    : this.researchPrices[this.selectedResearchPackage - 1].monthly
                                : 0,
                            period: this.yearMonthSwitch ? "yearly" : "monthly",
                            package:
                                this.selectedResearchPackage == "1" ? "starter" : this.selectedResearchPackage == "2" ? "advanced" : this.selectedResearchPackage == "3" ? "advanced" : "no selection"
                        },
                        content_suite: {
                            price: this.selectedContentPackage
                                ? this.yearMonthSwitch
                                    ? this.contentPrices[this.selectedContentPackage - 1].yearly
                                    : this.contentPrices[this.selectedContentPackage - 1].monthly
                                : 0,
                            period: this.yearMonthSwitch ? "yearly" : "monthly",
                            package: this.selectedContentPackage == "1" ? "starter" : this.selectedContentPackage == "2" ? "advanced" : this.selectedContentPackage == "3" ? "advanced" : "no selection"
                        },
                        monitoring_suite: {
                            price: this.selectedMonitoringPackage
                                ? this.yearMonthSwitch
                                    ? this.monitoringPrices[this.selectedMonitoringPackage - 1].yearly
                                    : this.monitoringPrices[this.selectedMonitoringPackage - 1].monthly
                                : 0,
                            period: this.yearMonthSwitch ? "yearly" : "monthly",
                            package:
                                this.selectedMonitoringPackage == "1"
                                    ? "starter"
                                    : this.selectedMonitoringPackage == "2"
                                    ? "advanced"
                                    : this.selectedMonitoringPackage == "3"
                                    ? "advanced"
                                    : "no selection"
                        }
                    }
                }
                this.$helpers.axiosPost(url, post_data)
            },
            getAmount(price) {
                try {
                    let split = price.split(",")
                    return split[0]
                } catch (error) {
                    return "error"
                }
            },
            getSub(price) {
                try {
                    let split = price.split(",")
                    return split[1]
                } catch (error) {
                    return "error"
                }
            },
            submitStripe(lineItems, itemID) {
                this.continueLoading = true
                // status if schon started
                let items = []
                if (parseInt(this.selectedResearchPackage)) {
                    items.push({
                        price: this.yearMonthSwitch ? this.researchPrices[this.selectedResearchPackage - 1].stripe_id_year : this.researchPrices[this.selectedResearchPackage - 1].stripe_id_month,
                        quantity: 1
                    })
                }
                if (parseInt(this.selectedContentPackage)) {
                    items.push({
                        price: this.yearMonthSwitch ? this.contentPrices[this.selectedContentPackage - 1].stripe_id_year : this.contentPrices[this.selectedContentPackage - 1].stripe_id_month,
                        quantity: 1
                    })
                }
                if (parseInt(this.selectedMonitoringPackage)) {
                    items.push({
                        price: this.yearMonthSwitch
                            ? this.monitoringPrices[this.selectedMonitoringPackage - 1].stripe_id_year
                            : this.monitoringPrices[this.selectedMonitoringPackage - 1].stripe_id_month,
                        quantity: 1
                    })
                }
                this.checkout_data.line_items = items
                this.checkout_data.interval = this.yearMonthSwitch ? "yearly" : "monthly"

                // if (itemID == 0) {
                //     this.loadingPackageNull = true
                // } else if (itemID == 1) {
                //     this.loadingPackageOne = true
                // } else {
                //     this.loadingPackageTwo = true
                // }
                this.$http
                    .post(process.env.VUE_APP_APIURL + "/checkout/init", this.checkout_data, {
                        headers: { Authorization: this.auth }
                    })
                    .then((response) => {
                        console.log(response)
                        if (!response.body.error) {
                            this.session_id = response.body.session_id
                            this.$refs.checkout.redirectToCheckout()
                        } else {
                            this.contactSupport()
                            console.log("Active Subscription found! Please contact support via chat or via email")
                            this.contactdialog = true
                            this.continueLoading = false
                        }
                    })
                    .catch((error) => {
                        this.notification = this.$helpers.createNotification("An error acurred! Please contact support via chat or via email! contact@quaro.io", "mdi-alert-circle-outline", "#FCBA03")
                        this.loadingPackageNull = false
                        this.loadingPackageOne = false
                        this.loadingPackageTwo = false
                        this.continueLoading = false
                        console.log(error)
                    })
            }
            // checkCampaign() {
            //     console.log("h")
            //     console.log(this.campaign)
            //     if (this.campaign && this.campaign == "fb_form1") {
            //         this.tabItems[0].lineItemsMonth = [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_MONTHLY_EARLY_BIRD, quantity: 1 }]
            //         this.tabItems[0].lineItemsAnnual = [{ price: process.env.VUE_APP_SINGLE_PRICE_ID_YEARLY_EARLY_BIRD, quantity: 1 }]
            //         this.tabItems[0].price = {
            //             month: "59,99",
            //             annual: "639,99"
            //         }
            //     }
            // }
        },
        mounted() {
            // this.checkCampaign()
            if (this.company_status == "active") this.cspricing = true
        }
    }
</script>
<style scoped lang="scss">
    .importantPoint {
        font-weight: bold;
    }
    #right_toggle {
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
    }
    .period {
        color: grey;
        display: flex;
        width: 30%;
        padding: 1px;
        border: 1px solid grey;
        border-radius: 5px;
    }
    .price-style {
        del {
            color: rgba(rgb(255, 0, 0), 0.5);
            text-decoration: none;
            position: relative;
            font-size: 25px;
            font-weight: 100;
            &:before {
                content: " ";
                display: block;
                width: 100%;
                border-top: 1px solid rgba(rgb(250, 0, 0), 0.8);
                border-bottom: 1px solid rgba(rgb(255, 0, 0), 0.8);
                height: 5px;
                position: absolute;
                bottom: 15px;
                left: 0;
                transform: rotate(-11deg);
            }
        }
        ins {
            font-size: 40px;
            font-weight: 100;
            text-decoration: none;
            padding-left: 0px !important;
            // padding: 1em 1em 1em 0.5em;
        }
    }
    @media only screen and (max-width: 600px) {
        .title-quaro {
            display: none;
        }
        .title-after {
            color: black !important;
            font-size: 13px;
        }
    }
    .title-quaro {
        text-transform: uppercase;
        color: black;
    }
    .title-after {
        text-transform: uppercase;
        color: var(--v-primary-base);
    }
    .best-value-card {
        border: 4px solid var(--v-primary-base);
    }
    .v-dialog {
        box-shadow: none !important;
    }
    #earlybirdSheet {
        margin-top: -25px;
        margin-left: auto;
        margin-right: auto;
        width: 250px;
        height: 50px;
    }
    #searchsheet {
        margin-top: -25px;
        margin-left: auto;
        margin-right: auto;
        width: 200px;
        height: 50px;
    }
    .plan-text {
        text-transform: uppercase;
        font-weight: bold;
        margin-top: 5px !important;
    }
    #gradient {
        background: rgb(28, 237, 179);
        background: linear-gradient(90deg, #009f89 0%, #e68989 75%);
    }
    .close-btn-container {
        width: 100px;
        position: fixed;
        top: 48px;
        left: calc(50% - 50px);
    }
    .choose > div > div {
        max-width: 300px;
        display: flex;
        // margin: auto;
        padding: 0px 30px;
        background-color: #dddcdc;
        border-radius: 10px;
        &:hover {
            background-color: #bfbfbf;
            cursor: pointer;
        }
        &.active {
            background-color: var(--v-primary-base) !important;
            color: white;
        }
    }
    .chooseimage {
        max-width: 40%;
        padding: 10px;
    }
    .choosetitle {
        display: flex;
        margin: auto;
    }
    .contactedheader {
        background-color: var(--v-primary-base);
        padding: 30px;
        color: white;
    }
    .new_pricing {
        background-color: white;
        border-radius: 10px;
    }
    .pricing_seperator {
        background-color: #f2f2f2;
        border-radius: 5px;
        display: flex;
    }
    .pricing_footer {
        // background-color: red;
    }
    .pricing_feature[active="true"] {
        background-color: #009f892b;
        border-radius: 5px;
        color: #009f89;
        border: 1px solid #009f89;
    }
</style>
