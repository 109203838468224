<template>
    <v-data-table id="cluster_rankings_table" hide-default-headers :headers="rankingHeader" :items="rankings">
        <!-- header slot -->
        <template v-for="(header_entry, index) in rankingHeader" v-slot:[`header.${header_entry.value}`]="{ header }">
            <span :key="index">
                <span v-bind:style="{ width: header.width }">
                    <v-tooltip max-width="250" color="" top :disabled="header.tooltip ? false : true">
                        <template v-slot:activator="{ on }">
                            <span v-on="on" style="white-space: nowrap; text-transform: uppercase; font-weight: 500">
                                {{ header.text }}
                                <v-icon class="questionIcon" v-if="header.tooltip" x-small>mdi-help-circle-outline</v-icon>
                            </span>
                        </template>
                        <span>
                            <v-row no-gutters>
                                <div cols="12"><v-icon color="white">mdi-help-circle-outline</v-icon></div>
                            </v-row>
                            {{ header.tooltip }}
                        </span>
                    </v-tooltip>
                </span>
            </span>
        </template>
        <template v-slot:item.keyword="{ item }">
            <div :class="!item.blur ? 'py-2' : 'py-2 blur'" style="padding-left: -5px !important">
                <span class="rtd pl-2">{{ !item.blur ? item.keyword : item.keyword.slice(0, Math.ceil(item.keyword.length / 3)) }}</span> <br />
                <div style="white-space: nowrap" v-if="item.category_id">
                    <v-btn icon x-small @click="showAllCatsInTable"
                        ><v-icon x-small>{{ showAllCategories ? "mdi-chevron-right" : "mdi-chevron-left" }}</v-icon></v-btn
                    >
                    <span v-if="item.category_id && showAllCategories" style="white-space: nowrap">
                        <span v-for="(cat, index) in item.category_id.split(';')" :key="cat">
                            <v-icon v-if="index" x-small class="px-0">mdi-chevron-right</v-icon>
                            <v-chip x-small @click="!item.blur ? setCat(item, index) : null"> {{ cat }}</v-chip>
                        </span>
                    </span>
                    <span v-else>
                        <v-chip x-small @click="!item.blur ? setCategory(item) : null">
                            {{ !item.blur ? item.category_id.split(";")[item.category_id.split(";").length - 1] : item.category_id.slice(0, Math.ceil(item.category_id.length / 3)) }}</v-chip
                        >
                    </span>
                </div>
            </div>
        </template>
        <template v-slot:item.avg_search_volume="{ item }">
            <v-chip outlined class="qchip" color="primary" style="">
                {{ $helpers.formatNumber(item.avg_search_volume) }}
            </v-chip>
        </template>
        <template v-slot:item.keyword_intent="{ item }">
            <div style="display: inline-flex" v-if="item.sorted_search_intent">
                <!-- Haupt-Intent -->
                <span class="pa-1 d-flex">
                    <v-tooltip max-width="250" color="primary" top>
                        <template v-slot:activator="{ on }">
                            <v-progress-linear
                                v-on="on"
                                style="border-radius: 16px; font-size: 14px; width: 70px; margin: auto; opacity: 0.8"
                                class="white--text intent"
                                rounded
                                :value="item.sorted_search_intent[0][1]"
                                color="primary"
                                height="32">
                                <v-icon class="mr-1" size="16px" color="white">{{ intent_icons[item.sorted_search_intent[0][0]] }}</v-icon>
                                <!-- Name des ersten Intents -->
                                {{ Math.ceil(item.sorted_search_intent[0][1]) + "%" }}
                                <!-- Prozentwert des ersten Intents -->
                            </v-progress-linear>
                        </template>
                        <span> {{ item.sorted_search_intent[0][0] }}</span>
                    </v-tooltip>
                </span>
                <v-slide-x-transition>
                    <span v-if="show_secondary_intents" style="display: inline-flex">
                        <span v-for="(intent, index) in item.sorted_search_intent.slice(1)" :key="index" class="pa-1">
                            <!-- Alle bis auf den ersten Intent -->
                            <v-tooltip max-width="250" color="primary" top>
                                <template v-slot:activator="{ on }">
                                    <v-progress-linear
                                        v-on="on"
                                        style="border-radius: 15px; font-size: 14px; width: 70px; margin: auto; opacity: 0.8"
                                        class="white--text intent"
                                        rounded
                                        :value="intent[1]"
                                        color="primary"
                                        height="32">
                                        <v-icon class="mr-1" size="16px" color="white">{{ intent_icons[intent[0]] }}</v-icon>
                                        <!-- Name des sekundären Intents -->
                                        {{ Math.ceil(intent[1]) + "%" }}
                                        <!-- Prozentwert des sekundären Intents -->
                                    </v-progress-linear>
                                </template>
                                <span> {{ intent[0] }}</span>
                            </v-tooltip>
                        </span>
                    </span>
                </v-slide-x-transition>
                <v-btn
                    @click="
                        (e) => {
                            e.stopPropagation()
                            show_secondary_intents = !show_secondary_intents
                        }
                    "
                    icon
                    class="ma-auto">
                    <v-icon>{{ show_secondary_intents ? "mdi-chevron-left" : "mdi-chevron-right" }}</v-icon>
                </v-btn>
            </div>
        </template>
        <template v-for="competitor in selectedCompetitors" v-slot:[`item.ranking_${competitor.domain}`]="{ item }">
            <v-dialog
                id="rankingmenu"
                style="border-radius: 10px !important"
                offset-y
                v-model="menuOpenState[competitor.domain + item.keyword]"
                rounded
                @click:outside="menuOpenState[competitor.domain + item.keyword] = false"
                :close-on-content-click="false"
                @input="menuOpen(item, competitor.domain)"
                :key="competitor.domain">
                <template v-slot:activator="{ on }">
                    <!-- Hier wird der Aktivatorinhalt platziert, z.B. ein Button -->
                    <span v-on="on">
                        <div class="pr-3" v-bind="(posData = getRankingPosition(item, competitor.domain))">
                            <v-row class="compranking-wrapper">
                                <v-chip class="compranking-wrapper" outlined :color="getColor(posData.position)" :class="posData.change == null ? 'qchip ml-auto' : ' ml-auto qchip'">
                                    {{ posData.position }}
                                </v-chip>
                                <div class="d-flex my-auto ml-1 px-2" v-if="posData.change != null" style="border: 1px solid rgb(187, 187, 187); border-radius: 14px">
                                    <v-icon :color="posData.change ? (posData.change < 0 ? 'red' : 'green') : ''" small class="my-auto">{{
                                        posData.change ? (posData.change < 0 ? "mdi-chevron-down" : "mdi-chevron-up") : "mdi-chevron-right"
                                    }}</v-icon>
                                    <span :style="posData.change ? (posData.change < 0 ? 'color: red' : 'color: green') : ''" class="rankingchange my-auto">{{ posData.change }}</span>
                                </div>
                            </v-row>
                        </div>
                    </span>
                </template>
                <v-list style="color: rgb(255, 255, 255); border-radius: 10px !important">
                    <!-- Ihr bisheriger Tooltip-Inhalt geht hier hinein -->
                    <div class="px-3 py-1" v-if="tooltipMenuData.datasets && tooltipMenuData.datasets.length">
                        <div class="d-flex my-4">
                            <div style="display: block !important" class="px-2">
                                <div class="mr-1 my-auto quarolbl mb-1" style="font-size: 16px">Position Changes: {{ tooltipMenuData.keyword }}</div>
                                <div class="my-auto">
                                    <v-chip small class="mr-1">
                                        <img class="ma-auto mr-1" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + tooltipMenuData.domain + '&sz=42'" alt="" />
                                        {{ tooltipMenuData.domain }}
                                    </v-chip>
                                    <v-chip small>
                                        <v-icon class="mr-1" small>mdi-clock-time-eight-outline</v-icon>
                                        <span v-if="tooltipMenuData.labels[1] != null">
                                            {{ tooltipMenuData.labels[0].substring(0, 10) + " - " + tooltipMenuData.labels[tooltipMenuData.labels.length - 1].substring(0, 10) }}
                                        </span>
                                        <span v-else>
                                            {{ tooltipMenuData.labels[0].substring(0, 10) }}
                                        </span></v-chip
                                    >
                                </div>
                            </div>
                            <v-spacer class="mr-2"></v-spacer>

                            <v-btn class="ml-2 mr-2" style="background-color: var(--v-primary-base)" icon @click="menuOpenState[competitor.domain + item.keyword] = false">
                                <v-icon color="white">mdi-close</v-icon></v-btn
                            >
                        </div>
                        <v-divider></v-divider>
                        <v-card-text class="pa-0">
                            <v-row v-if="tooltipMenuData.labels[1] != null">
                                <v-col cols="12">
                                    <line-chart
                                        v-if="menuOpenState[competitor.domain + item.keyword]"
                                        :dataChart="tooltipMenuData.chartData"
                                        :countGraph2="renderChart"
                                        :options="graphOptions"></line-chart>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col cols="12" class="pa-0">
                                    <v-divider></v-divider>
                                    <v-data-table id="urlRankingsTable" :headers="tooltipMenuData.tableHeaders" :items="tooltipMenuData.tableRows" hide-default-footer>
                                        <template v-for="(header_entry, index) in tooltipMenuData.tableHeaders" v-slot:[`header.${header_entry.value}`]="{ header }">
                                            <span :key="index">
                                                <span v-bind:style="{ width: header.width }">
                                                    <!-- Adding tooltips to header -->
                                                    <span style="white-space: nowrap; text-transform: uppercase; font-weight: 500; font-size: 14px">
                                                        {{ header.text }}
                                                    </span>
                                                </span>
                                            </span>
                                        </template>

                                        <template v-for="(header_entry, index) in tooltipMenuData.tableHeaders" v-slot:[`item.${header_entry.value}`]="{ item }">
                                            <span class="pa-1" v-if="index == 0">
                                                <span class="d-flex pa-1">
                                                    <div
                                                        class="fav_wrapper my-auto mr-4 ml-3"
                                                        :style="'border-color:' + getColoFromIndex(tooltipMenuData.tableRows.findIndex((d) => d.url == item.url)) + ' !important;'">
                                                        <img class="ma-auto" :src="'https://s2.googleusercontent.com/s2/favicons?domain=' + item.url + '&sz=42'" alt="" />
                                                    </div>
                                                    <span class="my-auto" style="white-space: normal">{{ item.url }}</span>
                                                    <!-- <span class="my-auto">{{ item.url.length > 80 ? item.url.substring(0, 80) + "..." : item.url }}</span> -->
                                                    <v-btn class="ml-1 my-auto" @click="openURL(item.url)" icon primary> <v-icon>mdi-link</v-icon></v-btn>
                                                </span>
                                            </span>
                                            <span class="pa-1" v-if="index > 0">
                                                <v-chip class="compranking-wrapper qchip" style="background-color: whitesmoke !important" outlined :color="getColor(item[header_entry.value])">
                                                    {{ item[header_entry.value] || "-" }}
                                                </v-chip>
                                            </span>
                                        </template>
                                    </v-data-table>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </div>
                    <div v-else>
                        <div class="pa-5 d-flex">
                            <v-spacer class="mr-2"></v-spacer>
                            <v-chip class="mx-auto"> no ranking data</v-chip>
                            <v-spacer class="mr-2"></v-spacer>
                            <v-btn class="ml-2 mr-2" style="background-color: var(--v-primary-base)" icon @click="menuOpenState[competitor.domain + item.keyword] = false">
                                <v-icon color="white">mdi-close</v-icon></v-btn
                            >
                        </div>
                    </div>
                </v-list>
            </v-dialog>
        </template>
    </v-data-table>
</template>
<script>
    import { positionColors } from "../helper/global.js"
    export default {
        props: {
            category_id: {
                type: String
            },
            search: {
                type: String
            },
            project_domain: {
                type: String
            },
            selectedCompetitors: {
                type: Array,
                required: true
            },
            parent: { type: Object },
            selectedDates: { type: Array },
            fetchClusterRanking: { type: Number }
        },
        data: () => ({
            intent_icons: { informational: "mdi-information-outline", navigational: "mdi-navigation-outline", commercial: "mdi-shopping-search-outline", transactional: "mdi-shopping-outline" },
            intentTooltips: {
                informational: "Looking for information on a specific topic, such as “what is SEO?”",
                navigational: "Navigating to a certain site or page, for instance, “Facebook login page”.",
                commercial: "Conducting a commercial investigation, that is, researching products or services before making a decision on what to invest in, such as “best SEO tools”.",
                transactional: "Making a specific purchase, for example, “buy SEO software”."
            },
            show_intents: false,
            show_secondary_intents: false,
            rankings: [],
            tooltipMenuData: {},
            menuOpenState: {},
            showAllCategories: false,
            menu: false, // Für das Datumsfeld
            dateRange: "",
            datePickerRange: null // Bereich für Datumsauswahl
        }),
        computed: {
            rankingHeader() {
                let h = [
                    { text: "Keyword", value: "keyword", width: "306px" },
                    { text: "Google Searches", value: "avg_search_volume", align: "end", width: "150px", tooltip: "Shows the average google search volume for a keyword" },
                    {
                        text: "Search Intent",
                        value: "keyword_intent",
                        sortable: false,
                        align: "end",
                        tooltip: "The search intent refers to the user’s motivation behind a search query."
                    },
                    // { text: "Category", value: "categories", align: "end", sortable: false },
                    // Add a header for each domain
                    ...this.selectedCompetitors.map((domain) => ({
                        text: domain.domain,
                        value: `ranking_${domain.domain}`,
                        align: "end",

                        tooltip: "Shows the position and the position change in googles search results based on the selected dates."
                    }))
                    // {
                    //     text: "",
                    //     value: "actions",
                    //     sortable: false,
                    //     align: "end",
                    //     width: 50
                    // }
                ]

                console.log(this.project_domain)
                let indexOfDomain = h.findIndex((e) => e.text == this.project_domain)
                console.log("indexOfDomain", indexOfDomain)
                if (indexOfDomain > -1) {
                    let targetIndex = 3
                    let removedItem = h.splice(indexOfDomain, 1)[0]
                    h.splice(targetIndex, 0, removedItem)
                }
                // let indexOfSvH = h.findIndex((e) => e.value == "avg_search_volume")
                // let intentheader = {
                //     text: "Search Intent",
                //     value: "keyword_intent",
                //     sortable: false,
                //     align: "end",
                //     tooltip: "The search intent refers to the user’s motivation behind a search query.",
                //     width: "250px"
                // }

                // h.push(intentheader)

                return h
            }
        },
        watch: {
            fetchClusterRanking() {
                // Fetch data when the domains prop changes
                console.log("waaaaaaaaaaatch")
                console.log("waaaaaaaaaaatch")
                console.log("waaaaaaaaaaatch")
                console.log("waaaaaaaaaaatch")

                this.fetchClusterKeywords()
            }
        },
        methods: {
            getColor(position) {
                let colorIndex = Math.ceil(position / 10) - 1
                let color = positionColors[colorIndex] ? positionColors[colorIndex] : positionColors[positionColors.length - 1]
                return color
            },
            getColoFromIndex(index) {
                let colors = ["#C2185B", "#7B1FA2", "#1976D2", "#0097A7", "#388E3C", "#AFB42B", "#F57C00", "#5D4037", "#455A64"]
                return colors[index]
            },
            getRankingPosition(item, domain) {
                if (!item.rankings) {
                    return { position: "-", change: null }
                }
                const ranking = item.rankings.find((r) => r.domain === domain)
                // console.log(item)
                const oldRanking = item.rankingsOld ? item.rankingsOld.find((r) => r.domain === domain) : null
                const change = oldRanking && ranking ? ranking.position - oldRanking.position : null
                return { position: ranking ? ranking.position : "-", change: change * -1 } // *-1 : 16 --> 20 = +4 sollte aber minuse 4 ergeben daher *-1
            },
            getColorFromString(str) {
                let hash = 0
                for (let i = 0; i < str.length; i++) {
                    hash = str.charCodeAt(i) + ((hash << 5) - hash) + str.length
                }
                const color = Math.floor(Math.abs((Math.sin(hash) * 16777215) % 1) * 16777215).toString(16)
                return "#" + "0".repeat(6 - color.length) + color
            },
            menuOpen(item, competitor) {
                const isMenuOpen = this.menuOpenState[competitor + item.keyword]
                console.log(isMenuOpen)
                if (isMenuOpen) {
                    console.log("Menu opened:", item, competitor)
                    //set tooltipdata
                    let labels = this.selectedDates[1] == null ? [JSON.parse(JSON.stringify(this.selectedDates[0]))] : JSON.parse(JSON.stringify(this.selectedDates))
                    let datasets = []
                    if (item.rankings) {
                        let ranking = item.rankings.find((e) => e.domain == competitor)
                        if (ranking) {
                            // best ranking
                            let dataset = {
                                label: ranking.url,
                                data: [undefined, ranking.position],
                                borderColor: this.getColoFromIndex(0) || this.getColorFromString(ranking.url),
                                backgroundColor: (this.getColoFromIndex(0) || this.getColorFromString(ranking.url)) + "20",
                                pointBackgroundColor: (this.getColoFromIndex(0) || this.getColorFromString(ranking.url)) + "20",
                                pointStyle: "circle",
                                pointRadius: 6,
                                pointHoverRadius: 10,
                                borderWidth: 2,
                                fill: "start"
                            }
                            datasets.push(dataset)
                            ranking.multiple.forEach((r, index) => {
                                // adding additional rankigns
                                let dataset = {
                                    label: r.url,
                                    data: [undefined, r.position],
                                    borderColor: this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url),
                                    backgroundColor: (this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url)) + "20",
                                    pointBackgroundColor: (this.getColoFromIndex(index + 1) || this.getColorFromString(ranking.url)) + "20",
                                    pointStyle: "circle",
                                    pointRadius: 6,
                                    pointHoverRadius: 10,
                                    borderWidth: 2,
                                    fill: "start"
                                }
                                datasets.push(dataset)
                            })
                        }
                    }
                    if (item.rankingsOld) {
                        let rankingOld = item.rankingsOld ? item.rankingsOld.find((e) => e.domain == competitor) : null
                        if (rankingOld) {
                            // find eintrag und setzte ersten data punkt
                            let index = datasets.findIndex((e) => e.label == rankingOld.url)
                            if (index === -1) {
                                let dataset = {
                                    label: rankingOld.url,
                                    data: [rankingOld.position, undefined],
                                    borderColor: this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url),
                                    backgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                    pointBackgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                    pointStyle: "circle",
                                    pointRadius: 6,
                                    pointHoverRadius: 10,
                                    borderWidth: 2,
                                    fill: "start"
                                }
                                datasets.push(dataset)
                            } else {
                                datasets[index].data[0] = rankingOld.position
                            }
                            rankingOld.multiple.forEach((r) => {
                                let index = datasets.findIndex((e) => e.label == r.url)
                                // adding additional rankigns
                                if (index === -1) {
                                    let dataset = {
                                        label: r.url,
                                        data: [r.position, undefined],
                                        borderColor: this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url),
                                        backgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                        pointBackgroundColor: (this.getColoFromIndex(datasets.length + 1) || this.getColorFromString(rankingOld.url)) + "20",
                                        pointStyle: "circle",
                                        pointRadius: 6,
                                        pointHoverRadius: 10,
                                        borderWidth: 2,
                                        fill: "start"
                                    }
                                    datasets.push(dataset)
                                } else {
                                    datasets[index].data[0] = r.position
                                }
                            })
                        }
                    }
                    this.tooltipMenuData.labels = labels
                    this.tooltipMenuData.datasets = datasets
                    this.tooltipMenuData.keyword = item.keyword
                    this.tooltipMenuData.domain = competitor
                    this.tooltipMenuData.chartData = {
                        labels: labels,
                        datasets: datasets
                    }
                    this.tooltipMenuData.tableHeaders = [
                        { text: "URL", value: "url" },
                        ...labels.map((l) => {
                            return { text: l.substring(0, 10), value: l, width: "150px" }
                        })
                    ]
                    this.tooltipMenuData.tableRows = this.tooltipMenuData.datasets.map((d) => {
                        let row = { url: d.label }
                        this.tooltipMenuData.labels.forEach((l, index) => {
                            row[l] = d.data[index]
                        })
                        return row
                    })

                    setTimeout(() => {
                        this.renderChart++
                    }, 110)
                } else {
                    console.log("Menu closed:", item, competitor)
                }
            },
            showAllCatsInTable() {
                event.stopPropagation()
                this.showAllCategories = !this.showAllCategories
            },

            async fetchClusterKeywords() {
                console.log(this.parent.keywords)

                try {
                    console.log(this.selectedDates)
                    console.time("fetch rankings")
                    const [dataForDate1, dataForDate2] = await Promise.all([
                        this.fetchDataForDate(this.selectedDates[0], this.parent.keywords),
                        this.fetchDataForDate(this.selectedDates[1], this.parent.keywords)
                    ])
                    console.timeEnd("fetch rankings")
                    if (!dataForDate2.rankings.length) {
                        dataForDate2.rankings = JSON.parse(JSON.stringify(dataForDate1.rankings))
                        dataForDate2.totalCount = JSON.parse(JSON.stringify(dataForDate1.totalCount))
                    }
                    dataForDate1.rankings.map((oldRanking) => {
                        let actualRanking = dataForDate2.rankings.find((r) => r.keyword == oldRanking.keyword)

                        if (actualRanking) {
                            actualRanking.rankingsOld = oldRanking.rankings
                        } else {
                            console.log("couldnt find ranking")
                        }
                    })

                    let rankings = dataForDate2.rankings.map((ranking) => {
                        if (ranking.search_intent) {
                            this.show_intents = true
                            ranking.sorted_search_intent = this.sortIntent(JSON.parse(JSON.stringify(ranking.search_intent)))
                        }
                        ranking.category_ids.sort()
                        ranking.category_id = ranking.category_ids[ranking.category_ids.length - 1]
                        return ranking
                    })
                    this.rankings = rankings
                    console.log(rankings)
                } catch (error) {
                    console.error(`Fehler beim Laden der Keywords für Clusters ${parent._id}:`, error)
                }
            },
            async fetchDataForDate(date, keywords) {
                try {
                    const domainArray = this.selectedCompetitors.map((c) => c.domain)
                    const url = process.env.VUE_APP_MONITORING_API_URL + "/project/get/frontend/keywords"
                    // const sort = this.getSortObject(tableOptions)
                    // console.log(tableOptions.sortDesc)
                    const post_data = {
                        company_id: localStorage.company_id,
                        company_status: this.$store.state.company_status_monitoring_suite,
                        project_id: this.$route.params.id,
                        category_id: this.category_id ? this.category_id : null,
                        domains: domainArray,
                        sortDesc: -1,
                        keywords: keywords.map((k) => k.keyword),
                        date: date, // Use the current date from the loop
                        search: "",
                        start: 1,
                        limit: 10000,
                        sort: { avg_search_volume: -1 },
                        filters: null
                    }

                    // if (this.sortDomain) {
                    //     post_data.sortDomain = this.sortDomain
                    // }

                    const response_data = await this.$helpers.axiosPost(url, post_data)
                    return response_data.data
                } catch (error) {
                    console.log(error)
                    throw error
                }
            },
            sortIntent(intent) {
                return Object.entries(intent).sort(([, valueA], [, valueB]) => {
                    if (valueA === null && valueB === null) return 0
                    if (valueA === null) return 1
                    if (valueB === null) return -1
                    return valueB - valueA
                })
            }
        },
        mountend() {
            this.fetchClusterKeywords()
        }
    }
</script>
<style lang="scss">
    #cluster_rankings_table .v-data-footer {
        background-color: #edeff1 !important;
    }
</style>
